import React from 'react'
import { hexToRgb } from '../../helpers'
import useStyles from './Loader.styles'

interface Props {
  message?: string
  diameter?: number
  borderWidth?: number
  color?: string
}

const Loader = ({ borderWidth, color, diameter }: Props) => {
  const classes = useStyles()
  const style:React.CSSProperties = {}
  if (diameter) {
    style.width = `${diameter}px`
    style.height = `${diameter}px`
  }
  if (borderWidth) {
    style.borderWidth = `${borderWidth}px`
  }
  if (color) {
    const rgb = hexToRgb(color)
    style.borderColor = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, .2)`
    style.borderLeftColor = color
  }
  return <div data-testid="loader" className={classes.loader} style={style} />
}

export default Loader
