import React from 'react'
import useStyles from './PickListOrder.styles'
import logo from '../../../assets/images/po-logo-400.png'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { PickListOrderProps } from './PickListOrder.props'
import ProductImage from '../../ProductImage/ProductImage'
import { formatPrice, formatDate, getAllItemsOrderedByBinCode } from '../../../helpers'
import Check from '@material-ui/icons/Check';

const PickListOrder = (props: PickListOrderProps) => {
  const classes = useStyles()

  return (
    <table className={classes.layoutTableWrapper}>
      <thead>
        <tr>
          <th>
            <header>
              <img src={logo} alt="Planet Organic" width="180px" className={classes.logo} />
              <h1>Pick List</h1>
            </header>
            <ul className={classes.orderDetails}>
              <li>
                Order#<span>{props.orderNumber}</span>
              </li>
              <li>
                Customer Name<span>{props.customer.name}</span>
              </li>
              <li>
                Tel no.<span>{props.customer.phone}</span>
              </li>
              <li>
                Date Placed
                <span>{formatDate(props.placedAt)}</span>
              </li>
              <li>
                Sub Total
                <span>{formatPrice(props.subTotal.amount, props.subTotal.currency)}</span>
              </li>
              <li>
                Delivery ({props.deliveryMethod})
                <span>{formatPrice(props.deliveryCost.amount, props.deliveryCost.currency)}</span>
              </li>
              <li>
                Grand Total
                <span>{formatPrice(props.grandTotal.amount, props.grandTotal.currency)}</span>
              </li>
            </ul>
            {props.discountCodes.length ? (
              <p>
                Coupon codes applied:
                <strong> {props.discountCodes.map((discount) => discount.code).join(', ')}</strong>
              </p>
            ) : null}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className={classes.tableContainer}>
              <Table aria-label="Pick list">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell align="left">Product Name</TableCell>
                    <TableCell align="left">Line Code</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">BIN No.</TableCell>
                    <TableCell align="left">Complete</TableCell>
                    <TableCell align="left">Part Pick</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAllItemsOrderedByBinCode(props.consignments).map(
                    (row: any, index: number) => (
                      <TableRow key={`${props.orderNumber}-${index}`}>
                        <TableCell align="left">
                          <ProductImage imageUrl={row.imageUrl} />
                        </TableCell>
                        <TableCell align="left">{row.quantityOrdered}</TableCell>
                        <TableCell align="left" className={classes.productName}>
                          {row.title}
                        </TableCell>
                        <TableCell align="left">{row.lineCode}</TableCell>
                        <TableCell align="left">
                          {formatPrice(row.priceUnit.amount, row.priceUnit.currency)}
                        </TableCell>
                        <TableCell align="left">{row.binNo}</TableCell>
                        <TableCell align="left">
                        <div className={classes.box}>
                          {(row.quantityPicked === row.quantityOrdered || row.quantityPartiallyPicked === row.quantityOrdered) && (<Check />)}
                        </div>
                        </TableCell>
                        <TableCell>
                        <div className={classes.box}>
                          {(row.quantityPartiallyPicked > 0 && row.quantityPartiallyPicked < row.quantityOrdered) ? row.quantityPartiallyPicked : ''}
                        </div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PickListOrder
