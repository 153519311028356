import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'assets/jss/theme'
import { colors, fontSizes, fontWeights } from '../../../assets/jss/variables'

const useStyles = makeStyles({
  notes: {
    width: '100%',
    display: 'block',
    flex: '1',
    textAlign: 'right',
    '& .MuiInputLabel-animated': {
      fontSize: fontSizes.hybrid,
      fontWeight: fontWeights.bold,
      color: colors.grey.grey2,
    },
    '& .MuiFormLabel-filled': {
      transform: 'translate(0, 0) scale(1)',
    },
    '& .MuiInputBase-input': {
      fontSize: fontSizes.hybrid,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '300px',
    },
  },
  textField: {
    display: 'block',
    width: '100%',
    margin: '0px 0 50px 0',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px 0 10px 0',
    },
  },
  charsLeft: {
    fontSize: '12px',
    display: 'block',
    marginBottom: '5px',
  },
})

export default useStyles
