import { Theme as ThemeType } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fontWeights } from '../../../assets/jss/variables'
import { StatusColour } from '../../../helpers'

interface CssProps {
  style: string
}

export const useStyles = makeStyles<ThemeType, CssProps>(() => ({
  orderCountLozenge: {
    padding: '0 10px',
    textAlign: 'center',
    lineHeight: '2px',
    width: '100px',
    height: '2px',
    marginBottom: '5px',
    marginRight: '20px',
    backgroundColor: ({ style }) => StatusColour(style),
    borderRadius: '10px',
    '& > span': {
      fontSize: '12px',
      textTransform: 'uppercase',
    },
  },
  numericIndicator: {
    maxWidth: '100px',
  },
  text: {
    display: 'table-cell',
    verticalAlign: 'bottom',
    height: '30px',
    textAlign: 'left',
    fontSize: '13px',
    fontWeight: fontWeights.bold,
    lineHeight: '14px',
    color: '#35526D',
  },
  value: {
    width: '302px',
    textAlign: 'left',
    fontSize: '48px',
    fontWeight: fontWeights.bold,
    lineHeight: '48px',
    color: '#35526D',
  },
}))
