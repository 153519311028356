import React from 'react'
import { useReactiveVar } from '@apollo/client'
import IconBurgerMenu from 'components/Icons/IconBurgerMenu/IconBurgerMenu'
import IconArrowRight from 'components/Icons/IconArrowRight/IconArrowRight'
import IconArrowLeft from 'components/Icons/IconArrowLeft/IconArrowLeft'
import { stateIsSidebarOpen } from '../../../cache'
import clsx from 'clsx'
import useStyles from './MainMenuToggle.styles'

const MainMenuToggle = () => {
  const classes = useStyles()
  const isSidebarOpen = useReactiveVar(stateIsSidebarOpen)
  return (
    <button
      onClick={() => stateIsSidebarOpen(!isSidebarOpen)}
      className={clsx(classes.mainMenuToggle, isSidebarOpen ? classes.toggleOpen : classes.toggleClosed)}
    >
      <span className={classes.iconBase}>
        <IconBurgerMenu />
      </span>
      <span className={clsx(classes.iconActive, classes.iconOpen)}>
        <IconArrowLeft />
      </span>
      <span className={clsx(classes.iconActive, classes.iconClosed)}>
        <IconArrowRight />
      </span>
    </button>
  )
}

export default MainMenuToggle
