import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../assets/jss/variables'

const styles = makeStyles(() => ({
  buttonContainer: {
    width: 'fit-content',
  },

  roundButton: {
    backgroundColor: `${colors.grey.grey1} !important`,
    width: 60,
    height: 60,
    '&$small': {
      width: 36,
      height: 36,
      minHeight: 36,
      '& .MuiIcon-root': {
        fontSize: '14px',
      },
    },
  },
  icon: {
    color: colors.grey.grey2,
  },
  small: {},
}))

export default styles
