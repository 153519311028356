import React from 'react'

const IconCamera = (props) => {
  const fillColor = props.fillColor || '#fff'
  const width = props.width ? props.width + 'px' : '33px'
  const height = props.height ? props.height + 'px' : '28px'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fillColor}>
      <path
        data-name="Path 75903"
        d="M3.795 3.464h5.054l1.332-2.174A2.654 2.654 0 0112.478 0h7.99a2.654 2.654 0 012.3 1.291l1.329 2.174h5.054a3.81 3.81 0 013.795 3.795v16.692a3.81 3.81 0 01-3.795 3.795H3.795A3.81 3.81 0 010 23.952V7.259a3.81 3.81 0 013.795-3.795zM9.3 5.074H3.8a2.187 2.187 0 00-2.191 2.185v16.693a2.187 2.187 0 002.186 2.186h25.353a2.187 2.187 0 002.186-2.186V7.259a2.187 2.187 0 00-2.186-2.186h-5.5a.813.813 0 01-.685-.386l-1.566-2.56a1.029 1.029 0 00-.926-.519h-7.99a1.032 1.032 0 00-.926.519l-1.541 2.512a.8.8 0 01-.714.434z"
        fill={fillColor}
      />
      <path
        data-name="Path 75904"
        d="M16.474 7.378a8.227 8.227 0 11-8.227 8.227 8.225 8.225 0 018.227-8.227zm0 1.609a6.618 6.618 0 106.618 6.618 6.617 6.617 0 00-6.618-6.618z"
        fill={fillColor}
      />
      <path
        data-name="Path 75905"
        d="M19.812 15.604a.804.804 0 111.607 0 4.957 4.957 0 01-4.949 4.947.804.804 0 010-1.607 3.334 3.334 0 003.339-3.339z"
        fill={fillColor}
      />
    </svg>
  )
}

export default IconCamera
