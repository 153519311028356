import React from 'react'

const IconLogout = (props) => {
  const fillColor = props.fillColor || '#888'
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 1000 1000"
    >
      <g>
        <path
          fill={fillColor}
          d="M770.7,91.6l-0.7,1.1c-8.6-4.7-18.2-8.1-28.7-8.1c-33.9,0-61.3,27.4-61.3,61.3c0,19.4,9.4,35.8,23.5,47.1l-0.5,0.8c99.2,65.8,164.6,178.4,164.6,306.3c0,202.9-164.6,367.5-367.5,367.5c-202.9,0-367.5-164.6-367.5-367.5c0-127.5,65-239.9,163.7-305.8l-0.5-0.7c14.5-11.2,24.4-27.9,24.4-47.7c0-33.8-27.4-61.3-61.3-61.3c-11.1,0-20.9,3.7-29.8,8.9l-0.8-1.2C96.7,180.2,10,330,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990.1,329.5,902.8,179.4,770.7,91.6z M500,438.8c33.8,0,61.3-27.4,61.3-61.3V71.3c0-33.8-27.4-61.3-61.3-61.3c-33.8,0-61.3,27.4-61.3,61.3v306.3C438.8,411.3,466.2,438.8,500,438.8z"
        />
      </g>
    </svg>
  )
}

export default IconLogout
