import React from 'react'
import Loader from 'components/Loader/Loader'
import { useStyles } from './Button.styles'
import clsx from 'clsx'

interface ButtonInterface {
  title?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  className?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'grey' | 'orange' | 'outlined' | 'textOnly' | 'warning'
  icon?: string | JSX.Element
  isSubmit?: boolean
  contents?: JSX.Element
  onClick?: () => any
  onMouseDown?: () => any
  loader?: {
    diameter: number
    borderWidth: number
    color: string
  }
  textSpacer?: boolean
}

const Button = ({
  title,
  disabled,
  className,
  size,
  fullWidth,
  type,
  icon,
  textSpacer,
  loader,
  onClick,
  onMouseDown,
  isSubmit,
  contents
}: ButtonInterface) => {

  const classes = useStyles()

  return (
    <button
      className={clsx(classes.btn, (type && classes[type]) || classes.primary, disabled && classes.disabled, size && classes[size], fullWidth && classes.fullWidth, contents && classes.hasContents, className && className)}
      disabled={disabled}
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      { contents ? contents : (
      <> 
        {icon && <span className={classes.icon}>{icon}</span>}
        <span className={clsx(classes.text, textSpacer && classes.textSpacer)}>{title}</span>
        {loader && (
          <span className={classes.loader}>
            <Loader {...loader} />
          </span>
        )}
      </>
      )}
    </button>
  )
}

export default Button
