import React, { useState } from 'react'
import Button from '../Button/Button'
import RoundButton from '../RoundButton/RoundButton'
import CloseIcon from '@material-ui/icons/Close';
import ProductInfo from '../ProductInfo/ProductInfo'
import IconEdit from 'components/Icons/IconEdit/IconEdit'
import useStyles from './BarcodeScanner.styles'
import ScanditScanner from './ScanditScanner'
import {
  Barcode,
  ScanSettings,
  SymbologySettings
} from "scandit-sdk";

interface ProductResponse { 
  lineItemId: string
  consignmentIndex:number
  title: string
  promoTitle: string
  image: string
  quantityOrdered: number
  matchedLineItem: boolean
}

interface Props {
  onClose: () => void
  pickedLineItems: any
  onEditLineItem: (lineItemId: string, consignmentIndex: number, validateLineItem?: boolean) => void
  onScan: (scanResult: any) => Promise<ProductResponse>
}

const BarcodeScanner = ({ onClose, onScan, onEditLineItem, pickedLineItems }: Props) => {
  const emptyProductState = { lineItemId: '', consignmentIndex: 0, title: '', promoTitle: '', image: '', quantityOrdered: 0, matchedLineItem: false }
  const [product, setProduct] = useState<ProductResponse>(emptyProductState)
  const classes = useStyles()
  const getScanSettings = () => {
    const scanSettings = new ScanSettings({ enabledSymbologies: [
      Barcode.Symbology.AZTEC,
      Barcode.Symbology.CODABAR,
      Barcode.Symbology.CODE11,
      Barcode.Symbology.CODE128,
      Barcode.Symbology.CODE25,
      Barcode.Symbology.CODE32,
      Barcode.Symbology.CODE39,
      Barcode.Symbology.CODE93,
      Barcode.Symbology.DATA_MATRIX,
      Barcode.Symbology.DOTCODE,
      Barcode.Symbology.EAN13,
      Barcode.Symbology.EAN8,
      Barcode.Symbology.FIVE_DIGIT_ADD_ON,
      Barcode.Symbology.GS1_DATABAR,
      Barcode.Symbology.GS1_DATABAR_EXPANDED,
      Barcode.Symbology.GS1_DATABAR_LIMITED,
      Barcode.Symbology.IATA_2_OF_5,
      Barcode.Symbology.INTERLEAVED_2_OF_5,
      Barcode.Symbology.KIX,
      Barcode.Symbology.LAPA4SC,
      Barcode.Symbology.MAXICODE,
      Barcode.Symbology.MICRO_PDF417,
      Barcode.Symbology.MICRO_QR,
      Barcode.Symbology.MSI_PLESSEY,
      Barcode.Symbology.PDF417,
      Barcode.Symbology.QR,
      Barcode.Symbology.RM4SCC,
      Barcode.Symbology.TWO_DIGIT_ADD_ON,
      Barcode.Symbology.UPCA,
      Barcode.Symbology.UPCE,
    ], codeDuplicateFilter: 2000 })
    scanSettings
      .getSymbologySettings(Barcode.Symbology.UPCA)
      .enableExtensions(
        SymbologySettings.Extension.REMOVE_LEADING_ZERO);
    return scanSettings
  };

  const handleScan = async (scanResult: any) => {
    console.log('Scandit scan result: ', scanResult)
    const productResponse = await onScan(
      scanResult.barcodes.reduce((string: any, barcode: any) => {
        const scannedBarcode = barcode.data
        console.log('Barcode retrieved from scan:', scannedBarcode)
        return scannedBarcode
      }, "")
    )
    if (productResponse) {
      setProduct(productResponse)
    } else {
      setProduct(emptyProductState)
    }
  }

  const handleError = (scanResult: any) => {
    console.log('Scan error:', scanResult)
  }

  const quantitySelected = (pickedLineItems.find((item: any) => item.id === product.lineItemId)?.quantity || 0)
  const defaultMessage = (<span className={classes.scanMessageHelper}>Scan a barcode</span>)

  return (
    <div className={classes.barcodeScanner}>
      <RoundButton icon="close" ariaLabel="Close" onClick={onClose} small={true} className={classes.closeButton}>
        <CloseIcon />
      </RoundButton>
      <ScanditScanner
        playSoundOnScan={true}
        vibrateOnScan={true}
        scanSettings={getScanSettings()}
        enableTorchToggle={false}
        onScan={handleScan}
        onError={handleError}
        videoFit="cover"
      />
        <div className={classes.productDetail}>
        {product.title ? (
          <>
          <ProductInfo imageUrl={product.image} title={product.title} promotion={product.promoTitle} isSmall={true} className={classes.productInfo} />
          { product.matchedLineItem ? (     
          <>
          <div className={classes.quantity}>
            <span className={classes.labelText}>Picked Qty</span>
            <Button onClick={() => onEditLineItem(product.lineItemId, product.consignmentIndex)} type={quantitySelected === product.quantityOrdered ? 'tertiary' : quantitySelected > 0 ? 'orange' : 'grey'} title={`${quantitySelected}/${product.quantityOrdered}`} className={classes.quantityButton} />
            <IconEdit width={21} height={21} fillColor="#fff" />
          </div>
          </>
          ) : defaultMessage }
          </>
        ) : defaultMessage}
        </div>
    </div>
    )
  }

export default BarcodeScanner
