import { Theme as ThemeType } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from 'assets/jss/variables'
import { ODCOrderStatus, StatusColour } from '../../../helpers'

interface CssProps {
  status?: string
}

export const useStyles = makeStyles<ThemeType, CssProps>((theme: Theme) => ({
  small: {},
  orderStatusLozenge: {
    padding: '0 10px',
    textAlign: 'center',
    lineHeight: '18px',
    minWidth: '18ch',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: ({ status }) => StatusColour(status),
    borderRadius: '20px',
    '& > span': {
      fontSize: '8px',
      lineHeight: '1.9rem',
      textTransform: 'uppercase',
      color: ({ status }) => {
        switch (status) {
          case ODCOrderStatus.OPEN.status:
            return '#000000'
          default:
            return '#fff'
        }
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '10px',
        lineHeight: '2rem',
      },
    },
    '&$small': {
      minWidth: 'auto',
      '& > span': {
        lineHeight: '1.25rem',
      },
    },
  },
  assignedIndicator: {
    borderRadius: '100%',
    backgroundColor: colors.green,
    borderColor: colors.green,
    marginRight: '6px',
    width: '6px',
    height: '6px',
    display: 'block',
  },
}))
