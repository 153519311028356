import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from 'assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberChanger: {
      borderRadius: '22px',
      border: `1px solid ${colors.black}`,
      height: '3rem',
      width: '10rem',
    },
    button: {
      borderRadius: '22px',
      fontSize: '24px',
      border: 'none',
      display: 'flex',
      alignItems: 'end',
    },
    input: {
      border: 'none',
      boxShadow: 'none',
      textAlign: 'center',
    },
  })
)

export default useStyles
