import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontSizes, fontWeights } from '../../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: '30px',
      marginRight: '30px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    counter: {
      textAlign: 'center',
      fontSize: fontSizes.small,
      fontWeight: fontWeights.regular,
      color: '#738699',
    },
  })
)

export default useStyles
