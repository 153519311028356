import { Theme as ThemeType } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fontWeights } from 'assets/jss/variables'
import { fontSizes } from './../../../assets/jss/variables'

export const useStyles = makeStyles<ThemeType>(() => ({
  headerUser: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      '& $userPhoto': {
        filter: 'brightness(80%)',
      },
    },
  },
  userPhoto: {
    position: 'relative',
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
    transition: 'filter 500ms ease',
  },
  userDetails: {
    margin: '0 12px 0 0',
    textAlign: 'right',
  },
  userName: {
    display: 'block',
    fontWeight: fontWeights.bold,
    textAlign: 'right',
  },
  userRole: {
    display: 'block',
    fontSize: fontSizes.subtitle,
    fontWeight: fontWeights.light,
  },
}))
