import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import App from 'components/App/App'
import { Auth0Provider } from '@auth0/auth0-react'
import history from './history'
import Layout from 'components/Layout/Layout'
import Dashboard from 'components/Dashboard/Dashboard'
import NotFound from 'components/NotFound/NotFound'
import Header from 'components/Header/Header'
import OrderList from 'components/Orders/OrderList/OrderList'
import Splash from 'components/Splash/Splash'
import SecureApolloProvider from './SecureApolloProvider'
import PickList from 'components/PickList/PickList'
import OrderDetails from 'components/OrderDetails/OrderDetails'
import DispatchNote from 'components/DispatchNote/DispatchNote'
import config from './config'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './assets/jss/theme'

import 'assets/fonts/fonts.css'

const { domain, clientId, redirectUri, audience, scope } = config.auth0

/*
 * Render out the DOM
 */
ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={redirectUri}
    audience={audience}
    scope={scope}
  >
    <SecureApolloProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
        <App>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <Layout
                    showHeader={false}
                    showFooter={true}
                    mainContent={<Splash />}
                  />
                )}
              />
              <Route path="/picklist/:orderId?" exact component={PickList} />
              <Route path="/dispatch-note/:orderId/:consignmentId?" exact component={DispatchNote} />
              <Layout
                showHeader={true}
                showFooter={true}
                header={<Header />}
                mainContent={
                  <Switch>
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/callback" exact>
                      <Redirect to="/orders" />
                    </Route>
                    <Route path="/orders" exact component={OrderList} />
                    <Route path="/orders/order-details/:orderId" exact component={OrderDetails} />
                    <Route component={NotFound} />
                  </Switch>
                }
              />
            </Switch>
        </App>
        </Router>
      </ThemeProvider>
    </SecureApolloProvider>
  </Auth0Provider>,
  document.getElementById('root')
)

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
