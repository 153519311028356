import React from 'react'

const IconProgress = (props) => {
  const innerColor = props.innerColor || '#B8BFC2'
  const outerColor = props.outerColor || '#FF7600'
  const strokeWidth = props.strokeWidth || '10%'
  const diameter = props.diameter || 100
  const percentage = props.percentage
  const chartRadius = props.chartRadius || 50
  const visiblePortion = Math.ceil((percentage / 100) * (2 * Math.PI * chartRadius))
  const invisiblePortion = Math.ceil(2 * Math.PI * chartRadius - visiblePortion)

  return (
    <svg width={diameter} height={diameter} viewBox="0 0 115 115" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle fill="none" stroke={innerColor} strokeWidth="4" cx="55" cy="55" r={chartRadius} />
        <circle
          fill="none"
          strokeDashoffset={78}
          strokeDasharray={visiblePortion + ',' + invisiblePortion}
          stroke={outerColor}
          strokeWidth={strokeWidth}
          cx="55"
          cy="55"
          r={chartRadius}
        />
      </g>
    </svg>
  )
}

export default IconProgress
