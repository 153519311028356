import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { isInPath } from '../../../helpers'
import { colorHighlight } from '../../../assets/jss/variables'
import { stateIsSidebarOpen } from '../../../cache'
import clsx from 'clsx'
import useStyles from './MainMenuItem.styles'

const MainMenuItem = (props:any) => {
  const classes = useStyles()
  const location = useLocation()
  const path = location.pathname
  const isSelected = isInPath(props.url, path)
  const IconComponent = isSelected ? props.iconSelected : props.icon
  const isSidebarOpen = useReactiveVar(stateIsSidebarOpen)

  return (
    <Link 
      to={props.url} 
      className={clsx(classes.mainMenuItem, isSelected && classes.itemSelected, props.isTabletViewport && classes.itemContracted)} 
      onClick={(e) => {
        props.onClick ? props.onClick(e) : stateIsSidebarOpen(!isSidebarOpen)
      }}
    >
      <span className={classes.itemIcon}>
        <IconComponent fillColor={isSelected ? colorHighlight : ''} />
      </span>
      <span className={classes.itemText}>{props.title}</span>
    </Link>
  )
}

export default MainMenuItem
