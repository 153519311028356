import React, { useState, useEffect } from 'react'
import useStyles from './OrderDetailsNotes.styles'
import Button from 'components/Button/Button'
import TextField from '@material-ui/core/TextField'

interface Props {
  notes: string
  onUpdateOrderNotes: (notes: string) => void
}

const OrderDetailsNotes = (props: Props) => {
  const { notes, onUpdateOrderNotes } = props
  const classes = useStyles()
  const [charsLeft, setCharsLeft] = useState<null | number>(null)
  const [notesState, setNotesState] = useState(notes)
  const maxCharsAllowed = 5000

  const handleUpdateOrderNotes = (e?: React.SyntheticEvent<EventTarget>) => {
    if (e) {
      e.preventDefault()
    }
    onUpdateOrderNotes(notesState)
  }

  const handleOnChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setNotesState(value)
    setCharsLeft(maxCharsAllowed - value.length)
  }

  const handleOnBlur = () => {
    setCharsLeft(null)
  }

  useEffect(() => {
    setNotesState(notes)
  }, [notes])

  return (
    <div className={classes.notes}>
      <form onSubmit={handleUpdateOrderNotes}>
        <TextField
          id="standard-multiline-static"
          label="Order Notes"
          classes={{ root: classes.textField }}
          rowsMax={8}
          multiline
          inputProps={{ maxLength: maxCharsAllowed }}
          value={notesState}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        {charsLeft !== null ? (
          <span className={classes.charsLeft}>Characters left: {charsLeft}</span>
        ) : null}
        <Button
          type="primary"
          isSubmit={true}
          onMouseDown={handleUpdateOrderNotes}
          size="small"
          title={notes === '' ? 'Add' : 'Update'}
        />
      </form>
    </div>
  )
}

export default OrderDetailsNotes
