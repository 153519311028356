import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontSizes, fontWeights } from '../../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardWidget: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '100%',
      borderRadius: '22px',
      background: '#fff',
      boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
      overflow: 'hidden',
    },
    fullWidth: {
      width: '100%',
      marginRight: 0,
    },
    mediumWidth: {
      width: '1272px',
    },
    mediumHeight: {
      height: '380px',
    },
    largeHeight: {
      height: '667px',
    },
    xLargeHeight: {
      height: '873px',
    },
    contentOnly: {
      height: 'auto',
      background: 'transparent',
      boxShadow: 'none',
      '& $body': {
        padding: 0,
      },
      '&::after': {
        display: 'none',
      },
    },
    body: {
      marginBottom: 0,
      flex: '1',
      overflow: 'auto',
    },
    header: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '66px',
      padding: '17px 24px',
      borderBottom: '1px solid rgba(184, 191, 194, .3)',
      flex: '0',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: '-2px',
        width: '12px',
        height: '4px',
        background: '#8DCA00',
      },
    },
    headerTitle: {
      fontSize: '18px',
      fontWeight: fontWeights.bold,
    },
    headerButton: {
      marginLeft: 'auto',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      minHeight: '60px',
      padding: '20px 28px',
      background: 'rgba(240, 242, 242, .96)',
      zIndex: 2,
    },
    footerLink: {
      fontFamily: 'Avenir Roman',
      fontSize: fontSizes.small,
      fontWeight: fontWeights.light,
      lineHeight: '18px',
      color: '#05ADD6',
      cursor: 'pointer',
      backgroundColor: 'inherit',
    },
  })
)

export default useStyles
