import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontSizes } from 'assets/jss/variables'

const contentWidth = '1140px'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dispatchNoteContainer: {
      borderBottom: '2px solid #F1F2F3',
      marginBottom: '30px',
      color: '#000',
      '@media print': {
        minWidth: '740px',
        fontSize: fontSizes.subtitle,
      },
    },
    heading: {
      display: 'flex',
      backgroundColor: '#F1F2F3',
      height: '193px',
      justifyContent: 'center',
      alignItems: 'center',
      '& > div': {
        width: 'calc(100% - 40px)',
        [theme.breakpoints.up('lg')]: {
          width: contentWidth,
        },
        '@media print': {
          width: contentWidth,
        },
        padding: '20px 0 20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      },
      '& h1': {
        position: 'relative',
        fontSize: '34px',
        fontWeight: 'bold',
        bottom: '20px',
        color: '#000',
      },
    },
    logo: {
      height: '125px',
    },
    detailsContainer: {
      width: 'calc(100% - 40px)',
      [theme.breakpoints.up('lg')]: {
        width: contentWidth,
      },
      margin: '0 auto 0 auto',
      '@media print': {
        width: 'auto',
      },
    },
    firstAddressLine: {
      marginBottom: '10px',
    },
    lastAddressLine: {
      marginTop: '10px',
    },
    orderDetails: {
      display: 'block',
      margin: '30px 0 30px 0',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '@media print': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& > li': {
        lineHeight: '24px',
      },
      '& > li:last-child': {
        marginRight: '0',
      },
      '& span': {
        fontWeight: 'bold',
        paddingLeft: '10px',
      },
    },
    orderCards: {
      display: 'flex',
      marginBottom: '50px',
      '@media print': {
        marginBottom: '30px',
      },
    },
    card: {
      borderRadius: '22px',
      border: '1px solid #0000001A',
      padding: '10px',
      [theme.breakpoints.up('lg')]: {
        padding: '30px',
      },
      flexGrow: 1,
      '&:first-child': {
        marginRight: '20px',
      },
      '& > h2': {
        fontSize: '18px',
        fontWeight: 'bold',
      },
      '@media print': {
        padding: '15px',
        '& > h2': {
          fontSize: fontSizes.hybrid,
        },
      },
    },
    tableContainer: {
      borderRadius: '22px',
      border: '1px solid #0000001A',
      overflow: 'hidden',
      marginBottom: '50px',
      '& td, & th': {
        padding: '5px 10px',
        color: '#000!important',
        [theme.breakpoints.up('lg')]: {
          padding: '15px 30px',
        },
        '@media print': {
          padding: '10px 15px!important',
        },
      },
      '& th': {
        whiteSpace: 'nowrap',
      },
    },
    evenRow: {
      backgroundColor: '#EDEFF0',
    },
    thankyouText: {
      textAlign: 'center',
      '& > p': {
        marginBottom: '30px',
      },
      '& h3': {
        display: 'block',
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '10px',
      },
    },
    disclaimer: {
      textAlign: 'center',
    },
    footer: {
      color: '#000',
      textAlign: 'center',
      '& p': {
        marginBottom: '5px',
      },
      '@media print': {
        pageBreakAfter: 'always',
        fontSize: fontSizes.subtitle,
      },
    },
    contactDetails: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto 50px auto',
      '& > span': {
        margin: '0 20px',
      },
    },
  })
)

export default useStyles
