import * as React from 'react'
import { useStyles } from './OrdersSelected.styles'
import Button from 'components/Button/Button'
import IconPrint from 'components/Icons/IconPrint/IconPrint'
import { colors } from 'assets/jss/variables'
import clsx from 'clsx'
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
} from '@material-ui/core'

export interface OrdersSelectedProps {
  orderCount: number
  printSelectedList?: () => void
  onUserAssignmentChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void
  show: boolean
  isAdminUser?: boolean
  pickerList: { id: string, name:string, image: string }[]
  pickerSelected?: string | null
}

const OrdersSelected = ({ orderCount, printSelectedList, show, isAdminUser, onUserAssignmentChange, pickerList, pickerSelected }: OrdersSelectedProps) => {
  const classes = useStyles({ show })
  const plural = orderCount > 1 ? 's' : ''
  return (
    <div className={classes.ordersSelectedContainer}>
      <Button
        type="textOnly"
        icon={<IconPrint fillColor={colors.white} />}
        title={`Print Pick List${plural} (${orderCount})`}
        onClick={printSelectedList}
        className={classes.printButton}
      />

    { isAdminUser && orderCount > 0 && (
        <FormControl className={classes.selectMenu}>
                <InputLabel id="user-assignment-label">Assign a picker</InputLabel>
                <Select
                  labelId="user-assignment-label"
                  id="user-assignment"
                  name="userAssignment"
                  onChange={onUserAssignmentChange}
                  input={<Input />}
                  value={pickerSelected}
                >
                  {pickerList.map((option: { id: string, name:string, image: string }) => (
                    <MenuItem value={option.id}>{option.image ? (<img src={option.image} className={classes.userImage} alt={option.name} />) : (<div className={clsx(classes.userImage, classes.imageFallback)} ></div>)} {option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
    )}
    </div>
  )
}

export default OrdersSelected
