import { gql } from '@apollo/client'

export const OrderDetails = {
  fragments: {
    orderProperties: gql`
      fragment OrderProperties on Order {
        id
        orderNumber
        placedAt
        pickingDate
        deliveryMethod
        status
        dueDeliveryDate
        paymentStatus
        paymentMethod
        notes
        deliveryAddress {
          name
          address1
          address2
          city
          region
          postcode
          country
          phone
        }
        billingAddress {
          name
          address1
          address2
          city
          region
          postcode
          country
          phone
        }
        customer {
          email
          name
          phone
        }
        deliveryCost {
          amount
          currency
        }
        consignments {
          id
          lineItems {
            id
            productId
            title
            promotion
            imageUrl
            priceUnit {
              amount
              currency
            }
            priceTotal {
              amount
              currency
            }
            binNo
            lineCode
            quantityOrdered
            quantityNotPicked
            quantityPartiallyPicked
            quantityPicked
            quantityRefunded
          }
          shipment {
            id
          }
        }
        subTotal {
          amount
          currency
        }
        deliveryCost {
          amount
          currency
        }
        grandTotal {
          amount
          currency
        }
        revisedGrandTotal {
          amount
          currency
        }
        assignedTo {
          id
          externalId
          name
          email
          image
          roles
        }
      }
    `,
  },
}
