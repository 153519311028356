import { makeStyles } from '@material-ui/core/styles'
import { fontWeights, spacing, typography } from '../../assets/jss/variables'

const styles = makeStyles((theme) => ({
  modal: {
    padding: spacing.medium,
  },
  header: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing.small,
    flex: '0 0 auto',
    '& h3': {
      ...typography.large,
      fontWeight: fontWeights.bold,
    },
  },
  contentContainer: {
    minWidth: '350px',
  },
}))

export default styles
