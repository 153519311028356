import { makeStyles } from '@material-ui/core/styles'
import { colors, fontSizes, fontWeights } from '../../assets/jss/variables'

const useStyles = makeStyles(() => ({
  percentageIndicator: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 110,
    height: 110,
    '& li': {
      textAlign: 'center',
    },
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  mainText: {
    fontSize: fontSizes.xxl,
    lineHeight: '1em',
    fontWeight: fontWeights.bold,
    color: colors.grey.grey2,
  },
  subText: {
    fontSize: fontSizes.hybrid,
    fontWeight: fontWeights.light,
    color: colors.grey.grey3,
  },
}))

export default useStyles
