import React from 'react'
import Button from 'components/Button/Button'
import useStyles from '../../../assets/jss/dialog.style'
import NumberChanger from '../../NumberChanger/NumberChanger'

interface PropLabel {
  consignmentId: string
  quantity: number | string
}

interface Props {
  parcelValues: PropLabel[]
  onConfirm: () => void
  onClose: () => void
  errors: string[]
  onSetParcelQuantity: (quantity: string, consignmentId: string, index: number) => void
}

const OrderDetailsProcessDialog = (props: Props) => {
  const { parcelValues, errors, onConfirm, onSetParcelQuantity, onClose } = props
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const handleConfirm = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    onConfirm()
  }

  return (
    <form noValidate onSubmit={handleConfirm}>
      <div>
        {parcelValues.map((label, index) => (
          <div key={label.consignmentId} className={classes.inputContainer}>
            <span>Parcels required for consignment {index + 1}</span>
            <NumberChanger minQuantity={1} initialValue={Number(label.quantity)} onChange={(value) => onSetParcelQuantity(String(value), label.consignmentId, index)} />
            {errors.includes(label.consignmentId) && (
              <span className={classes.errorMessage}>Please enter a quantity greater than 0</span>
            )}
          </div>
        ))}
      </div>
      <div className={classes.content}>
        <Button title="Confirm" isSubmit={true} fullWidth={true} size="medium" />
        <Button onClick={handleClose} title="Cancel" type="secondary" fullWidth={true} size="medium" />
      </div>
    </form>
  )
}

export default OrderDetailsProcessDialog
