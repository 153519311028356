import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardSummary: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiGrid-container': {
        flexDirection: 'row',
      },
    },
    fullWidth: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '&:not(:last-of-type)': {
        borderBottom: '1px solid rgba(184, 191, 194, 0.3)',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    singlePanel: {
      padding: '21px 31px 0 37px',
    },
    primarySection: {
      width: '100%',
    },
    secondarySection: {
      flex: 1,
      padding: '31px 31px 0 37px',
      '&:not(:last-of-type)': {
        borderBottom: '1px solid rgba(184, 191, 194, 0.3)',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '88px',
        '&:not(:last-of-type)': {
          borderBottom: 0,
          borderRight: '1px solid rgba(184, 191, 194, 0.3)',
        },
        '& $sectionBottom tr': {
          maxWidth: '280px',
        },
      },
      [theme.breakpoints.up('lg')]: {
        '& $sectionBottom tr': {
          marginLeft: '130px',
        },
      },
      [theme.breakpoints.up('xl')]: {
        '& $sectionBottom tr': {
          maxWidth: 'none',
        },
      },
    },
    secondarySectionIcon: {
      marginRight: '50px',
      [theme.breakpoints.up('lg')]: {
        marginRight: '100px',
      },
    },
    sectionTop: {
      marginBottom: '25px',
      width: '40%',
    },
    sectionBottom: {
      '& tr': {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-40px',
        [theme.breakpoints.up('md')]: {
          maxWidth: '560px',
        },
        [theme.breakpoints.up('xl')]: {
          maxWidth: 'none',
        },
      },
      '& td': {
        border: 'none',
        marginBottom: '15px',
        padding: '0 0 0 40px',
      },
    },
  })
)

export default useStyles
