import { makeStyles, Theme } from '@material-ui/core/styles'
import image from '../../../../assets/images/marker.svg'
import { card } from '../../../../assets/jss/common'
import { colors, fontSizes, fontWeights } from '../../../../assets/jss/variables'

const selectedRow = {
  backgroundImage: 'url(' + image + ')',
  backgroundRepeatX: 'no-repeat',
  backgroundRepeatY: 'no-repeat',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '5px 100%',
}

const useStyles = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
  orderResultsContainer: {
    '& button': {
      margin: '0 auto 0 auto',
      display: 'block',
    },
  },
  noResultsFound: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '& > p': {
      fontSize: '18px',
    },
  },
  orderResultsTable: {
    ...card,
    height: '100%',
    minHeight: '300px',
    marginBottom: '30px',
    position: 'relative',
    fontSize: fontSizes.subtitle,
    [theme.breakpoints.up('md')]: {
      fontSize: fontSizes.hybrid,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: 'transparent',
      '& > .MuiTableCell-body:first-child': {
        ...selectedRow,
      },
    },
    '& .MuiTableRow-root:last-child': {
      backgroundColor: 'transparent',
      '& > .MuiTableCell-body': {
        border: 'none',
      },
    },
  },
  loader: {
    width: '30px',
    height: '0px',
    position: 'sticky',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelected: {
    '&.MuiTableRow-root.Mui-selected': {
      backgroundColor: 'transparent',
      '& > .MuiTableCell-body:first-child': {
        ...selectedRow,
      },
    },
  },
  pickerButtons: {
    display: 'flex',
    width: 'fit-content',
    '& button': {
      padding: '0 0 3px 0',
      marginRight: '5px',
      '&:first-child': {
        marginRight: '20px',
      },
    },
  },
  selected: {
    fontWeight: fontWeights.bold,
  },
  tabButton: {
    cursor: 'pointer',
    paddingBottom: '5px',
    borderBottom: `5px solid ${colors.white}`,
    [theme.breakpoints.up('md')]: {
      fontSize: fontSizes.small,
    },
    '&$selected': {
      borderBottom: `5px solid ${colors.orange}`,
      '&:hover': {
        borderBottom: `5px solid ${colors.orange}`,
      },
    },
    '&:hover': {
      borderBottom: `5px solid ${colors.white}`,
    },
  },
  tabHeaderCell: {
    '&.MuiTableCell-head': {
      paddingBottom: 0,
    },
  },
  assignedCount: {
    backgroundColor: colors.green,
    color: colors.white,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    padding: '5px',
    marginBottom: '7px',
    lineHeight: 'initial',
    fontSize: fontSizes.subtitle,
    fontWeight: fontWeights.bold,
  },
  orderCellInner: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: fontSizes.label,
    },
    '& > span:first-of-type': {
      fontSize: fontSizes.subtitle,
      fontWeight: fontWeights.bold,
      [theme.breakpoints.up('md')]: {
        fontSize: 'inherit',
        fontWeight: fontWeights.regular,
      },
    },
  },
  smallTabletOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      '&.MuiTableCell-root': {
        display: 'none',
      },
    },
  },
  mediumTabletUp: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      '&.MuiTableCell-root': {
        display: 'none',
      },
    },
  },
}))

export default useStyles
