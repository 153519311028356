import { makeStyles, Theme } from '@material-ui/core/styles'
import image from '../../assets/images/footer-dashes.svg'
import { colors, fontSizes, fontWeights } from '../../assets/jss/variables'

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    position: 'relative',
    paddingBottom: '35px',
    color: colors.grey.grey3,
    background: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom left',
    backgroundSize: 'contain',
    '@media print': {
      background: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      backgroundSize: '228px 4px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0,
      backgroundPosition: '100% 100%',
    },
  },
  copyright: {
    fontSize: fontSizes.subtitle,
    fontWeight: fontWeights.light,
    lineHeight: '1rem',
  },
  buildInfo: {
    display: 'none',
    width: 'calc(100% - 250px)',
  },
  content: {
    '&:hover': {
      '& $buildInfo': {
        display: 'block',
      },
      '& $copyright': {
        display: 'none',
      },
    },
  },
}))
