import { makeStyles } from '@material-ui/core/styles'
import { fontSizes, fontWeights, spacing } from '../../assets/jss/variables'

export const useStyles = makeStyles((theme) => ({
  PageTitle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: spacing.large,
  },
  title: {
    fontFamily: 'Avenir Bold',
    fontSize: fontSizes.large,
    lineHeight: fontSizes.large,
    marginBottom: spacing.small,
  },
  intro: {
    marginBottom: spacing.small,
    maxWidth: '560px',
    fontWeight: fontWeights.light,
  },
  actions: {
    marginLeft: 'auto',
  },
}))
