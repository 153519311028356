import React, { Reducer, useReducer } from 'react'
import { useStyles } from './OrderCountLozenge.styles'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { OrderListSearch } from '../OrderList/OrderList.types'
import { initialFilterState } from '../OrderList/OrderList'
import { ODCOrderStatus, orderStatuses, StatusColour } from '../../../helpers'
import { DeliveryMethodDetail } from '../../../types'

interface OrderCountLozengeProps {
  breakWord?: boolean
  count: number
  deliveryMethods?: DeliveryMethodDetail[]
  font?: string
  label?: string
  status?: string[]
  type?: string
}

const inPickingOrderStates = Object.keys(orderStatuses).filter(
  (status: string) => status === ODCOrderStatus.IN_PICKING.status
)

const determineLabel = (props: OrderCountLozengeProps) => {
  if (props.label) return props.label
  if (props.deliveryMethods && props.deliveryMethods.length > 0)
    return props.deliveryMethods[0].shortLabel
  if (props.status) return props.status[0]

  return 'Unknown'
}

const determineStyle = (props: OrderCountLozengeProps) => {
  if (props.deliveryMethods && props.deliveryMethods.length > 0)
    return props.deliveryMethods[0].name
  if (props.status) return props.status[0]

  return 'Unknown'
}

const OrderCountLozenge = (props: OrderCountLozengeProps) => {
  const label = determineLabel(props)
  const labelForDisplay = label.toUpperCase().replace('_', ' ')
  const classes = useStyles({ style: determineStyle(props) })

  const reducer: Reducer<OrderListSearch, any> = (state: OrderListSearch): OrderListSearch => {
    let newState = {
      ...state,
      filters: {
        ...state.filters,
        deliveryMethod: props.deliveryMethods ? props.deliveryMethods.map((dm) => dm.name) : [],
        orderStatus: props.status ? props.status : inPickingOrderStates,
        pickingDate: new Date(),
      },
      sort: {
        field: 'placedAt',
        direction: 'DESC',
      },
    }

    localStorage.setItem('listSearch', JSON.stringify(newState))
    return newState
  }

  const [state, dispatch] = useReducer(reducer, initialFilterState)
  const handleSearch = () => dispatch({ state: state })
  const textClass = props.breakWord ? 'numeric-indicator__text__break' : ''
  const fontClass = props.font === 'heavy' ? 'numeric-indicator__text__heavy' : ''

  return (
    <div className={classes.numericIndicator}>
      <ul>
        <li>
          <span className={clsx(classes.text, textClass, fontClass)}>
            {labelForDisplay}
          </span>
          <div className={classes.orderCountLozenge} style={{ marginTop: '10px' }}>
            <span>&nbsp;</span>
          </div>
        </li>
        <li>
          <Link to="/orders" onClick={handleSearch}>
            <span
              className={classes.value}
              style={{ color: StatusColour(determineStyle(props)) }}
            >
              {props.count}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default OrderCountLozenge
