import { colors } from '../jss/variables'

export const card = {
  borderRadius: '22px',
  border: '1px solid #0000001A',
  backgroundColor: '#fff',
  boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
}

export const noImage = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '80px',
  fontSize: '14px',
  color: '#35526D',
  backgroundColor: colors.grey.grey1,
}

export const productImage = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '80px',
  '& img': {
    maxHeight: '80px',
    width: 'auto',
  },
}

export const errorMessage = {
  color: 'red',
  display: 'block',
  marginTop: '10px',
}

export const errorInput = {
  border: '1px solid red',
}

export const buttonPrintContainer = {
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0 20px 0',
  '& > button': {
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  '@media print': {
    display: 'none',
  },
}
