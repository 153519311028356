
import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'
import useStyles from './Logo.styles'
import { stateIsSidebarOpen } from '../../cache'
import clsx from 'clsx'
import logoSmall from '../../assets/images/logo-stacked.svg'
import logoLarge from '../../assets/images/logo-inline.svg'

const Logo = () => {
  const classes = useStyles()
  const isSidebarOpen = useReactiveVar(stateIsSidebarOpen)
  return (
    <div className={classes.logo}>
      <Link 
        to="/dashboard" 
        className={classes.logoLink}
        onClick={() => {
          stateIsSidebarOpen(!isSidebarOpen)
        }}
      >
        <img className={clsx(classes.logoImage, classes.logoImageLarge)} width="180" height="18" src={logoLarge} alt="" />
        <img className={clsx(classes.logoImage, classes.logoImageSmall)} width="32" height="18" src={logoSmall} alt="" />
      </Link>
    </div>
  )
}

export default Logo
