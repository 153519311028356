import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      '& h1': {
        fontSize: '18px',
        lineHeight: '1em',
        [theme.breakpoints.up('lg')]: {
          fontSize: '24px',
        },
      },
    },
  })
)

export default useStyles
