import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors, fontSizes } from 'assets/jss/variables'
import { errorInput } from '../../../assets/jss/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContent: {
      marginBottom: '20px',
    },
    productDetail: {
      display: 'flex',
      marginBottom: '20px',
      '& button': {
        fontSize: fontSizes.medium,
        height: 'fit-content',
      },
    },
    productInfoContainer: {
      width: '310px',
      marginRight: '40px',
    },
    quantityRow: {
      height: '60px',
      marginBottom: '10px',
      '&:before': {
        backgroundColor: colors.grey.grey1,
        content: "''",
        height: '60px',
        width: '500px',
        position: 'absolute',
        left: '-20px',
      },
    },
    quantityDetails: {
      height: '100%',
      alignItems: 'center',
      position: 'relative',
    },
    quantityOrdered: {
      width: '160px',
      textAlign: 'center',
    },
    quantityButton: {},
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    buttonContainer: {
      textAlign: 'right',
    },
    textField: {
      display: 'block',
      width: '100%',
      margin: '0px 0 20px 0',
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
    errorInput,
  })
)

export default useStyles
