import React, { Fragment, Reducer, useEffect, useReducer, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep, get, has, isEmpty, pick } from 'lodash'
import { GET_ORDER, GET_ORDER_USER_HISTORY, GET_PRODUCTS, SHIPPING_LABEL } from '../../graphQL/queries'
import {
  CANCEL_ORDER,
  COMPLETE_ORDER,
  SAVE_ORDER,
  HOLD_AND_SAVE_ORDER,
  UPDATE_ORDER_NOTES,
  START_PICKING_ORDERS,
  ASSIGN_ORDERS_TO_USER,
  READY_FOR_PACKING_ORDER
} from '../../graphQL/mutations'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import Spinner from 'components/Spinner/Spinner'
import OrderDetailsHeader from './OrderDetailsHeader/OrderDetailsHeader'
import OrderDetailsTable from './OrderDetailsTable/OrderDetailsTable'
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog'
import OrderDetailsUpdate from './OrderDetailsUpdate/OrderDetailsUpdate'
import OrderDetailsProcessDialog from './OrderDetailsProcessDialog/OrderDetailsProcessDialog'
import OrderDetailsOverview from './OrderDetailsOverview/OrderDetailsOverview'
import OrderDetailsActionChoice from './OrderDetailsActionChoice/OrderDetailsActionChoice'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner'
import { stateIsDebugModeActive, stateSelectedOrderIds, stateUser, stateIsActionbarOpen } from '../../cache'
import { downloadFile, isOfType } from '../../helpers'
import OrderDetailsSummaryAction from './OrderDetailsSummaryAction/OrderDetailsSummaryAction'
import { Consignments, OrderProps } from '../../types'
import { orderStatuses, useImperativeQuery } from '../../helpers'
import { LineItems, Payload, PropLabel, UpdateQty } from './OrderDetails.props'
import useStyles from './OrderDetails.styles'
import Notification from '../Notification/Notification'
import IconCamera from '../Icons/IconCamera/IconCamera'
import IconChat from '../Icons/IconChat/IconChat'
import { useAuth0 } from '@auth0/auth0-react'

const initParcelReducer = (consignments: Consignments[]) => {
  if (consignments) {
    return consignments.map((item) => ({
      consignmentId: item.id,
      quantity: 1,
    }))
  }
  return []
}

const parcelReducer: Reducer<PropLabel[], any> = (
  labelState: PropLabel[],
  action: { type: string; payload: Payload | Consignments[] }
) => {
  const { type, payload } = action
  let newState = cloneDeep(labelState)
  if (type === 'setField' && isOfType<Payload>(payload, 'consignmentId')) {
    const { consignmentId, quantity, index } = payload
    newState[index] = { consignmentId, quantity }
  } else if (type === 'init' && isOfType<Consignments[]>(payload, 0)) {
    newState = initParcelReducer(payload)
  }
  return newState
}

const OrderDetails = () => {
  const { user } = useAuth0()
  const sub = user ? user.sub : undefined
  const userDetails = useReactiveVar(stateUser)
  const isDebugModeActive = useReactiveVar(stateIsDebugModeActive)
  const history = useHistory()
  const classes = useStyles()
  const pickedLineItemsRef: any = useRef([])
  const [showOverview, setShowOverview] = useState(false)
  const [showPickingIncomplete, setShowPickingIncomplete] = useState(false)
  const [showPackingReady, setShowPackingReady] = useState(false)
  const [completePick, setCompletePick] = useState(false)
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false)
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false)
  const [showHoldConfirmation, setShowHoldConfirmation] = useState<boolean>(false)
  const [showManualUpdate, setShowManualUpdate] = useState(false)
  const [updatingQuantity, setUpdatingQuantity] = useState<UpdateQty | null>()
  const [showProcessOrder, setShowProcessOrder] = useState(false)
  const [stateData, setStateData] = useState<OrderProps>()
  const [error, setError] = useState<string | null>(null)
  const [processingIssues, setProcessingIssues] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [selectedConsignment, setSelectedConsignment] = useState<string | null>()
  const [lineItemsPicked, setPickedLineItem] = useState<LineItems[]>([])
  const [hasNotes, setHasNotes] = useState(false)
  const [getOrder, { loading, data }] = useLazyQuery(GET_ORDER, {
    fetchPolicy: 'network-only',
  })
  const [getOrderUserHistory, { data: orderUserHistory }] = useLazyQuery(GET_ORDER_USER_HISTORY, {
    fetchPolicy: 'network-only',
  })
  const getProducts = useImperativeQuery(GET_PRODUCTS)
  const [saveOrder, { loading: loadingSaveOrder, data: saveOrderResponse }] = useMutation(SAVE_ORDER)
  const [completeOrder, { loading: loadingCompleteOrder, data: completeOrderResponse }] = useMutation(COMPLETE_ORDER)
  const [startPickingOrders, { loading: loadingPickingOrder }] = useMutation(START_PICKING_ORDERS)
  const [readyForPackingOrder, { loading: loadingReadyForPackingOrder}] = useMutation(READY_FOR_PACKING_ORDER)
  const [cancelOrder, { loading: loadingCancelOrder, data: cancelOrderResponse }] = useMutation(CANCEL_ORDER)
  const [holdAndSaveOrder, { loading: loadingHoldAndSaveOrder, data: holdAndSaveOrderResponse }] = useMutation(HOLD_AND_SAVE_ORDER)
  const [assignOrdersToUser] = useMutation(ASSIGN_ORDERS_TO_USER)
  const [updateOrderNotes, { loading: loadingOrderNotes }] = useMutation(UPDATE_ORDER_NOTES)
  const [
    getShippingLabel,
    { loading: loadingShippingLabel, error: errorShippingLabel, data: dataShippingLabel },
  ] = useLazyQuery(SHIPPING_LABEL)
  const [parcelErrors, setParcelErrors] = useState<string[]>([])
  const [parcelValues, dispatchParcelQty] = useReducer(parcelReducer, [], initParcelReducer)
  const [partialCompletePicking, setPartialCompletePicking] = useState(false)
  const { orderId } = useParams<{ orderId: string }>()

  if (!orderId) {
    history.push('/orders')
  }

  const savedStatus = orderStatuses[get(stateData, 'status')!]
  const completedStatus =
  savedStatus === orderStatuses.COMPLETED || savedStatus === orderStatuses.PARTIALLY_COMPLETED
  const canShowActionBar = 
    (savedStatus === orderStatuses.OPEN ||
    savedStatus === orderStatuses.ON_HOLD ||
    savedStatus === orderStatuses.IN_PICKING ||
    savedStatus === orderStatuses.PARTIALLY_PICKED ||
    savedStatus === orderStatuses.ASSIGNED)

  useEffect(() => {
    const variables = {
      variables: {
        id: orderId,
      }
    }
    getOrderUserHistory(variables)
    getOrder(variables)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (has(data, 'order')) {
      setStateData(data.order)
      dispatchParcelQty({
        type: 'init',
        payload: data.order.consignments,
      })
      data.order.notes !== '' ? setHasNotes(true) : setHasNotes(false)
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (has(data, 'order')) {
      const consignments = data.order.consignments
      if (consignments) {
        consignments.forEach((consignment: Consignments) => {
          const consignmentLineItems = consignment.lineItems
          if (consignmentLineItems && lineItemsPicked.length === 0) {
            const partiallyPickedLineItems = lineItemsPicked
            consignmentLineItems.forEach((consignmentLineItem) => {
              partiallyPickedLineItems.push({
                id: consignmentLineItem.id,
                quantity: consignmentLineItem.quantityPartiallyPicked,
                quantityNotPicked: consignmentLineItem.quantityNotPicked
              })
            })
            setPickedLineItem(partiallyPickedLineItems)
            pickedLineItemsRef.current = partiallyPickedLineItems
          } else {
            setCompletePick(consignmentLineItems.every((lineItem) => {
              const currentlyPickLineItem = lineItemsPicked.find((pickedLineItem) => pickedLineItem.id === lineItem.id)
              return currentlyPickLineItem && currentlyPickLineItem.quantity === lineItem.quantityOrdered
            }))
          }
        })
      }
    }
  }, [data, lineItemsPicked])

  useEffect(() => {
    if (lineItemsPicked.length && !isDebugModeActive) {
      handleSaveOrder()
    }
    // eslint-disable-next-line
  }, [lineItemsPicked])

  useEffect(() => {
    if (!saveOrderResponse) return
    else if (saveOrderResponse.saveOrder) {
      const issues = saveOrderResponse.saveOrder.errorMessage
      setStateData(saveOrderResponse.saveOrder.order)
      setProcessingIssues(issues ? issues : null)
    }
    // eslint-disable-next-line
  }, [saveOrderResponse])

  useEffect(() => {
    if (!completeOrderResponse) return
    else if (completeOrderResponse.completeOrder) {
      const issues = completeOrderResponse.completeOrder.errorMessage
      setStateData(completeOrderResponse.completeOrder.order)
      setProcessingIssues(issues ? issues : null)
    }
    // eslint-disable-next-line
  }, [completeOrderResponse])

  useEffect(() => {
    if (!cancelOrderResponse) return
    else if (cancelOrderResponse.cancelOrder) {
      setStateData(cancelOrderResponse.cancelOrder)
    }
    // eslint-disable-next-line
  }, [cancelOrderResponse])

  useEffect(() => {
    if (!holdAndSaveOrderResponse) return
    else if (holdAndSaveOrderResponse.holdAndSaveOrder) {
      const issues = holdAndSaveOrderResponse.holdAndSaveOrder.errorMessage
      setStateData(holdAndSaveOrderResponse.holdAndSaveOrder.order)
      setProcessingIssues(issues ? issues : null)
    }
    // eslint-disable-next-line
  }, [holdAndSaveOrderResponse])

  useEffect(() => {
    if (errorShippingLabel) {
      setError(errorShippingLabel.toString())
    } else if (dataShippingLabel) {
      const labelContent = get(dataShippingLabel, 'shipmentLabel.data')
      const filename = `dpd-orderid-${orderId}-consid-${selectedConsignment}.txt`
      downloadFile(labelContent, filename)
    }
    // eslint-disable-next-line
  }, [dataShippingLabel, errorShippingLabel])

  useEffect(() => {
    stateIsActionbarOpen(canShowActionBar)
    // eslint-disable-next-line
  }, [savedStatus])

  const handleStartPicking = async () => {
    try {
      if (!stateData?.assignedTo?.id) {
        await assignOrdersToUser({
          variables: {
            orderIds: [orderId],
            userId: userDetails?.id,
          },
        })
      }
      await startPickingOrders({ variables: { orderIds: [orderId], userId: sub } })
      getOrder({
        variables: {
          id: orderId,
        },
      })
    } catch (error) {
      setError('Error: could not set the order to in-picking')
    }
  }

  const handleSetParcelQuantity = (quantity: string, consignmentId: string, index: number) => {
    setParcelErrors(parcelErrors.filter((id) => id !== consignmentId))
    const formQuantity = !isNaN(Number(quantity)) ? Number(quantity) : quantity
    const isEmptyString = quantity === ''
    if (formQuantity === '' || formQuantity >= 0) {
      dispatchParcelQty({
        type: 'setField',
        payload: { consignmentId, quantity: isEmptyString ? '' : formQuantity, index },
      })
    }
  }

  const handleCloseConfirmation = () => {
    setShowProcessOrder(false)
    if (stateData) {
      dispatchParcelQty({
        type: 'init',
        payload: stateData.consignments,
      })
      setParcelErrors([])
    }
  }

  const handleCompleteOrder = async () => {
    const formErrors = parcelValues.filter((field) => field.quantity < 1).map((field) => field.consignmentId)
    if (formErrors.length) {
      setParcelErrors(formErrors)
      return false
    }
    setShowProcessOrder(false)
    try {
      await completeOrder({
        variables: {
          id: orderId,
          lineItems: lineItemsPicked,
          labels: parcelValues,
          userId: sub,
        },
      })
    } catch (e: any) {
      setError(e.message)
    }
  }

  const actionCancelOrder = async () => {
    setShowCancelConfirmation(false)
    try {
      await cancelOrder({
        variables: {
          id: orderId,
          userId: sub,
        },
      })
      window.location.reload()
    } catch (e: any) {
      setError(e.message)
    }
  }

  const handlePickLineItem = (lineItemId: string, quantity: number, quantityOrdered: number) => {
    const otherLineItems = pickedLineItemsRef.current.filter((item:any) => item.id !== lineItemId)
    otherLineItems.push({ id: lineItemId, quantity, quantityNotPicked: quantityOrdered - quantity  })
    setUpdatingQuantity(null)
    pickedLineItemsRef.current = otherLineItems
    setPickedLineItem(otherLineItems)
    if(updatingQuantity) {
      quantity < updatingQuantity?.quantityOrdered ? setPartialCompletePicking(true) : setPartialCompletePicking(false) 
    }
  }

  const handleEditLineItem = (lineItemId: string, consignmentIndex: number) => {
    const lineItem = data.order.consignments[consignmentIndex].lineItems.find((item: any) => item.id === lineItemId)
    const { imageUrl, title, promotion, quantityOrdered, lineCode } = lineItem

    setUpdatingQuantity({
      lineItemId,
      lineCode,
      imageUrl,
      title,
      promotion,
      quantityOrdered
    })
    setShowManualUpdate(true)
  }

  const handlePrintPicklist = () => {
    stateSelectedOrderIds([orderId as string])
    history.push(`/picklist/${orderId}`)
  }

  const handlePrintDispatchNote = (consignmentId?: number) => {
    history.push(`/dispatch-note/${orderId}${consignmentId !== undefined ? `/${consignmentId}` : ''}`)
  }

  const handleCloseNotification = () => {
    setError(null)
    setProcessingIssues(null)
    setSuccess(null)
  }

  const handleSaveOrder = async (completePicking=false) => {
    setPickedLineItem(lineItemsPicked)
    pickedLineItemsRef.current = lineItemsPicked
    setShowPickingIncomplete(false)
    try {
      await saveOrder({
        variables: {
          id: orderId,
          lineItems: lineItemsPicked,
          userId: sub,
          completePicking
        },
      })
    } catch (e: any) {
      setError(e.message)
    }
  }

  const actionHoldAndSaveOrder = async () => {
    setPickedLineItem(lineItemsPicked)
    pickedLineItemsRef.current = lineItemsPicked
    setShowPickingIncomplete(false)
    setShowHoldConfirmation(false)
    try {
      await holdAndSaveOrder({
        variables: {
          id: orderId,
          lineItems: lineItemsPicked,
          userId: sub,
        },
      })
      window.location.reload()
    } catch (e: any) {
      setError(e.message)
    }
  }

  const handleReadyForPackingOrder = async () => {
    try {
      setShowPackingReady(false)
      const response = await readyForPackingOrder({
        variables: {
          id: orderId,
          lineItems: lineItemsPicked,
          userId: sub,
        },
      })
      const updatedOrder = response.data?.readyForPackingOrder?.order
      updatedOrder && setStateData(updatedOrder)
      window.location.reload()
    } catch (e: any) {
      setError(e.message)
    }
  }

  const handleProcessOrder = () => {
    setShowProcessOrder(true)
  }

  const handleUpdateOrderNotes = async (notes: string) => {
    try {
      const response = await updateOrderNotes({
        variables: {
          orderId,
          notes,
        },
      })
      const updatedOrder = get(response, 'data.updateOrder')
      if (updatedOrder) {
        setStateData(updatedOrder)
      }
      setSuccess('Notes successfully updated')
    } catch (e: any) {
      setError(e.message)
    }
  }

  const handleGetShippingLabel = (consignmentId: string) => {
    setSelectedConsignment(consignmentId)
    getShippingLabel({
      variables: {
        orderId,
        consignmentId,
      },
    })
  }

  const canSaveOrder = () => {
    if (lineItemsPicked.length === 0) return false

    return (
      (lineItemsPicked.length >= 1 && lineItemsPicked.filter((li) => li.quantity === undefined).length === 0) ||
      lineItemsPicked.length < totalProductCount
    )
  }

  const canProcessOrder = () => {
    return (
      lineItemsPicked.filter((li) => li.quantity === undefined).length === 0 &&
      lineItemsPicked.length === totalProductCount
    )
  }

  const spinnerMessage =
    loadingCompleteOrder || loadingCancelOrder || loadingHoldAndSaveOrder ? 'Processing order' : 'Loading order'
  const totalProductCount =
    stateData && stateData.consignments
      ? stateData.consignments.reduce((count, row) => count + row.lineItems.length, 0)
      : 0

  const handleScan = async (barcode: string) => {
    if (barcode) {
      const response = await getProducts({ filter: { barcodes: [barcode] } })
      const product = response.data.products.edges.map((product: any) =>
        pick(product.node, ['title', 'image', 'promoTitle', 'externalId'])
      )[0]
      if (!product) {
        setProcessingIssues('Product not found from scan')
        return
      }
      let matchedLineItem: any
      let matchedConsignmentIndex = 0
      data.order.consignments.forEach((consignment: any, consignmentIndex: number) => {
        const lineItem = consignment.lineItems.find((lineItem: any) => lineItem.productId === product.externalId)
        if (lineItem) {
          matchedLineItem = lineItem
          matchedConsignmentIndex = consignmentIndex
          const quantitySelected =
            (pickedLineItemsRef.current.find((item: any) => item.id === matchedLineItem.id)?.quantity || 0) + 1
          if (quantitySelected <= matchedLineItem.quantityOrdered) {
            handlePickLineItem(lineItem.id, quantitySelected, matchedLineItem.quantityOrdered)
          } else {
            setProcessingIssues('Product total reached')
          }
        } else {
          setProcessingIssues('Product does not exist in the order')
        }
      })

      return {
        ...product,
        quantityOrdered: matchedLineItem?.quantityOrdered || 0,
        consignmentIndex: matchedConsignmentIndex,
        lineItemId: matchedLineItem?.id,
        matchedLineItem: !!matchedLineItem,
      }
    }
  }

  const handleStartCameraScan = async () => {
    if (!isDebugModeActive && savedStatus !== orderStatuses.IN_PICKING) {
      await handleStartPicking()
    }
    setShowBarcodeScanner(true)
  }

  const handleSaveAndStopPicking = async () => {
    if (lineItemsPicked.length > 0) {
        await handleSaveOrder(true)
      }
      setShowPickingIncomplete(true)
  }

  const handleCompletePicking = async () => {
    await handleSaveOrder(true)
    setShowPackingReady(true)
  }

  return (
    <React.Fragment>
      <Notification 
        showNotification={error !== null || processingIssues !== null || success !== null} 
        onCloseNotification={handleCloseNotification}
        status={error ? 'error' : success ? 'success' : 'warning'}
        text={error || processingIssues || success || ''}
      />

      {showBarcodeScanner && (
        <BarcodeScanner pickedLineItems={pickedLineItemsRef.current} onClose={() => setShowBarcodeScanner(false)} onScan={handleScan} onEditLineItem={handleEditLineItem} />
      )}

      {(loading && isEmpty(stateData)) ||
      (loadingSaveOrder && isEmpty(stateData)) ||
      loadingCompleteOrder ||
      loadingCancelOrder ||
      loadingHoldAndSaveOrder ||
      loadingShippingLabel ||
      loadingPickingOrder ||
      loadingReadyForPackingOrder ||
      loadingOrderNotes ? (
        <Spinner message={spinnerMessage} />
      ) : (
        stateData &&
        !isEmpty(stateData) && (
          <Fragment>
            {(loading || loadingSaveOrder) && (<Spinner message={spinnerMessage} />)}
            <Modal
              title={`Process Order #${stateData.orderNumber}`}
              onClose={() => {
                setShowProcessOrder(false)
              }}
              setOpen={setShowProcessOrder}
              open={showProcessOrder}
              >
              <OrderDetailsProcessDialog
                parcelValues={parcelValues}
                errors={parcelErrors}
                onConfirm={handleCompleteOrder}
                onSetParcelQuantity={handleSetParcelQuantity}
                onClose={handleCloseConfirmation}
              />
            </Modal>
            {updatingQuantity && (
              <Modal
                title="Manual Update"
                onClose={() => {
                  setUpdatingQuantity(null)
                  setShowManualUpdate(false)
                }}
                setOpen={setShowManualUpdate}
                open={showManualUpdate}
              >
                <OrderDetailsUpdate
                  lineItemId={updatingQuantity.lineItemId}
                  imageUrl={updatingQuantity.imageUrl}
                  title={updatingQuantity.title}
                  promotion={updatingQuantity.promotion}
                  quantityOrdered={updatingQuantity.quantityOrdered}
                  onConfirm={handlePickLineItem}
                />
              </Modal>
            )}
            <Modal
              title={`Cancel Order #${stateData.orderNumber}`}
              onClose={() => {
                setShowCancelConfirmation(false)
              }}
              setOpen={setShowCancelConfirmation}
              open={showCancelConfirmation}
            >
            <ConfirmationDialog
              onConfirm={actionCancelOrder}
              onCancel={() => setShowCancelConfirmation(false)}
              message="Please confirm that you would like to cancel this order"
            />
            </Modal>
            <Modal
              title={`Hold Order #${stateData.orderNumber}`}
              onClose={() => {
                setShowHoldConfirmation(false)
              }}
              setOpen={setShowHoldConfirmation}
              open={showHoldConfirmation}
            >
            <ConfirmationDialog
              onConfirm={actionHoldAndSaveOrder}
              onCancel={() => setShowHoldConfirmation(false)}
              message="Please confirm that you would like to hold this order"
            />
            </Modal>
            <Modal
              title={`Order # ${stateData.orderNumber}`}
              onClose={() => setShowOverview(false)}
              setOpen={setShowOverview}
              open={showOverview}
            >
              <Notification 
                showNotification={error !== null || processingIssues !== null || success !== null} 
                onCloseNotification={handleCloseNotification}
                status={error ? 'error' : success ? 'success' : 'warning'}
                text={error || processingIssues || success || ''}
              />
              <OrderDetailsOverview data={stateData} orderUserHistory={orderUserHistory?.orderUserHistory} onUpdateOrderNotes={handleUpdateOrderNotes} />
            </Modal>
            <Modal
              title="Picking Incomplete"
              maxWidth="xs"
              onClose={() => setShowPickingIncomplete(false)}
              setOpen={setShowPickingIncomplete}
              open={showPickingIncomplete}
            >
              <OrderDetailsActionChoice
                message="Please print the Print List and attached it to the basket for the next Picker. Thank you."
                buttonPrintConfig={{ label: 'Print Pick List', onClick: handlePrintPicklist }}
                buttonStatusChangeConfig={{ label: 'SAVE ORDER', onClick: () => handleSaveOrder(true), disabled: !canSaveOrder() }}
                onContinuePicking={() => setShowPickingIncomplete(false)}
              />
            </Modal>
            <Modal
              title="Ready for Packing?"
              maxWidth="xs"
              onClose={() => setShowPackingReady(false)}
              setOpen={setShowPackingReady}
              open={showPackingReady}
            >
              <OrderDetailsActionChoice
                message="Please print the Despatch Note and attach it on the basket for the Packer."
                buttonPrintConfig={{ label: 'Print Despatch Note', onClick: () => handlePrintDispatchNote() }}
                buttonStatusChangeConfig={{ label: 'READY FOR PACKING', onClick: handleReadyForPackingOrder }}
                onContinuePicking={() => setShowPackingReady(false)}
              />
            </Modal>
            <div className={classes.orderDetailsContainer}>
              <OrderDetailsHeader
                onHoldOrder={() => setShowHoldConfirmation(true)}
                onSaveOrder={() => handleSaveOrder(true)}
                onProcessOrder={handleProcessOrder}
                onCancelOrder={() => setShowCancelConfirmation(true)}
                onPrintPicklist={handlePrintPicklist}
                status={get(stateData, 'status')}
                orderNumber={get(stateData, 'orderNumber')}
                canSave={canSaveOrder()}
                canProcess={canProcessOrder()}
              />
              {!showBarcodeScanner && (
                <div className={classes.primaryButtonActions}>
                  <Button
                    title="Customer and order details"
                    type="white"
                    size="large"
                    contents={(<div className={classes.overviewButtonContents}><div><span className={classes.headerBtn}>Customer and order details</span><IconChat hasNotes={hasNotes} /></div><span className={classes.plus}>+</span></div> )}
                    onClick={() => setShowOverview(true)}
                  />
                  {(!completedStatus || isDebugModeActive) && (
                    <Button
                      title="START CAMERA SCAN"
                      type="tertiary"
                      size="large"
                      icon={(<IconCamera />)}
                      onClick={handleStartCameraScan}
                      className={classes.headerBtn}
                    />
                  )}
                </div>
              )}
              <OrderDetailsTable
                data={stateData}
                lineItemsPicked={lineItemsPicked}
                inPicking={savedStatus === orderStatuses.IN_PICKING || savedStatus === orderStatuses.PARTIALLY_PICKED}
                onEditLineItem={handleEditLineItem}
                onPrintDispatchNote={handlePrintDispatchNote}
                onGetShippingLabel={handleGetShippingLabel}
              />

              {(canShowActionBar) && (
                <OrderDetailsSummaryAction
                  assignedTo={pick(stateData.assignedTo, ['name', 'image'])}
                  status={savedStatus}
                  onPrimaryCta={
                    savedStatus === orderStatuses.OPEN || savedStatus === orderStatuses.ASSIGNED || savedStatus === orderStatuses.ON_HOLD
                      ? handleStartPicking
                      : handleSaveAndStopPicking
                  }
                  onSecondaryCta={handleCompletePicking}
                  price={stateData.grandTotal.amount}
                  currency={stateData.grandTotal.currency}
                  showIncompletePicking={!completePick}
                  showCompletePicking={canProcessOrder()}
                  partialCompletePicking={partialCompletePicking}
                />
              )}
            </div>
          </Fragment>
        )
      )}
    </React.Fragment>
  )
}

export default OrderDetails
