import React, { Fragment } from 'react'
import DashboardSummaryHeader from 'components/Dashboard/DashboardSummaryHeader/DashboardSummaryHeader'
import NumericIndicator from 'components/NumericIndicator/NumericIndicator'
import PercentageIndicator from 'components/PercentageIndicator/PercentageIndicator'
import IconOrders from 'components/Icons/IconOrders/IconOrders'
import OrderCountLozenge from 'components/Orders/OrderCountLozenge/OrderCountLozenge'
import { ODCDeliveryMethod, ODCOrderStatus } from '../../../helpers'
import clsx from 'clsx'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import useStyles from './DashboardSummary.styles'
import IconUser from 'components/Icons/IconUser/IconUser'

interface Props {
  ordersSummary: {
    Count: number
    onHoldOrderCount: number
    completedOrderCount: number
    inpickingOrderCount: number
    openOrderCount: number
    assignedOrderCount: number
    readyForPackingOrderCount: number
    partiallyPickedOrderCount: number
    nextDayOrderCount: number
    nextDayPre12OrderCount: number
    nominatedOrderCount: number
    nominatedPre12OrderCount: number
    standardOrderCount: number
    pre12OrderCount: number
    totalOrderCount: number
    cancelledOrderCount: number
    nominatedReadyForPackingOrderCount: number
    nominatedPre12ReadyForPackingOrderCount: number
    nextDayReadyForPackingOrderCount: number
    nextDayPre12ReadyForPackingOrderCount: number
    standardReadyForPackingOrderCount: number
  },
}

const DashboardSummary = (props: Props) => {
  const classes = useStyles()
  const inpickingPercentage = props.ordersSummary.totalOrderCount
    ? Math.ceil((props.ordersSummary.inpickingOrderCount * 100) / props.ordersSummary.totalOrderCount)
    : 0
  const readyForPackingPercentage = props.ordersSummary.totalOrderCount
    ? Math.ceil((props.ordersSummary.readyForPackingOrderCount * 100) / props.ordersSummary.totalOrderCount)
    : 0
  return (
    <Fragment>
      <div className={classes.dashboardSummary}>
        <Grid container>
          <div className={clsx(classes.fullWidth, classes.singlePanel)}>
            <DashboardSummaryHeader ordersSummary={props.ordersSummary} diameter={192} subtext="Processed" />
            <div className={classes.primarySection}>
              <div className={classes.sectionTop}>
                <NumericIndicator
                  font="heavy"
                  text="ORDERS PROCESSED"
                  link="/orders"
                  status={[ODCOrderStatus.COMPLETED.status, ODCOrderStatus.PARTIALLY_COMPLETED.status]}
                  value={`${props.ordersSummary.completedOrderCount} / ${props.ordersSummary.totalOrderCount}`}
                />
              </div>
              <div className={classes.sectionBottom}>
                <Table>
                  <TableHead />
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.COMPLETED.status]}
                          count={props.ordersSummary.completedOrderCount}
                          type="LINK"
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.OPEN.status]}
                          label="OPEN ORDERS"
                          count={props.ordersSummary.openOrderCount}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                          count={props.ordersSummary.assignedOrderCount}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.PARTIALLY_PICKED.status]}
                          label="PARTIAL"
                          count={props.ordersSummary.partiallyPickedOrderCount}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.ON_HOLD.status]}
                          count={props.ordersSummary.onHoldOrderCount}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.READY_FOR_PACKING.status]}
                          label="READY FOR PACKING"
                          count={props.ordersSummary.readyForPackingOrderCount}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          status={[ODCOrderStatus.CANCELLED.status]}
                          count={props.ordersSummary.cancelledOrderCount}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          <div className={classes.fullWidth}>
            <div className={classes.secondarySection}>
              <Grid container>
                <div className={classes.secondarySectionIcon}>
                  <PercentageIndicator
                    icon={IconUser}
                    diameter={70}
                    outerColor="#FF7600"
                    strokeWidth={12}
                    percentage={inpickingPercentage}
                  />
                </div>
                <div className={classes.sectionTop}>
                  <NumericIndicator
                    font="heavy"
                    text={ODCOrderStatus.ASSIGNED.label}
                    link="/orders"
                    status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                    value={props.ordersSummary.assignedOrderCount}
                  />
                </div>
              </Grid>
              <div className={classes.sectionBottom}>
                <Table>
                  <TableHead />
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NOMINATED_DAY, ODCDeliveryMethod.NOMINATED_DAY_PRE_12]}
                          breakWord={true}
                          count={props.ordersSummary.nominatedOrderCount + props.ordersSummary.nominatedPre12OrderCount}
                          status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NEXT_DAY, ODCDeliveryMethod.NEXT_DAY_PRE_12]}
                          count={props.ordersSummary.nextDayOrderCount + props.ordersSummary.nextDayPre12OrderCount}
                          status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NEXT_DAY_PRE_12]}
                          count={props.ordersSummary.nextDayPre12OrderCount}
                          status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.STANDARD]}
                          count={props.ordersSummary.standardOrderCount}
                          status={[ODCOrderStatus.ASSIGNED.status, ODCOrderStatus.IN_PICKING.status]}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className={classes.secondarySection}>
              <Grid container>
                <div className={classes.secondarySectionIcon}>
                  <PercentageIndicator
                    icon={IconOrders}
                    diameter={70}
                    outerColor="#FF7600"
                    strokeWidth={12}
                    percentage={readyForPackingPercentage}
                  />
                </div>
                <div className={classes.sectionTop}>
                  <NumericIndicator
                    font="heavy"
                    text={ODCOrderStatus.READY_FOR_PACKING.label}
                    link="/orders"
                    status={ODCOrderStatus.READY_FOR_PACKING.status}
                    value={props.ordersSummary.readyForPackingOrderCount}
                  />
                </div>
              </Grid>
              <div className={classes.sectionBottom}>
                <Table>
                  <TableHead />
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NOMINATED_DAY, ODCDeliveryMethod.NOMINATED_DAY_PRE_12]}
                          breakWord={true}
                          count={props.ordersSummary.nominatedReadyForPackingOrderCount + props.ordersSummary.nominatedPre12ReadyForPackingOrderCount}
                          status={[ODCOrderStatus.READY_FOR_PACKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NEXT_DAY, ODCDeliveryMethod.NEXT_DAY_PRE_12]}
                          count={props.ordersSummary.nextDayReadyForPackingOrderCount + props.ordersSummary.nextDayPre12ReadyForPackingOrderCount}
                          status={[ODCOrderStatus.READY_FOR_PACKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.NEXT_DAY_PRE_12]}
                          count={props.ordersSummary.nextDayPre12ReadyForPackingOrderCount}
                          status={[ODCOrderStatus.READY_FOR_PACKING.status]}
                        />
                      </TableCell>
                      <TableCell>
                        <OrderCountLozenge
                          deliveryMethods={[ODCDeliveryMethod.STANDARD]}
                          count={props.ordersSummary.standardReadyForPackingOrderCount}
                          status={[ODCOrderStatus.READY_FOR_PACKING.status]}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Fragment>
  )
}

export default DashboardSummary
