import { makeStyles } from '@material-ui/core/styles'
import { noImage, productImage } from '../../assets/jss/common'

const useStyles = makeStyles({
  small: {
    '& img': {
      height: '50px',
    },
  },
  noImage: {
    ...noImage,
    textAlign: 'center',
    fontWeight: 'bold',
    '& span': {
      opacity: '20%',
    },
  },
  productImage: {
    ...productImage,
    '&$small': {
      width: '50px',
      height: '50px',
      '& $noImage': {
        width: '50px',
        height: '50px',
        fontSize: '8px',
        lineHeight: '1rem',
      },
    },
  },
})

export default useStyles
