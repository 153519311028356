import React from 'react'
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core'
import IconCheckbox from '../Icons/IconCheckbox/IconCheckbox'
import IconEmptyCheckbox from '../Icons/IconEmptyCheckbox/IconEmptyCheckbox'


const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      {...props}
      icon={(<IconEmptyCheckbox />)}
      checkedIcon={(<IconCheckbox />)}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  )
}

export default Checkbox