import React from 'react'
import Logo from 'components/Logo/Logo'
import MainMenu from 'components/MainMenu/MainMenu'
import Drawer from '@material-ui/core/Drawer'
import { useReactiveVar } from '@apollo/client'
import { stateIsSidebarOpen } from '../../cache'
import { useStyles } from './SideBar.styles'

const SideBar = () => {
  const isSidebarOpen = useReactiveVar(stateIsSidebarOpen)
  const classes = useStyles()
  return (
      <Drawer variant="persistent" open={isSidebarOpen} anchor="left" className={classes.drawer} classes={{
        paper: classes.drawerPaper,
      }}>
        <Logo />
        <MainMenu />
      </Drawer>
  )
}

export default SideBar
