import React from 'react'
import BuildInfo from 'components/BuildInfo/BuildInfo'
import { useStyles } from './Footer.styles'

const Footer = () => {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <div className={classes.content}>
        <p className={classes.copyright}>Copyright © Gradient Edge {new Date().getFullYear()}</p>
        <div className={classes.buildInfo}>
          <BuildInfo />
        </div>
      </div>
    </div>
  )
}

export default Footer
