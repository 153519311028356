import React from 'react'
import useStyles from './IconChat.styles'
import clsx from 'clsx'

const IconChat = (props) => {
  const classes = useStyles()
  const fillColor = props.fillColor || '#fff'
  const width = props.width ? props.width + 'px' : '15px'
  const height = props.height ? props.height + 'px' : '14px'

  return (
    <div className={clsx(classes.iconChat, props.hasNotes && classes.iconChatActive)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
        <g transform="translate(.5 .499)">
          <path
            d="M13.392 13.427s1.836-8.859-.881-11.484S4.523-.489 1.857 1.582s-2.281 7.133 0 9.293 9.355.7 9.355.7z"
            fill="none"
            stroke={fillColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            data-name="Ellipse 695"
            cx={0.826}
            cy={0.821}
            rx={0.826}
            ry={0.821}
            transform="translate(3.211 5.264)"
            fill={fillColor}
          />
          <ellipse
            data-name="Ellipse 696"
            cx={0.826}
            cy={0.821}
            rx={0.826}
            ry={0.821}
            transform="translate(5.965 5.264)"
            fill={fillColor}
          />
          <ellipse
            data-name="Ellipse 697"
            cx={0.826}
            cy={0.821}
            rx={0.826}
            ry={0.821}
            transform="translate(8.718 5.264)"
            fill={fillColor}
          />
        </g>
      </svg>
    </div>
  )
}

export default IconChat
