import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../assets/jss/variables'
import { errorMessage } from './common'

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    marginBottom: spacing.large,
    alignItems: 'center',
    gap: spacing.large,
  },
  content: {
    '& p': {
      marginBottom: spacing.xl,
    },
    '& button': {
      marginBottom: spacing.medium,
    },
  },
  errorMessage,
})

export default useStyles
