import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../assets/jss/variables'

const useStyles = makeStyles({
  iconChat: {
    borderRadius: '100%',
    backgroundColor: colors.blue,
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconChatActive: {
    backgroundColor: colors.orange,
  },
})

export default useStyles
