import React from 'react'

interface IconProps {
  fillColor?: string
  width?: string
  height?: string
}

const IconOrders = (props: IconProps) => {
  const fillColor = props.fillColor || '#636c78'
  const width = (props.width || 20) + 'px'
  const height = (props.height || 22) + 'px'

  return (
    <svg width={width} height={height} viewBox="0 0 20.258 22.11">
      <defs>
        <style>
          {`.prefix__cls-iconOrders{fill:none;stroke:${fillColor};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}`}
        </style>
      </defs>
      <g id="prefix__ICON_MyOrders" transform="translate(1 1)">
        <path
          id="prefix__Path_71085"
          data-name="Path 71085"
          className="prefix__cls-iconOrders"
          d="M7.543 3L4.5 7.022V21.1a2.02 2.02 0 002.029 2.01h14.2a2.02 2.02 0 002.029-2.01V7.022L19.715 3z"
          transform="translate(-4.5 -3)"
        />
        <path
          id="prefix__Path_71086"
          data-name="Path 71086"
          className="prefix__cls-iconOrders"
          d="M4.5 9h18.258"
          transform="translate(-4.5 -4.978)"
        />
        <path
          id="prefix__Path_71087"
          data-name="Path 71087"
          className="prefix__cls-iconOrders"
          d="M20.115 15A4.058 4.058 0 0112 15"
          transform="translate(-6.928 -6.956)"
        />
      </g>
    </svg>
  )
}

export default IconOrders
