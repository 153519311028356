import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontSizes } from '../../../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {
      marginBottom: '50px',
      '@media only screen and (max-width: 1420px)': {
        display: 'block',
      },
      '& .MuiFormControl-root': {
        margin: '8px 0px 8px 0px',
        paddingRight: '40px',
        width: '33%',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    fieldContainer: {
      width: '100%',
      maxWidth: '910px',
      flexShrink: 0,
      marginBottom: '25px',
      '& .MuiInputLabel-animated': {
        fontSize: fontSizes.label,
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.hybrid,
        },
      },
      '& .MuiInputBase-input': {
        fontSize: fontSizes.subtitle,
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.small,
        },
      },
    },
    buttonContainer: {
      flexShrink: 0,
      textAlign: 'right',
      maxWidth: '910px',
      marginBottom: '6px',
      paddingRight: '6px',
    },
    datePicker: {
      '& .MuiIconButton-root': {
        right: '-15px',
      },
    },
    searchForm: {
      marginBottom: '20px',
    },
    listDisplay: {
      textTransform: 'capitalize',
    },
  })
)

export default useStyles
