import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  iconBase: {},
  iconOpen: {},
  iconClosed: {},
  iconActive: {
    display: 'none',
    top: 0,
    left: 0,
  },
  mainMenuToggle: {
    position: 'relative',
    display: 'block',
    width: '42px',
    height: '42px',
    padding: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      '& $iconBase': {
        display: 'none',
      },
    },
  },
  toggleOpen: {
    '&:hover': {
      '& $iconOpen': {
        display: 'block',
      },
    },
  },
  toggleClosed: {
    '&:hover': {
      '& $iconClosed': {
        display: 'block',
      },
    },
  },
})

export default useStyles
