import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  '@-webkit-keyframes load8': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes load8': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    margin: 0,
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: '10px solid rgba(0, 0, 0, 0.2)',
    borderRight: '10px solid rgba(0, 0, 0, 0.2)',
    borderBottom: '10px solid rgba(0, 0, 0, 0.2)',
    borderLeft: '10px solid #000',
    transform: 'translateZ(0)',
    animation: '$load8 1.1s infinite linear',
    borderRadius: '50%',
    width: 100,
    height: 100,
  },
}))

export default styles
