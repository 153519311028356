import { makeStyles } from '@material-ui/core/styles'
import { fontSizes } from '../../assets/jss/variables'

const useStyles = makeStyles((theme) => ({
  barcodeScanner: {
    position: 'sticky',
    top: 0,
    zIndex: 50,
    height: '293px',
    marginBottom: '20px',
    '& .scandit.scandit-barcode-picker': {
      backgroundColor: 'transparent',
    },
    '& .scandit-video': {
      borderRadius: '22px',
    },
    fontSize: fontSizes.subtitle,
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: 10,
  },
  productDetail: {
    position: 'absolute',
    bottom: 0,
    height: '70px',
    width: '100%',
    borderRadius: '0 0 22px 22px',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    '&:after': {
      content: "''",
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      bottom: 0,
      height: '70px',
      width: '100%',
      borderRadius: '0 0 22px 22px',
    },
    '& > div': {
      padding: '10px 25px',
    },
    '& > *': {
      zIndex: 5,
    },
  },
  productInfo: {
    minWidth: '240px',
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    fontWeight: 'bold',
    paddingRight: '12px',
  },
  quantityButton: {
    marginRight: '20px',
  },
  scanMessageHelper: {
    display: 'flex',
    textAlign: 'right',
    marginRight: '20px',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: fontSizes.small,
  },
}))

export default useStyles
