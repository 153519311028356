import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'assets/jss/theme'
import { colors, fontSizes, fontWeights, statusColors } from '../../../assets/jss/variables'

const useStyles = makeStyles({
  orderDetailsOverview: {
    fontSize: fontSizes.subtitle,
    [theme.breakpoints.up('md')]: {
      fontSize: fontSizes.hybrid,
    },
  },
  orderStatus: {
    maxWidth: '135px',
  },
  address: {
    '& > li': {
      marginBottom: '0',
    },
  },
  section: {
    display: 'flex',
    '&:first-child': {
      marginRight: '20px',
      [theme.breakpoints.up('md')]: {
        marginRight: '36px',
      },
    },
    '& span': {
      display: 'block',
      fontWeight: fontWeights.bold,
    },
    '& div': {
      display: 'flex',
      gap: '10px',
    },
  },
  column: {
    marginRight: '20px',
    minWidth: '110px',
    '& > li': {
      marginBottom: '30px',
      wordBreak: 'break-word',
      '&:last-child': {
        marginBottom: '0',
      },
    },
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '30px',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  columns: {
    display: 'flex',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
    },
  },
  historyNotesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  orderNotes: {
    width: '48%',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
      width: 'inherit',
    },
  },
  pickerHistory: {
    marginBottom: '30px',
    marginRight: '5%',
    maxHeight: '120px',
    maxWidth: '220px',
    overflow: 'auto',
    width: '45%',
    '& > span': {
      fontWeight: fontWeights.bold,
      display: 'block',
      marginBottom: '10px',
    },
    '& li': {
      display: 'grid',
      columnGap: '5px',
      gridTemplateColumns: '100px 80px 1fr',
      marginBottom: '15px',
      '& > span': {
        marginRight: '10px',
      },
      [theme.breakpoints.up('md')]: {
        columnGap: '20px',
        gridTemplateColumns: '100px 150px 1fr',
      },
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      maxWidth: 'none',
      width: 'inherit',
    },
  },
  recordUserName: {
    display: 'block',
  },
  smallTabletOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mediumTabletUp: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  recordStatus: {
    '&[data-record="open"]': {
      '& > div': {
        backgroundColor: statusColors.open,
        color: colors.black,
      },
    },
    '&[data-record="in-picking"]': {
      '& > div': {
        backgroundColor: statusColors.inPicking,
      },
    },
    '&[data-record="ready-for-packing"]': {
      '& > div': {
        backgroundColor: statusColors.readyForPacking,
      },
    },
    '&[data-record="partially-picked"]': {
      '& > div': {
        backgroundColor: statusColors.partiallyPicked,
      },
    },
    '&[data-record="completed"]': {
      '& > div': {
        backgroundColor: statusColors.completed,
      },
    },
    '&[data-record="assigned"]': {
      '& > div': {
        backgroundColor: statusColors.assigned,
      },
    },
    '&[data-record="partially-completed"]': {
      '& > div': {
        backgroundColor: statusColors.partiallyCompleted,
      },
    },
    '&[data-record="cancelled"]': {
      '& > div': {
        backgroundColor: statusColors.cancelled,
      },
    },
    '&[data-record="on-hold"]': {
      '& > div': {
        backgroundColor: statusColors.onHold,
      },
    },
  },
})

export default useStyles
