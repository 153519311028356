import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../assets/jss/variables'

const useStyles = makeStyles({
  mainMenuItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 69,
    paddingRight: 15,
    minHeight: 60,
    overflow: 'hidden',
    fontSize: 14,
    color: colors.grey.grey2,
    textDecoration: 'none',
  },
  itemSelected: {
    color: colors.grey.grey3,
    '&:before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      height: 20,
      width: 4,
      top: '50%',
      left: 0,
      marginTop: '-10px',
      background: colors.orange,
    },
  },
  itemText: {
    opacity: 1,
    transition: 'opacity 100ms linear 0ms',
  },
  itemContracted: {
    '& $itemText': {
      opacity: 0,
    },
  },
  itemIcon: {
    position: 'absolute',
    display: 'block',
    left: 32,
  },
})

export default useStyles
