import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../assets/jss/variables'

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: colors.green,
  },
}))

export default useStyles
