import React from 'react'

const IconEmptyCheckbox = (props) => {
  const fillColor = props.fillColor || '#b6bfc2'
  const width = props.width ? props.width + 'px' : '30px'
  const height = props.height ? props.height + 'px' : '30px'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g fill="rgba(255,255,255,0.4)" stroke={fillColor} strokeMiterlimit={10}>
        <rect width={30} height={30} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={29} height={29} rx={3.5} fill="none" />
      </g>
    </svg>
  )
}

export default IconEmptyCheckbox
