import { makeStyles, Theme } from '@material-ui/core/styles'
import { colors, fontSizes, statusColors } from '../../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: '20px',
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      marginBottom: '30px',
    },
    '& h1': {
      fontSize: fontSizes.hybrid,
      marginRight: '10px',
      marginBottom: 0,
      [theme.breakpoints.up('lg')]: {
        fontSize: fontSizes.medium,
        marginBottom: '0',
      },
    },
    '& strong': {
      paddingLeft: '5px',
    },
  },
  buttonContainer: {
    display: 'flex',
    '& button': {
      marginRight: '10px',
      '&:last-child': {
        marginRight: '0',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '20px',
      },
    },
    '& $headerBtn': {
      fontSize: fontSizes.subtitle,
      [theme.breakpoints.up('md')]: {
        fontSize: fontSizes.hybrid,
      },
    },
  },
  details: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
    '& span': {
      fontSize: fontSizes.hybrid,
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        display: 'flex',
        fontSize: fontSizes.medium,
      },
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  headerBtn: {
    padding: '10px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '10px 30px',
    },
  },
  savedStatus: {
    display: 'inline-block',
    marginLeft: '5px',
    '& > div > span': {
      fontSize: fontSizes.hybrid,
    },
    '&[data-record="open"]': {
      '& > div': {
        backgroundColor: statusColors.open,
        '& > span': {
          color: colors.black,
        },
      },
    },
    '&[data-record="in-picking"]': {
      '& > div': {
        backgroundColor: statusColors.inPicking,
      },
    },
    '&[data-record="ready-for-packing"]': {
      '& > div': {
        backgroundColor: statusColors.readyForPacking,
      },
    },
    '&[data-record="partially-picked"]': {
      '& > div': {
        backgroundColor: statusColors.partiallyPicked,
      },
    },
    '&[data-record="completed"]': {
      '& > div': {
        backgroundColor: statusColors.completed,
      },
    },
    '&[data-record="assigned"]': {
      '& > div': {
        backgroundColor: statusColors.assigned,
      },
    },
    '&[data-record="partially-completed"]': {
      '& > div': {
        backgroundColor: statusColors.partiallyCompleted,
      },
    },
    '&[data-record="cancelled"]': {
      '& > div': {
        backgroundColor: statusColors.cancelled,
      },
    },
    '&[data-record="on-hold"]': {
      '& > div': {
        backgroundColor: statusColors.onHold,
      },
    },
  },
}))

export default useStyles
