import React from 'react'

const IconUser = (props) => {
  const fillColor = props.fillColor || '#35526d'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.778" height="31">
      <g fill="none" stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path
          data-name="Path 69196"
          d="M27.778 29.999v-3.391a6.74 6.74 0 0 0-6.695-6.784H7.694A6.74 6.74 0 0 0 1 26.608V30"
        />
        <path data-name="Path 69197" d="M21.085 7.784A6.7 6.7 0 1 1 14.39 1a6.739 6.739 0 0 1 6.695 6.784Z" />
      </g>
    </svg>
  )
}

export default IconUser
