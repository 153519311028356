import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../assets/jss/variables'

const useStyles = makeStyles(() => ({
  dateRangePicker: {
    position: 'absolute',
    backgroundColor: colors.white,
    width: '700px!important',
    zIndex: 200,
  },
  endAdornment: {
    marginRight: '20px',
  },
}))

export default useStyles
