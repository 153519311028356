import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles';
import Footer from 'components/Footer/Footer'
import clsx from 'clsx'
import bgImage from '../../assets/images/bg-body.jpg'
import { stateIsDebugModeActive, stateIsSidebarOpen, stateUser } from '../../cache'
import { GET_USER_BY_EXTERNAL_ID } from '../../graphQL/queries'
import { useStyles } from './Layout.styles'
import SideBar from '../SideBar/SideBar'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useReactiveVar, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import Notification from '../Notification/Notification'

interface Props {
  showHeader: boolean
  showFooter: boolean
  header?: JSX.Element
  mainContent: JSX.Element
}

const Layout = ({ showHeader, showFooter, header, mainContent }: Props) => {
  const isSidebarOpen = useReactiveVar(stateIsSidebarOpen)
  const classes = useStyles()
  const theme = useTheme();
  const matchesLargeBkp = useMediaQuery(theme.breakpoints.up('lg'));
  const hasRendered = useRef(false)
  const { user } = useAuth0()
  const location = useLocation()
  const userId = user ? user.sub : undefined
  const [debugModeConfirmation, setDebugModeConfirmation] = useState('')

  useQuery(GET_USER_BY_EXTERNAL_ID, {
    variables: { id: userId },
    onCompleted: (data) => stateUser(data.userByExtId),
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.get('debug') === 'true') {
      localStorage.setItem('isDebugModeActive', 'true')
      stateIsDebugModeActive(true)
      setDebugModeConfirmation('Debug mode is now ENABLED')
    } else if ((urlParams.get('debug') === 'false')) {
      localStorage.removeItem('isDebugModeActive')
      stateIsDebugModeActive(false)
      setDebugModeConfirmation('Debug mode has been DISABLED')
    }
  }, [location.search])

  useEffect(() => {
    if (matchesLargeBkp && !hasRendered.current && userId) {
      stateIsSidebarOpen(true)
      hasRendered.current = true
    }
  }, [matchesLargeBkp, userId])

  const handleCloseNotification = () => {
    setDebugModeConfirmation('')
  }

  return (
    <>
    <Notification 
    showNotification={debugModeConfirmation !== ''} 
    onCloseNotification={handleCloseNotification}
    status="success"
    text={debugModeConfirmation}
    />
    <div className={clsx(classes.layout, isSidebarOpen && classes.layoutSidebarOpen)}>
      <div className={classes.layoutContainer}>
        <SideBar />
        <div className={classes.layoutMain}>
          {showHeader && <div>{header}</div>}

          <div className={classes.layoutContent}>
            <div className={classes.layoutContentMain}>{mainContent}</div>
            {showFooter && (
              <div className={classes.layoutContentFooter}>
                <Footer />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.layoutBackground}>
        <img className={classes.layoutBackgroundImage} src={bgImage} alt="" />
      </div>
    </div>
    </>
  )
}

export default Layout
