import React from 'react'

const IconEdit = (props) => {
  const fillColor = props.fillColor || '#00CDFF'
  const width = (props.width || 16) + 'px'
  const height = (props.height || 16) + 'px'
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={fillColor}
          d="M15.5562505,9.39786809 C15.8013527,9.39786809 16,9.59649884 16,9.84158072 L16,13.7814369 C15.9986133,15.0061531 15.0060704,15.9986134 13.7812524,16 L2.21874763,16 C0.993929627,15.9986134 0.00138671727,15.0061531 1.687539e-13,13.7814369 L1.687539e-13,2.21856313 C0.00138671727,0.993846932 0.993929627,0.00138660196 2.21874763,2.22044605e-14 L6.15875806,2.22044605e-14 C6.40386036,2.22044605e-14 6.60250759,0.198804067 6.60250759,0.443712627 C6.60250759,0.688794501 6.40386036,0.887425253 6.15875806,0.887425253 L2.21874763,0.887425253 C1.48378748,0.888291869 0.888365741,1.48383741 0.887499054,2.21856313 L0.887499054,13.7812636 C0.888365741,14.5161626 1.48378748,15.1117081 2.21874763,15.1124014 L13.7812524,15.1124014 C14.5162125,15.1117081 15.1116342,14.5161626 15.1125009,13.7812636 L15.1125009,9.84158072 C15.1125009,9.59649884 15.3111482,9.39786809 15.5562505,9.39786809 Z M14.8889723,0.584842682 L15.4096846,1.10551172 C16.1883263,1.88582199 16.1883263,3.14901638 15.4096846,3.92932661 L7.49308906,11.8456105 C7.43883372,11.8998613 7.37157793,11.9390328 7.29773526,11.9594852 L3.53921142,13.0006499 C3.38511248,13.043288 3.22009312,12.9997833 3.10690233,12.8867753 C2.99388483,12.7735939 2.95037661,12.6085883 2.99319152,12.4545021 L4.03426949,8.69629083 C4.05472354,8.62245429 4.09389833,8.55520405 4.14815363,8.50095326 L12.0649225,0.584842682 C12.8447777,-0.194947561 14.1091172,-0.194947561 14.8889723,0.584842682 Z M5.11504227,8.78936647 L7.2044785,10.8788023 L13.6839149,4.39990466 L11.5943054,2.31046882 L5.11504227,8.78936647 Z M4.69764037,9.62687405 L4.05784368,11.9360863 L6.36707464,11.2963428 L4.69764037,9.62687405 Z M14.782195,3.30188922 C15.2146775,2.86840279 15.2146775,2.16660889 14.782195,1.7329491 L14.2613094,1.21228007 C13.8281335,0.779140271 13.1257612,0.779140271 12.6925854,1.21228007 L12.221795,1.68285812 L14.3115779,3.77246727 L14.782195,3.30188922 Z"
        />
      </g>
    </svg>
  )
}

export default IconEdit
