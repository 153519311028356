import React from 'react'
import IconProgress from 'components/Icons/IconProgress/IconProgress'
import useStyles from './PercentageIndicator.styles'

interface Props {
  diameter: number
  outerColor: string
  strokeWidth?: number
  percentage: number
  mainText?: string
  subText?: string
  icon?: React.ElementType
}

const PercentageIndicator = (props: Props) => {
  const classes = useStyles()
  const diameter = props.diameter || 100
  const IconComponent = props.icon as React.ElementType

  return (
    <div className={classes.percentageIndicator} style={{ width: diameter, height: diameter }}>
      <span className={classes.icon}>
        <IconProgress
          outerColor={props.outerColor}
          strokeWidth={props.strokeWidth}
          diameter={diameter}
          percentage={props.percentage}
        />
      </span>
      <ul>
        <li>
          {props.icon ? <IconComponent fillColor="" /> : <span className={classes.mainText}>{props.mainText}</span>}
        </li>
        <li>
          <span className={classes.subText}>{props.subText}</span>
        </li>
      </ul>
    </div>
  )
}

export default PercentageIndicator
