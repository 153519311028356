import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import useStyles from './NumberChanger.styles'

interface Props {
  initialValue: number
  minQuantity?: number
  maxQuantity?: number
  ariaLabel?: string
  onChange: (value: number) => void
}

const NumberChanger = ({ initialValue, minQuantity, maxQuantity, ariaLabel, onChange }: Props) => {
  const [stateValue, setStateValue] = useState(initialValue)
  const classes = useStyles()

  const handleIncrement = () => {
    setStateValue((maxQuantity !== undefined && stateValue < maxQuantity) || maxQuantity === undefined ? stateValue + 1 : stateValue)
  }

  const handleDecrement = () => {
    setStateValue(stateValue > (minQuantity !== undefined ? minQuantity : 0) ? stateValue-1 : stateValue)
  }

  const handleTextFieldChange = (evt:any) => {
    const { value } = evt.target
    if (!isNaN(value) && !(maxQuantity !== undefined && value >= maxQuantity)) {
      setStateValue(value)
    }
  }

  useEffect(() => {
    onChange(stateValue)
    // eslint-disable-next-line
  }, [stateValue])

  return (
    <ButtonGroup size="small" aria-label={ariaLabel || ''} className={classes.numberChanger}>
      <Button className={classes.button} onClick={handleDecrement}>-</Button>
      <input type="text" className={classes.input} value={stateValue} onChange={handleTextFieldChange} />
      <Button className={classes.button} onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  )
}

export default NumberChanger