import { InMemoryCache, makeVar } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { ODCUserRoles } from './helpers'

type UserRoles = ODCUserRoles.MANAGER | ODCUserRoles.PICKER | ODCUserRoles.PACKER

interface UserDetails {
  id: string
  email: string
  emailVerified: string
  externalId: string
  name: string
  nickName: string
  image: string
  roles: UserRoles[]
}

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orders: relayStylePagination(['sort', 'filter']),
      },
    },
  },
})

export const stateIsDebugModeActive = makeVar<boolean>(localStorage.getItem('isDebugModeActive') === 'true')
export const stateIsSidebarOpen = makeVar<boolean>(false)
export const stateIsActionbarOpen = makeVar<boolean>(false)
export const stateSelectedOrderIds = makeVar<string[]>([])
export const stateUser = makeVar<UserDetails | null>(null)
