import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { buttonPrintContainer } from '../../assets/jss/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPrintContainer,
    pickListContainer: {
      margin: '0 20px',
      [theme.breakpoints.up('lg')]: {
        margin: '0 150px',
      },
      '@media print': {
        margin: '0',
        footer: {
          pageBreakAfter: 'always',
        },
      },
    },
    orderDetailButton: {
      margin: '20px 0 -10px 0',
      '@media print': {
        display: 'none',
      },
    },
  })
)

export default useStyles
