import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    position: 'relative',
    display: 'block',
    height: '18px',
    margin: '26px 0 35px 30px',
    width: '32px',
    [theme.breakpoints.up('md')]: {
      width: '180px',
    },
  },
  logoLink: {
    display: 'block',
  },
  logoImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 300ms ease',
  },
  logoImageSmall: {
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      opacity: 0,
    },
  },
  logoImageLarge: {
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      opacity: 1,
    },
  },
  closeSidebar: {
    display: 'block',
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
}))

export default useStyles
