import React, { Fragment } from 'react'
import useStyles from './DispatchNotesTable.style'
import { formatDate } from '../../../helpers'
import { AddressProps, OrderProps } from '../../../types'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import logoStacked from '../../../assets/images/logo-stacked.svg'
import clsx from 'clsx'

interface DataProps {
  data: OrderProps
  consignmentId?: string
}

const DispatchNoteTable = (props: DataProps) => {
  const { data, consignmentId } = props
  const classes = useStyles()

  const formatAddress = (address: AddressProps) => {
    type fieldKeys = keyof AddressProps
    if (!address) return null
    const addressFields: fieldKeys[] = [
      'name',
      'address1',
      'address2',
      'city',
      'region',
      'postcode',
      'county',
      'phone',
    ]
    return (
      <ul>
        {addressFields.map((field, index) => {
          if (!address[field]) {
            return null
          }
          return (
          <li
            key={field}
            className={clsx({
              [classes.firstAddressLine]: index === 0,
              [classes.lastAddressLine]: index === addressFields.length - 1,
            })}
          >
            {address[field]}
          </li>
        )})}
      </ul>
    )
  }

  const consignments = data.consignments.filter(
    (consignment) => (consignmentId && consignment.id.toString() === consignmentId) || true
  )!

  return (
    <Fragment>
      { consignments.map((consignment) => (
        <>
      <div className={classes.dispatchNoteContainer}>
        <div className={classes.heading}>
          <div>
            <img className={classes.logo} src={logoStacked} alt="Planet Organic Logo" />
            <h1>Dispatch Note</h1>
          </div>
        </div>
        <div className={classes.detailsContainer}>
          <ul className={classes.orderDetails}>
            <li>
              Order#<span>{data.orderNumber}</span>
            </li>
            <li>
              Date Placed
              <span>{formatDate(data.placedAt)}</span>
            </li>
            <li>
              Dispatch Date
              <span>{formatDate(new Date().toISOString())}</span>
            </li>
            <li>
              Delivery Method<span>{data.deliveryMethod}</span>
            </li>
          </ul>
          <div className={classes.orderCards}>
            <div className={classes.card}>
              <h2>Delivery Address</h2>
              {formatAddress(data.deliveryAddress)}
            </div>

            <div className={classes.card}>
              <h2>Invoice Address</h2>
              {formatAddress(data.billingAddress)}
            </div>
          </div>
          <div className={classes.tableContainer}>
            <Table aria-label="Dispatch Note">
              <TableHead>
                <TableRow>
                  <TableCell>Item #</TableCell>
                  <TableCell>Product Description</TableCell>
                  <TableCell>Ordered</TableCell>
                  <TableCell>Delivered</TableCell>
                  <TableCell>Refunded</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consignment.lineItems.map((lineItem: any, index: number) => (
                  <TableRow
                    key={index}
                    className={clsx({
                      [classes.evenRow]: index % 2 === 0,
                    })}
                  >
                    <TableCell>{lineItem.lineCode}</TableCell>
                    <TableCell>{lineItem.title}</TableCell>
                    <TableCell>{lineItem.quantityOrdered}</TableCell>
                    <TableCell>{lineItem.quantityPicked}</TableCell>
                    <TableCell>{lineItem.quantityRefunded}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <p className={classes.disclaimer}>
            All of our packaging is recyclable, home compostable or biodegradable. Please visit
            www.planetorganic.com/pages/our-online-order-packaging for more information
          </p>
        </div>
      </div>
      <footer className={classes.footer}>
        <p>Planet Organic Ltd, 42 Westbourne Grove, London, W2 5SH</p>
        <div className={classes.contactDetails}>
          <span>Email. talktous@planetorganic.com</span> <span>Website. www.planetorganic.com</span>
        </div>
      </footer>
      </>
      ))}
    </Fragment>
  )
}

export default DispatchNoteTable
