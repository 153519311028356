import React, { Fragment, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ORDER } from '../../graphQL/queries'
import { useHistory, useParams } from 'react-router-dom'
import { get, isUndefined } from 'lodash'
import Spinner from 'components/Spinner/Spinner'
import useStyles from './DispatchNote.style'
import DispatchNoteTable from './DispatchNoteTable/DispatchNoteTable'
import Notification from 'components/Notification/Notification'
import Button from 'components/Button/Button'

interface ErrorProps {
  reason: string
  showError: boolean
}

const DispatchNote = () => {
  const history = useHistory()
  const classes = useStyles()
  const { orderId, consignmentId } = useParams<{ orderId: string, consignmentId: string  }>()

  if (isUndefined(orderId)) {
    history.push('/orders')
  }

  const [responseError, setResponseError] = React.useState<ErrorProps>({
    reason: '',
    showError: false,
  })

  const { loading, error, data } = useQuery(GET_ORDER, {
    fetchPolicy: 'network-only',
    variables: { id: orderId },
  })

  useEffect(() => {
    if (error) {
      setResponseError({
        reason: error.toString(),
        showError: true,
      })
    }
    // eslint-disable-next-line
  }, [error])

  const handleCloseNotification = () => {
    setResponseError({ reason: '', showError: false })
  }

  const RenderDispatchNoteTable = () => {
    const tableData = get(data, 'order')
    return tableData ? <DispatchNoteTable data={tableData} consignmentId={consignmentId || undefined} /> : null
  }

  return (
    <Fragment>
      <Notification
        showNotification={responseError.showError}
        status="error"
        onCloseNotification={handleCloseNotification}
        text={responseError.reason}
      />
      {loading ? (
        <Spinner message="Loading order" />
      ) : (
        <Fragment>
          <div className={classes.buttonPrintContainer}>
            <Button
              onClick={() => history.push(`/orders/order-details/${orderId}`)}
              title="Order details"
            />
            <Button onClick={() => history.push('/orders')} title="Order listing" />
            <Button onClick={() => window.print()} title="Print" />
          </div>
          <RenderDispatchNoteTable />
        </Fragment>
      )}
    </Fragment>
  )
}

export default DispatchNote
