import React, { useState } from 'react'
import Button from 'components/Button/Button'
import ProductInfo from '../../ProductInfo/ProductInfo'
import useStyles from './OrderDetailsUpdate.styles'
import { isNumber } from 'lodash'
import clsx from 'clsx'
import NumberChanger from '../../NumberChanger/NumberChanger'

interface Props {
  lineItemId: string
  imageUrl: string
  title: string
  promotion: string
  quantityOrdered: number
  validateLineItem?: boolean
  onConfirm: (lineItemId: string, quantity: number, quantityOrdered: number) => void
}

const OrderDetailsUpdate = (props: Props) => {
  const { lineItemId, imageUrl, title, promotion, quantityOrdered, onConfirm } = props
  const [newQuantity, setNewQuantity] = useState<number>(quantityOrdered)

  const handleConfirm = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    onConfirm(lineItemId, isNumber(newQuantity) ? newQuantity : 0, quantityOrdered)
  }

  const handleSetNewQuantity = (quantity: number) => {
    setNewQuantity(quantity)
  }

  const classes = useStyles()

  return (
      <form onSubmit={(e) => handleConfirm(e)}>
        <div className={classes.formContent}>
          <div className={classes.productDetail}>
            <div className={classes.productInfoContainer}>
              <ProductInfo imageUrl={imageUrl} title={title} promotion={promotion} />
            </div>
            <Button type={newQuantity === quantityOrdered ? 'tertiary' : newQuantity > 0 ? 'orange' : 'grey'} title={`${newQuantity}/${quantityOrdered}`} className={classes.quantityButton} />
          </div>

            <div className={classes.quantityRow}>
              <div className={clsx(classes.row, classes.quantityDetails)}>
              <span>Quantity Ordered</span>
              <div className={classes.quantityOrdered}>{quantityOrdered}</div>
              </div>
            </div>
            <div className={classes.row}>
            <span>Units you've picked</span>
            <NumberChanger initialValue={quantityOrdered} maxQuantity={quantityOrdered} onChange={handleSetNewQuantity}  />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button title="UPDATE PICK" isSubmit={true} />
          </div>
      </form>
  )
}

export default OrderDetailsUpdate
