import { makeStyles } from '@material-ui/core/styles'
import { fontSizes, fontWeights } from '../../assets/jss/variables'

export const useStyles = makeStyles(() => ({
  buildInfo: {
    fontSize: fontSizes.subtitle,
    fontWeight: fontWeights.light,
    lineHeight: '1rem',
  },
  accessCode: {
    cursor: 'pointer',
  },
}))
