import React from 'react'

const IconCalendar = (props) => {
  const fillColor = props.fillColor || '#b6bfc2'
  const width = props.width ? props.width + 'px' : '17px'
  const height = props.height ? props.height + 'px' : '16px'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        d="M4.973 5.972h1.99v1.986h-1.99zm2.984 0h1.989v1.986H7.957zm2.984 0h1.988v1.986h-1.988zm-8.952 5.964h1.989v1.989H1.989zm2.984 0h1.99v1.989h-1.99zm2.984 0h1.989v1.989H7.957zM4.973 8.952h1.99v1.99h-1.99zm2.984 0h1.989v1.99H7.957zm2.984 0h1.988v1.99h-1.988zm-8.952 0h1.989v1.99H1.989zM12.929 0v.995h-1.988V0H3.978v.995H1.989V0H0v15.915h14.92V0h-1.991zm.995 14.92H.994V3.979h12.935z"
        fill={fillColor}
      />
    </svg>
  )
}

export default IconCalendar
