import React from 'react'
import { Redirect } from 'react-router-dom'
import Button from 'components/Button/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { ReactComponent as Logo } from '../../assets/images/logo-stacked.svg'
import useStyles from './Splash.styles'

const Splash = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const classes = useStyles()
  if (isAuthenticated) {
    return <Redirect to="/orders" />
  }
  if (window.location.pathname === '/login') {
    loginWithRedirect()
  }

  const login = () => {
    if (window.self !== window.top) {
      window.open('/login', '_blank')
    } else {
      loginWithRedirect()
    }
  }

  return (
    <div className={classes.splash}>
      <span className={classes.logo}>
        <Logo />
      </span>
      <h1 className={classes.title}>Order Data Centre</h1>
      <div className={classes.buttonContainer}><Button fullWidth={true} onClick={() => login()} title="Log in" /></div>
    </div>
  )
}

export default Splash
