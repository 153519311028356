import React from 'react'
import { formatPrice } from '../../../helpers'
import useStyles from './OrderDetailsSummaryAction.styles'
import Button from '../../Button/Button'
import clsx from 'clsx'
import { orderStatuses } from '../../../helpers'
import { stateUser } from '../../../cache'
import { useReactiveVar } from '@apollo/client'

interface Props {
  price: string
  currency: string
  status: string
  onPrimaryCta: () => void
  onSecondaryCta?: () => void
  assignedTo: {
    name: string,
    image: string
  }
  showIncompletePicking: boolean
  showCompletePicking: boolean
  partialCompletePicking: boolean
}

const OrderDetailsSummaryAction = ({ price, currency, status, onPrimaryCta, onSecondaryCta, assignedTo, showIncompletePicking, showCompletePicking, partialCompletePicking }: Props) => {
  const classes = useStyles()
  const openStatus = status === orderStatuses.OPEN || status === orderStatuses.ASSIGNED || status === orderStatuses.ON_HOLD
  const userDetails = useReactiveVar(stateUser)

  return (
    <div
      className={clsx(
        classes.orderDetailsSummaryAction,
        openStatus
          ? classes.statusOpen
          : status === orderStatuses.IN_PICKING || status === orderStatuses.PARTIALLY_PICKED
          ? classes.statusPicking
          : ''
      )}
    >
      <div className={classes.content}>
        <div className={classes.priceDetailsContainer}>
          <span>Order Grand Total</span>
          <strong>{formatPrice(price, currency)}</strong>
        </div>
        {openStatus && status !== orderStatuses.PARTIALLY_PICKED && (<Button title="START PICKING" type="tertiary" onClick={onPrimaryCta} size="small" />)}
        {(status === orderStatuses.IN_PICKING || status === orderStatuses.PARTIALLY_PICKED) && (
          <div className={classes.buttonContainer}>
            { showIncompletePicking && (
              <Button title="SAVE AND STOP PICKING" type="white" onClick={onPrimaryCta} size="small" />
            )}
            {(showCompletePicking || partialCompletePicking) && (
              <Button title="COMPLETE PICKING" type="white" onClick={onSecondaryCta} size="small" />
            )}
          </div>
        )}
        <div className={classes.userDetailsContainer}>
          <p className={classes.userDetails}>
            <span>{ status === orderStatuses.ASSIGNED ? 'Picker assigned' : status }</span>
            <strong>{ assignedTo.name === userDetails?.name ? 'You' : assignedTo.name }</strong>
          </p>
          {assignedTo.image && (
            <div className={classes.userPhoto}>
              <img src={assignedTo.image} alt={assignedTo.name} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderDetailsSummaryAction
