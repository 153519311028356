import React from 'react'
import HeaderUser from 'components/Header/HeaderUser/HeaderUser'
import MainMenuToggle from 'components/MainMenu/MainMenuToggle/MainMenuToggle'
import { useReactiveVar } from '@apollo/client'
import { stateIsDebugModeActive } from '../../cache'
import { useStyles } from './Header.styles'

const Header = () => {
  const classes = useStyles()
  const isDebugModeActive =  useReactiveVar(stateIsDebugModeActive)
  return (
    <div className={classes.header}>
      <div className={classes.headerCore}>
        <div className={classes.menuToggle}>
          <MainMenuToggle />
        </div>
        {isDebugModeActive && (<small>(Debug active)</small>)}
      </div>
      <HeaderUser />
    </div>
  )
}

export default Header
