import React from 'react'

const IconBurgerMenu = (props) => {
  const fillColor = props.fillColor || '#3D4958'
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={fillColor}
          d="M0.754716981,1.40975988 C0.338113208,1.40975988 0,1.09397366 0,0.704879938 C0,0.315786212 0.338113208,-8.9928065e-15 0.754716981,-8.9928065e-15 L19.245283,-8.9928065e-15 C19.6618868,-8.9928065e-15 20,0.315786212 20,0.704879938 C20,1.09397366 19.6618868,1.40975988 19.245283,1.40975988 L0.754716981,1.40975988 Z M0.754716981,10.7048799 C0.338113208,10.7048799 0,10.3890937 0,10 C0,9.61090627 0.338113208,9.29512006 0.754716981,9.29512006 L19.245283,9.29512006 C19.6618868,9.29512006 20,9.61090627 20,10 C20,10.3890937 19.6618868,10.7048799 19.245283,10.7048799 L0.754716981,10.7048799 Z M0.754716981,20 C0.338113208,20 0,19.6842138 0,19.2951201 C0,18.9060263 0.338113208,18.5902401 0.754716981,18.5902401 L19.245283,18.5902401 C19.6618868,18.5902401 20,18.9060263 20,19.2951201 C20,19.6842138 19.6618868,20 19.245283,20 L0.754716981,20 Z"
          id="path-1"
        ></path>
      </g>
    </svg>
  )
}

export default IconBurgerMenu
