import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontSizes, fontWeights } from 'assets/jss/variables'
import { errorInput, errorMessage, noImage } from '../../assets/jss/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderDetailsContainer: {},
    updateDialog: {
      textAlign: 'center',
      '& .MuiPaper-rounded': {
        borderRadius: '22px',
        padding: '0px',
      },
      '& .MuiDialogContent-root': {
        padding: '20px',
      },
    },
    overviewButtonContents: {
      display: 'flex',
      minWidth: '224px',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: fontSizes.small,
      [theme.breakpoints.up('md')]: {
        minWidth: '300px',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
      '& span': {
        marginRight: '10px',
      },
    },
    plus: {
      fontSize: fontSizes.large,
      fontWeight: fontWeights.bold,
    },
    divider: {
      borderBottom: '1px solid #b8bfc2',
    },
    productInfoContainer: {
      display: 'flex',
    },
    productDetails: {
      textAlign: 'left',
    },
    updateForm: {
      textAlign: 'center',
    },
    noImage: {
      ...noImage,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    numberInput: {
      fontSize: '14px',
      padding: '10px',
      width: '70px',
    },
    entryField: {
      display: 'block',
    },
    buttonContainer: {
      paddingBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    primaryButtonActions: {
      marginBottom: '20px',
      display: 'flex',
      '& button': {
        marginRight: '20px',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    promotion: {
      display: 'block',
      fontWeight: 'bold',
      color: '#35526D',
      textTransform: 'uppercase',
    },
    errorInput,
    errorMessage,
    headerBtn: {
      fontSize: fontSizes.subtitle,
      '& span': {
        fontSize: fontSizes.subtitle,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: fontSizes.hybrid,
        '& span': {
          fontSize: fontSizes.hybrid,
        },
      },
    },
  })
)

export default useStyles
