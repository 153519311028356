import React, { Fragment } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import useStyles from './Notification.styles'
import clsx from 'clsx'

interface NotificationProps {
  showNotification: boolean
  onCloseNotification: () => void
  status: 'warning' | 'error' | 'success' | 'info'
  text: string
}

const Notification = (props: NotificationProps) => {
  const { status, text, showNotification, onCloseNotification } = props
  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />
  const classes = useStyles()
  return (
    <Fragment>
      <Snackbar
        open={showNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={onCloseNotification}
      >
        <Alert onClose={onCloseNotification} severity={status} className={clsx(status === 'success' && classes.success)}>
          {text}
        </Alert>
      </Snackbar>
    </Fragment>
  )
}

export default Notification
