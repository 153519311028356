import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainMenu: {
    flex: 1,
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& > li': {
      listStyle: 'none',
    },
  },
  menuItemBottom: {
    marginBottom: '8px',
    [theme.breakpoints.up('md')]: {
      marginTop: 'auto',
    },
  },
  menuItemBottomPushed: {
    marginTop: 'auto',
    marginBottom: 80,
  },
  menuLink: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 69,
    minHeight: 60,
    fontSize: 14,
    color: '#636C78',
    textDecoration: 'none',
  },
  menuIcon: {
    position: 'absolute',
    display: 'block',
    left: 32,
  },
}))

export default useStyles
