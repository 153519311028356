import React from 'react'
import { useReactiveVar } from '@apollo/client'
import IconOrders from 'components/Icons/IconOrders/IconOrders'
import IconDashboard from 'components/Icons/IconDashboard/IconDashboard'
import MainMenuItem from 'components/MainMenu/MainMenuItem/MainMenuItem'
import IconDashboardFilled from 'components/Icons/IconDashboardFilled/IconDashboardFilled'
import IconLogout from 'components/Icons/IconLogout/IconLogout'
import { stateSelectedOrderIds, stateIsActionbarOpen } from '../../cache'
import { breakpoints } from '../../assets/jss/variables'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import clsx from 'clsx'
import useStyles from './MainMenu.styles'

const MainMenu = () => {
  const selectedOrderIds = useReactiveVar(stateSelectedOrderIds)
  const isActionbarOpen = useReactiveVar(stateIsActionbarOpen)
  const { logout } = useAuth0()
  const classes = useStyles()
  const location = useLocation()
  const nonTabletBreakpoint = useMediaQuery(`(min-width:${breakpoints.md}px)`)

  const logoutFromApp = (e:any) => {
    e.preventDefault()
    localStorage.removeItem('listSearch')
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className={classes.mainMenu}>
      <ul className={classes.menuList}>
        <li>
          <MainMenuItem
            title="Dashboard"
            url="/dashboard"
            icon={IconDashboard}
            iconSelected={IconDashboardFilled}
            isTabletViewport={!nonTabletBreakpoint}
          />
        </li>
        <li>
          <MainMenuItem
            title="Orders"
            url="/orders"
            icon={IconOrders}
            iconSelected={IconOrders}
            isTabletViewport={!nonTabletBreakpoint}
          />
        </li>
        <li className={clsx(selectedOrderIds.length > 0 || (isActionbarOpen && location.pathname === '/orders/orderDetails') ? classes.menuItemBottomPushed : classes.menuItemBottom)}>
          <MainMenuItem
            title="Logout"
            onClick={logoutFromApp}
            url="/logout"
            icon={IconLogout}
            isTabletViewport={!nonTabletBreakpoint}
          />
        </li>
      </ul>
    </div>
  )
}

export default MainMenu
