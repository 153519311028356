import { OperationVariables, QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import { colors } from 'assets/jss/variables'
import { DocumentNode } from 'graphql'
import { DeliveryMethodDetail, OrderStatusDetail } from './types'

export const ODCOrderStatus: { [key: string]: OrderStatusDetail } = {
  OPEN: { label: 'Open', status: 'OPEN' },
  ASSIGNED: { label: 'Assigned', status: 'ASSIGNED' },
  IN_PICKING: { label: 'In picking', status: 'IN_PICKING' },
  PARTIALLY_PICKED: { label: 'Partially picked', status: 'PARTIALLY_PICKED' },
  READY_FOR_PACKING: { label: 'Ready for packing', status: 'READY_FOR_PACKING' },
  ON_HOLD: { label: 'On hold', status: 'ON_HOLD' },
  PARTIALLY_COMPLETED: { label: 'Partially completed', status: 'PARTIALLY_COMPLETED' },
  COMPLETED: { label: 'Completed', status: 'COMPLETED' },
  CANCELLED: { label: 'Cancelled', status: 'CANCELLED' },
}

export const orderStatuses: any = Object.freeze({
  OPEN: ODCOrderStatus.OPEN.label,
  ASSIGNED: ODCOrderStatus.ASSIGNED.label,
  COMPLETED: ODCOrderStatus.COMPLETED.label,
  PARTIALLY_COMPLETED: ODCOrderStatus.PARTIALLY_COMPLETED.label,
  CANCELLED: ODCOrderStatus.CANCELLED.label,
  IN_PICKING: ODCOrderStatus.IN_PICKING.label,
  PARTIALLY_PICKED: ODCOrderStatus.PARTIALLY_PICKED.label,
  READY_FOR_PACKING: ODCOrderStatus.READY_FOR_PACKING.label,
  ON_HOLD: ODCOrderStatus.ON_HOLD.label,
})

export const nonProcessedOrderStatues = [
  orderStatuses.OPEN,
  orderStatuses.ASSIGNED,
  orderStatuses.IN_PICKING,
  orderStatuses.PARTIALLY_PICKED,
  orderStatuses.ON_HOLD,
]

export enum ODCUserRoles {
  MANAGER = 'Manager',
  PICKER = 'Picker',
  PACKER = 'Packer',
}

export const ODCDeliveryMethod: { [key: string]: DeliveryMethodDetail } = {
  FREE_SHIPPING: { name: 'free-shipping-delivery', label: 'Free Delivery', shortLabel: 'Free' },
  NEXT_DAY: { name: 'next-day-delivery', label: 'Next Day Delivery', shortLabel: 'Next Day' },
  NEXT_DAY_PRE_12: {
    name: 'next-day-pre12-delivery',
    label: 'Next Day Pre-12 Delivery',
    shortLabel: 'Pre-12 Next Day',
  },
  NOMINATED_DAY: {
    name: 'nominated-day-delivery',
    label: 'Nominated Day Delivery',
    shortLabel: 'Nominated Day',
  },
  NOMINATED_DAY_PRE_12: {
    name: 'nominated-day-pre12-delivery',
    label: 'Nominated Day Pre-12 Delivery',
    shortLabel: 'Pre-12 Nominated',
  },
  STANDARD: { name: 'standard-delivery', label: 'Standard Delivery', shortLabel: 'Standard' },
}

export const StatusColour = (status?: string) => {
  switch (status) {
    case ODCOrderStatus.OPEN.status:
      return '#E6E6E6'
    case ODCOrderStatus.IN_PICKING.status:
      return '#FF7600'
    case ODCOrderStatus.READY_FOR_PACKING.status:
    case ODCOrderStatus.PARTIALLY_PICKED.status:
      return '#FFA200'
    case ODCOrderStatus.COMPLETED.status:
    case ODCOrderStatus.PARTIALLY_COMPLETED.status:
    case ODCOrderStatus.ASSIGNED.status:
      return '#8CC702'
    case ODCOrderStatus.CANCELLED.status:
      return '#C73E02'
    case ODCOrderStatus.ON_HOLD.status:
      return '#AA2222'
    case ODCDeliveryMethod.NOMINATED_DAY.name:
      return '#EC66BB'
    case ODCDeliveryMethod.NEXT_DAY.name:
      return '#BC6ACC'
    case ODCDeliveryMethod.STANDARD.name:
      return '#05ADD6'
    case ODCDeliveryMethod.NEXT_DAY_PRE_12.name:
    case ODCDeliveryMethod.NOMINATED_DAY_PRE_12.name:
      return '#057BD6'
    default:
      return colors.grey.grey3
  }
}

export const filterConsignmentsForStatus = [ODCOrderStatus.PARTIALLY_COMPLETED.label, ODCOrderStatus.COMPLETED.label]
export const removedItemsForStatus = [ODCOrderStatus.PARTIALLY_COMPLETED.label, ODCOrderStatus.COMPLETED.label]

export const isInPath = (menuPath: string, currentPath: string) => {
  if (currentPath.substring(0, menuPath.length) !== menuPath) {
    return false
  }
  return !(currentPath.length > menuPath.length && currentPath[menuPath.length] !== '/')
}

export const formatPrice = (price: string, currency: string) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  }).format(parseFloat(price))
}

export const formatDate = (dateString: string) => {
  return dateString ? new Intl.DateTimeFormat('en-GB').format(new Date(dateString)) : ''
}

export const getFilteredConsignmentsForStatus = (status: string, consignments: any[]) => {
  if (!filterConsignmentsForStatus.includes(ODCOrderStatus[status].label)) {
    return consignments
  }
  return consignments
    .map((consignment) => ({
      ...consignment,
      lineItems: consignment.lineItems
        .filter((lineItem: any) => lineItem.quantityPicked && lineItem.quantityPicked > 0)
        .sort((a: any, b: any) => a.lineCode - b.lineCode),
    }))
    .filter((consignment) => consignment.lineItems.length)
}

export const getRemovedItemsForStatus = (status: string, consignments: any[]) => {
  const removedItems: any[] = []
  if (!removedItemsForStatus.includes(ODCOrderStatus[status].label)) {
    return []
  }
  consignments.forEach((consignment) => {
    consignment.lineItems.forEach((lineItem: any) => {
      if (lineItem.quantityPicked < lineItem.quantityOrdered) {
        removedItems.push(lineItem)
      }
    })
  })
  return removedItems.sort((a, b) => a.lineCode - b.lineCode)
}

export const getAllItemsOrderedByBinCode = (consignments: any[]) => {
  const allItems: any[] = []
  consignments.forEach((consignment) => {
    consignment.lineItems.forEach((lineItem: any) => {
      allItems.push(lineItem)
    })
  })
  return allItems.sort((a, b) => a.binNo.localeCompare(b.binNo))
}

export const downloadFile = (textContent: string, filename: string) => {
  const element = document.createElement('a')
  const file = new Blob([textContent], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export interface AddressProps {
  name: string
  address1: string
  address2: string
  city: string
  region: string
  county: string
  postcode: string
  phone: string
}

export const isOfType = <T>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined

// Used by StylesProvider to remove undeterministic classnames in snapshots
export const generateClassName = (rule: any, styleSheet: any) => `${styleSheet.options.classNamePrefix}-${rule.key}`

export function useImperativeQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables>['refetch'] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'no-cache',
    skip: true,
  })

  const imperativelyCallQuery = (queryVariables: TVariables) => {
    return refetch(queryVariables)
  }

  return imperativelyCallQuery
}
