import _ from 'lodash'
import React, { useReducer } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { initialFilterState } from '../Orders/OrderList/OrderList'
import useStyles from './NumericIndicator.styles'

const NumericIndicator = (props) => {
  const classes = useStyles()
  const fontClass = props.font === 'heavy' ? classes.textHeavy : ''
  let statuses = props.status
  if (!_.isArray(statuses)) {
    statuses = [statuses]
  }
  const reducer = (state) => {
    let newState = {
      ...state,
      filters: {
        ...state.filters,
        orderStatus: statuses,
        pickingDate: new Date(),
      },
      sort: {
        field: 'status',
        direction: 'ASC',
      },
    }

    localStorage.setItem('listSearch', JSON.stringify(newState))
    return newState
  }
  const [state, dispatch] = useReducer(reducer, initialFilterState)
  const handleSearch = () => dispatch({ state: state })

  return (
    <div>
      <ul>
        <li>
          <span className={clsx(classes.text, fontClass)}>{props.text.toUpperCase()}</span>
        </li>
        <li>
          <Link to={props.link} onClick={handleSearch}>
            <span className={classes.value}>{props.value}</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default NumericIndicator
