import React from 'react'

interface IconProps {
  fillColor?: string
  width?: string
  height?: string
}

const IconPrint = (props: IconProps) => {
  const fillColor = props.fillColor || '#05add6'
  const width = (props.width || 22) + 'px'
  const height = (props.height || 22) + 'px'

  return (
    <svg width={width} height={height} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style>
          {`.prefix__cls-iconPrint{fill:none;stroke:${fillColor};stroke-linecap:round;stroke-linejoin:round}`}
        </style>
      </defs>
      <g id="prefix__ICON_Printer" transform="translate(.5 .5)">
        <path
          id="prefix__Path_71109"
          data-name="Path 71109"
          className="prefix__cls-iconPrint"
          d="M9 10V3h12v7"
          transform="translate(-4.999 -3)"
        />
        <path
          id="prefix__Path_71110"
          data-name="Path 71110"
          className="prefix__cls-iconPrint"
          d="M7 22.5H5a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"
          transform="translate(-3 -6.5)"
        />
        <path
          id="prefix__Path_71111"
          data-name="Path 71111"
          className="prefix__cls-iconPrint"
          d="M9 21h12v8H9z"
          transform="translate(-4.999 -9)"
        />
      </g>
    </svg>
  )
}

export default IconPrint
