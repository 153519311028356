const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'login.planetorganic.io',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'G4eki2m0adS9FR6WCD93f5MTS2h6E79F',
    redirectUri: `${window.location.origin}/callback`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.odc-dev.planetorganic.io/graphql/',
    scope: 'read:orders write:orders',
  },
  graphQL: {
    uri: process.env.REACT_APP_GRAPHQL_URI || 'https://api.odc-dev.planetorganic.io/graphql/',
  },
}

export default config
