import React from 'react'
import Button from 'components/Button/Button'
import IconPlus from 'components/Icons/IconPlus/IconPlus'

import useStyles from './DashboardWidget.styles'

interface Props {
  children: React.ReactNode
  contentOnly?: string
  footer: {
    onClick: () => void
    title: string
  }
  header: {
    button?: {
      onClick: () => void
      title: string
    }
    title: string
  }
  height?: string
  width: string
}

const DashboardWidget = (props: Props) => {
  const classes = useStyles()
  let classNames = classes.dashboardWidget
  classNames += props.width === 'medium' ? ` ${classes.mediumWidth}` : ''
  classNames += props.width === 'full' ? ` ${classes.fullWidth}` : ''
  classNames += props.height === 'medium' ? ` ${classes.mediumHeight}` : ''
  classNames += props.height === 'large' ? ` ${classes.largeHeight}` : ''
  classNames += props.height === 'xlarge' ? ` ${classes.xLargeHeight}` : ''
  classNames += props.contentOnly ? ` ${classes.contentOnly}` : ''
  return (
    <div className={classNames}>
      {props.header && (
        <div className={classes.header}>
          <h2 className={classes.headerTitle}>{props.header.title}</h2>
          {props.header.button && (
            <div className={classes.headerButton}>
              <Button
                type="secondary"
                size="small"
                title={props.header.button.title}
                icon={<IconPlus width={12} height={12} />}
                onClick={props.header.button.onClick}
              />
            </div>
          )}
        </div>
      )}

      <div className={classes.body}>{props.children}</div>

      {props.footer && (
        <div className={classes.footer}>
          <button className={classes.footerLink} onClick={props.footer.onClick}>
            {props.footer.title}
          </button>
        </div>
      )}
    </div>
  )
}

export default DashboardWidget
