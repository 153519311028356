import { makeStyles } from '@material-ui/core/styles'
import { largeDrawerWidth, smallDrawerWidth } from '../../assets/jss/variables'

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: smallDrawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: largeDrawerWidth,
    },
  },
  drawerPaper: {
    width: smallDrawerWidth,
    backgroundColor: 'transparent',
    zIndex: 10,
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: largeDrawerWidth,
    },
  },
}))
