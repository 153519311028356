import { gql } from '@apollo/client'
import { OrderDetails } from './fragments'

export const GET_ORDER = gql`
  query GetOrder($id: String!) {
    order(id: $id) {
      ...OrderProperties
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const GET_ORDERS_SUMMARY = gql`
  query GetOrdersSummary($forDate: String!) {
    ordersSummary(forDate: $forDate) {
      cancelledOrderCount
      onHoldOrderCount
      completedOrderCount
      inpickingOrderCount
      openOrderCount
      assignedOrderCount
      readyForPackingOrderCount
      partiallyPickedOrderCount
      nextDayOrderCount
      nextDayPre12OrderCount
      nominatedOrderCount
      nominatedPre12OrderCount
      standardOrderCount
      nextDayReadyForPackingOrderCount
      nextDayPre12ReadyForPackingOrderCount
      nominatedReadyForPackingOrderCount
      nominatedPre12ReadyForPackingOrderCount
      standardReadyForPackingOrderCount
      totalOrderCount
    }
  }
`

export const GET_ORDER_LIST = gql`
  query GetOrders($first: Int!, $filter: OrderFiltersCriteria!, $sort: OrderFiltersSort!, $cursor: String) {
    orders(first: $first, filter: $filter, sort: $sort, cursor: $cursor) {
      edges {
        cursor
        node {
          id
          orderNumber
          placedAt
          pickingDate
          deliveryMethod
          status
          grandTotal {
            amount
            currency
          }
          customer {
            name
          }
          deliveryCost {
            amount
            currency
          }
          assignedTo {
            id
            externalId
            name
            email
            image
            roles
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const GET_ORDER_USER_HISTORY = gql`
  query getOrderUserHistory($id: String!) {
    orderUserHistory(id: $id) {
      id
      user {
        name
        nickName
      }
      status
      lastModifiedAt
    }
  }
`

export const SHIPPING_LABEL = gql`
  query ShipmentLabel($orderId: ID!, $consignmentId: ID!) {
    shipmentLabel(orderId: $orderId, consignmentId: $consignmentId) {
      data
    }
  }
`

export const GET_PRINTABLE_PICK_LIST = gql`
  query GetOrders($first: Int!, $filter: OrderFiltersCriteria!, $sort: OrderFiltersSort!, $cursor: String) {
    orders(first: $first, filter: $filter, sort: $sort, cursor: $cursor) {
      edges {
        cursor
        node {
          id
          orderNumber
          placedAt
          pickingDate
          deliveryMethod
          status
          deliveryAddress {
            phone
          }
          customer {
            name
            phone
          }
          deliveryCost {
            amount
            currency
          }
          consignments {
            id
            lineItems {
              id
              productId
              title
              promotion
              imageUrl
              priceUnit {
                amount
                currency
              }
              priceTotal {
                amount
                currency
              }
              binNo
              lineCode
              quantityOrdered
              quantityNotPicked
              quantityPartiallyPicked
              quantityPicked
            }
          }
          discountCodes {
            code
          }
          subTotal {
            amount
            currency
          }
          deliveryCost {
            amount
            currency
          }
          grandTotal {
            amount
            currency
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      externalId
      email
      emailVerified
      name
      nickName
      image
      roles
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($roles: [String]) {
    users(first: 100, filter: { roles: $roles }, sort: { field: "name", direction: ASC }) {
      edges {
        cursor
        node {
          id
          externalId
          email
          emailVerified
          name
          nickName
          image
          roles
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_USER_BY_EXTERNAL_ID = gql`
  query GetUserByExternalId($id: String!) {
    userByExtId(id: $id) {
      id
      externalId
      email
      emailVerified
      name
      nickName
      image
      roles
    }
  }
`

export const GET_PRODUCTS = gql`
  query GetProducts($filter: ProductFiltersCriteria!) {
    products(first: 100, filter: $filter, sort: {}) {
      edges {
        cursor
        node {
          id
          externalId
          sku
          title
          image
          promoTitle
          binNumber
          barcodes
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
