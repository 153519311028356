import React from 'react'
import PageTitle from 'components/PageTitle/PageTitle'

const NotFound = () => {
  return (
    <>
      <PageTitle
        title="Sorry that page could not be found"
        intro="Please use the site navigation to access a specific view"
      />
    </>
  )
}

export default NotFound
