import React, { ReactNode } from 'react'
import RoundButton from '../RoundButton/RoundButton'
import Dialog from '@material-ui/core/Dialog'
import useStyles from './Modal.styles'
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
  children: ReactNode
  onClose?: () => void
  onEnter?: () => void
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
}

const Modal = (props: Props) => {
  const { title, setOpen, onClose, children, open, maxWidth } = props
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    !!onClose && onClose()
  }

  return (
    <Dialog
      aria-labelledby={title || ''}
      onClose={handleClose}
      open={open}
      PaperProps={{ className: classes.modal }}
      maxWidth={maxWidth || false}
    >
      <header className={classes.header}>
        {title && <h3>{title}</h3>}
        <RoundButton icon="close" ariaLabel="Close" onClick={handleClose} small={true}>
          <CloseIcon />
        </RoundButton>
      </header>
      <div className={classes.contentContainer}>
        {children}
      </div>
    </Dialog>
  )
}

export default Modal
