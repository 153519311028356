import { gql } from '@apollo/client'
import { OrderDetails } from './fragments'

export const COMPLETE_ORDER = gql`
  mutation CompleteOrder($id: ID!, $lineItems: [PickedLineItem]!, $labels: [ConsignmentLabels], $userId: String) {
    completeOrder(id: $id, lineItems: $lineItems, labels: $labels, userId: $userId) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const CANCEL_ORDER = gql`
  mutation CancelOrder($id: ID!, $userId: String) {
    cancelOrder(id: $id, userId: $userId) {
      ...OrderProperties
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const HOLD_ORDER = gql`
  mutation HoldOrder($id: ID!, $userId: String) {
    holdOrder(id: $id, userId: $userId) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const SAVE_ORDER = gql`
  mutation SaveOrder($id: ID!, $lineItems: [PickedLineItem]!, $userId: String, $completePicking: Boolean) {
    saveOrder(id: $id, lineItems: $lineItems, userId: $userId, completePicking: $completePicking) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const HOLD_AND_SAVE_ORDER = gql`
  mutation holdAndSaveOrder($id: ID!, $lineItems: [PickedLineItem]!, $userId: String) {
    holdAndSaveOrder(id: $id, lineItems: $lineItems, userId: $userId) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const UPDATE_ORDER_NOTES = gql`
  mutation UpdateOrderNotes($orderId: ID!, $notes: String) {
    updateOrderNotes(orderId: $orderId, notes: $notes) {
      ...OrderProperties
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const CREATE_SHIPMENT = gql`
  mutation CreateShipment($orderId: ID!, $consignmentId: ID!, $labelQuantity: Int!) {
    createShipment(orderId: $orderId, consignmentId: $consignmentId, labelQuantity: $labelQuantity) {
      id
    }
  }
`

export const START_PICKING_ORDERS = gql`
  mutation startPickingOrders($orderIds: [String], $userId: String) {
    startPickingOrders(orderIds: $orderIds, userId: $userId) {
      success
      errorMessage
    }
  }
`

export const READY_FOR_PACKING_ORDER = gql`
  mutation readyForPackingOrder($id: ID!, $lineItems: [PickedLineItem]!, $userId: String) {
    readyForPackingOrder(id: $id, lineItems: $lineItems, userId: $userId) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`

export const ASSIGN_ORDERS_TO_USER = gql`
  mutation assignUserToOrder($orderIds: [String]!, $userId: String!) {
    assignOrders(orderIds: $orderIds, userId: $userId) {
      order {
        ...OrderProperties
      }
      errorMessage
    }
  }
  ${OrderDetails.fragments.orderProperties}
`
