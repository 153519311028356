import React, { Fragment, useReducer, useState, useEffect, Reducer } from 'react'
import OrderSearchForm from './OrderSearchForm/OrderSearchForm'
import { useReactiveVar, useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { OrderListSearch } from './OrderList.types'
import Notification from '../../Notification/Notification'
import { DateRange } from 'materialui-daterange-picker'
import OrderResultsTable, { Order } from './OrderResultsTable/OrderResultsTable'
import OrdersSelected from './OrdersSelected/OrdersSelected'
import { stateSelectedOrderIds, stateUser } from '../../../cache'
import { GET_ORDER_LIST, GET_USERS } from '../../../graphQL/queries'
import { ASSIGN_ORDERS_TO_USER } from '../../../graphQL/mutations'
import { has, pick } from 'lodash'
import { Data } from './OrderResultsTable/OrderResultsTable.props'
import { useHistory } from 'react-router-dom'
import { ODCDeliveryMethod, ODCUserRoles, orderStatuses, nonProcessedOrderStatues } from '../../../helpers'
import useStyles from './OrderList.styles'

let moment = require('moment-timezone')
const initialDateRange = { startDate: undefined, endDate: undefined }

export const initialFilterState = {
  isInitial: true,
  filters: {
    orderNumber: '',
    surname: '',
    postcode: '',
    email: '',
    dateOrderedTo: initialDateRange,
    dueDeliveryDateTo: initialDateRange,
    deliveryMethod: [],
    pickingDate: null,
    orderStatus: [],
    orderStatuses: Object.keys(orderStatuses),
    deliveryMethods: Object.values(ODCDeliveryMethod),
    assignedTo: null,
  },
  sort: {
    field: 'pickingDate',
    direction: 'DESC',
  },
}

export const orderListReducer: Reducer<OrderListSearch, any> = (
  state: OrderListSearch,
  action: { type: string; payload: any | undefined }
): OrderListSearch => {
  let newState = { ...state }

  if (action.type === 'loadLocal') {
    newState = {
      ...state,
      ...action.payload,
      isInitial: false,
    }
  }
  if (action.type === 'reset') {
    newState = {
      ...state,
      filters: { ...initialFilterState.filters },
    }
  }
  if (action.type === 'setField') {
    newState = {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.field]: action.payload.value,
      },
    }
  }
  if (action.type === 'setSort') {
    newState = {
      ...state,
      sort: action.payload,
    }
  }

  localStorage.setItem('listSearch', JSON.stringify(newState))
  return newState
}

const OrderList = () => {
  const [state, dispatch] = useReducer(orderListReducer, initialFilterState)
  const [selectedOrderNumbers, setSelectedOrderNumbers] = useState<string[]>([])
  const [pickerList, setPickerList] = useState<{ name: string, id: string, image:string }[]>([])
  const [pickerSelected, setPickerSelected] = useState<string | null>(null)
  const [selectAll, setSelectAll] = useState(false)
  const [showingAssignedOrders, setShowingAssignedOrders] = useState(false)
  const [formattedData, setData] = useState<Data[]>([])
  const [assignedFormattedData, setAssignedData] = useState<Data[]>([])
  const [hasClearedFilters, setClearedFilters] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [cursor, setCursor] = useState<string>('')
  const [assignedCursor, setAssignedCursor] = useState<string>('')
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')

  const [getOrderList, { loading, data, fetchMore }] = useLazyQuery(GET_ORDER_LIST, {
    fetchPolicy: 'network-only',
  })

  const [getAssignedOrderList, { data: dataAssigned, fetchMore: fetchMoreAssigned }] = useLazyQuery(GET_ORDER_LIST, {
    fetchPolicy: 'network-only',
  })

  const [assignOrdersToUser] = useMutation(ASSIGN_ORDERS_TO_USER, {
    onCompleted: () => onSearch()
  })

  const classes = useStyles()
  const selectedOrderIds = useReactiveVar(stateSelectedOrderIds)
  const userDetails = useReactiveVar(stateUser)

  useQuery(GET_USERS, {
    variables: { roles: [ODCUserRoles.PICKER] },
    onCompleted: (data) => {
      setPickerList(data.users.edges.map((user: any) => ({ name: user.node.name, image: user.node.image, id: user.node.id })))
    },
  })

  const history = useHistory()
  const qtyToPaginate = 50

  useEffect(() => {
    let searchData = state
    if (!userDetails) {
      return
    }
    if (state.isInitial) {
      let localSearchData: any = localStorage.getItem('listSearch')
      if (localSearchData) {
        try {
          searchData = JSON.parse(localSearchData)
          dispatch({ type: 'loadLocal', payload: searchData })
        } catch (e: any) {}
      }
    }
    stateSelectedOrderIds([])
    const searchConfig = (isPickerUser?: boolean) => ({
      variables: {
        first: qtyToPaginate,
        sort: searchData.sort,
        filter: filtersForQuery(searchData, isPickerUser),
      },
    })
    getOrderList(searchConfig())
    getAssignedOrderList(searchConfig(true))
    // eslint-disable-next-line
  }, [userDetails])

  const formatData = (data: any, currentFormattedData: any) => {
    if (has(data, 'orders.edges') && data.orders.edges.length > 0) {
      const { edges } = data.orders
      return {
        results: currentFormattedData.concat(
          edges.map((order: any) => {
            return { ...order.node }
          })
        ),
        cursor: edges[edges.length - 1].cursor,
      }
    } else {
      return {
        results: [],
        cursor: '',
      }
    }
  }

  useEffect(() => {
    const { results, cursor } = formatData(data, formattedData)
    setData(results)
    setCursor(cursor)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    const { results, cursor } = formatData(dataAssigned, assignedFormattedData)
    setAssignedData(results)
    setAssignedCursor(cursor)
    // eslint-disable-next-line
  }, [dataAssigned])

  useEffect(() => {
    if (hasClearedFilters) {
      onSearch()
      setClearedFilters(false)
    }
    // eslint-disable-next-line
  }, [hasClearedFilters, state.filters])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'setField', payload: { field: e.target.name, value: e.target.value } })
  }

  const onPickerChange = (name: string, value: DateRange | MaterialUiPickersDate) => {
    const pickerValue = value || ''
    dispatch({ type: 'setField', payload: { field: name, value: pickerValue } })
  }

  const convertToISOString = (date: any) => {
    return date ? new Date(date).toISOString() : ''
  }

  const formatDateFrom = (date: any) => {
    return date ? moment(date).tz('Europe/London').startOf('day').format() : ''
  }

  const formatDateTo = (date: any) => {
    return date ? moment(date).tz('Europe/London').endOf('day').format() : ''
  }

  const filtersForQuery = (state: any, assignedToMe?: boolean) => {
    const { dateOrderedTo, dueDeliveryDateTo, pickingDate } = state.filters
    return {
      ...pick(state.filters, ['orderNumber', 'surname', 'postcode', 'email', 'orderStatus', 'deliveryMethod']),
      dateOrderedFrom: formatDateFrom(dateOrderedTo.startDate),
      dateOrderedTo: formatDateTo(dateOrderedTo.endDate),
      dueDeliveryDateFrom: formatDateFrom(dueDeliveryDateTo.startDate),
      dueDeliveryDateTo: formatDateTo(dueDeliveryDateTo.endDate),
      pickingDate: convertToISOString(pickingDate),
      assignedTo: assignedToMe ? { id: userDetails?.id } : null,
    }
  }

  const onShowAssignedOrders = () => {
    setShowingAssignedOrders(true)
  }

  const onSeeAllOrders = () => {
    setShowingAssignedOrders(false)
  }

  const onSearch = () => {
    const searchConfig = (isPicker?: boolean) => ({
      variables: {
        first: qtyToPaginate,
        filter: filtersForQuery(state, isPicker),
        sort: state.sort,
      },
    })
    getOrderList(searchConfig())
    getAssignedOrderList(searchConfig(true))
  }

  const onSort = (field: string, direction: string) => {
    let updatedSort = { field, direction }
    if (state.sort.direction === 'DESC') {
      updatedSort = initialFilterState.sort
    }
    dispatch({ type: 'setSort', payload: updatedSort })
    const searchConfig = (isPicker?: boolean) => ({
      variables: {
        first: qtyToPaginate,
        filter: filtersForQuery(state, isPicker),
        sort: updatedSort,
      },
    })
    if (showingAssignedOrders) {
      getAssignedOrderList(searchConfig(true))
    } else {
      getOrderList(searchConfig())
    }
  }

  const onClear = () => {
    dispatch({ type: 'reset' })
    setClearedFilters(true)
  }

  const loadMore = async () => {
    if (!fetchMore) {
      return
    }
    setLoadingMore(true)
    if (showingAssignedOrders && fetchMoreAssigned) {
      await fetchMoreAssigned({
        variables: {
          cursor: assignedCursor,
        },
      })
    } else {
      await fetchMore({
        variables: {
          cursor,
        },
      })
    }
    setLoadingMore(false)
  }

  const onSelectionChange = (rows: any) => {
    if (!rows.length) {
      setSelectAll(false)
    }
    setPickerSelected(null)
    setSelectedOrderNumbers(rows)
    stateSelectedOrderIds(
      (showingAssignedOrders ? assignedFormattedData : formattedData)
        .filter((row) => rows.includes(row.orderNumber))
        .map((row) => row.id)
    )
  }

  const onUserAssignmentChange = async (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const pickerId = e.target.value as string
    // Filter out orders that haven't been processed
    const selectedOrders = formattedData.filter((order) => selectedOrderIds.includes(order.id) && nonProcessedOrderStatues.includes(orderStatuses[order.status]))
    setPickerSelected(pickerId)
    await assignOrdersToUser({
      variables: {
        orderIds: selectedOrders.map((order) => order.id),
        userId: pickerId,
      },
    })
    const orderNumbers = selectedOrders.map((order) => order.orderNumber)
    const picker = pickerList.find((picker:any) => picker.id === pickerId)
    const plural = selectedOrders.length > 1
    setConfirmationMessage(`Order number${plural ? 's' : ''} (${orderNumbers.join(', ')}) ${plural ? 'have' : 'has'} been assigned to ${picker?.name}`)
  }

  const printSelectedList = () => {
    history.push('/picklist')
  }

  return (
    <Fragment>
      <Notification
        showNotification={!!confirmationMessage}
        status="success"
        onCloseNotification={() => setConfirmationMessage('')}
        text={confirmationMessage}
      />

      <div className={classes.headerContainer}>
        <h1>Search orders</h1>
      </div>
      <div>
        <OrderSearchForm
          params={{ ...state.filters }}
          onInputChange={onInputChange}
          onPickerChange={onPickerChange}
          onSearch={onSearch}
          onClear={onClear}
        />
        <OrderResultsTable
          rows={showingAssignedOrders ? assignedFormattedData : formattedData}
          rowCount={{ all: formattedData.length, assigned: assignedFormattedData.length }}
          loading={loading || loadingMore}
          hasMoreItems={
            showingAssignedOrders ? dataAssigned?.orders?.pageInfo?.hasNextPage : data?.orders?.pageInfo?.hasNextPage
          }
          loadMore={loadMore}
          onSelectionChange={onSelectionChange}
          onSort={onSort}
          onShowAssignedOrders={onShowAssignedOrders}
          onSeeAllOrders={onSeeAllOrders}
          selectAll={selectAll}
          onSetSelectAll={setSelectAll}
          selectedOrderNumbers={selectedOrderNumbers}
          initialSortBy={state.sort.field as keyof Data}
          initialSortOrder={state.sort.direction.toLowerCase() as Order}
          showingAssignedOrders={showingAssignedOrders}
        />
        <OrdersSelected
          orderCount={selectedOrderNumbers.length}
          printSelectedList={printSelectedList}
          show={selectedOrderIds.length > 0}
          onUserAssignmentChange={onUserAssignmentChange}
          pickerList={pickerList}
          pickerSelected={pickerSelected}
          isAdminUser={userDetails?.roles.includes(ODCUserRoles.MANAGER)}
        />
      </div>
    </Fragment>
  )
}

export default OrderList
