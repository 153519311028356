import React from 'react'
import { useStyles } from './PageTitle.styles'

interface Props {
  title: string
  intro?: string
  children?: JSX.Element
}

export const PageTitle = ({ title, intro, children }:Props) => {
  const classes = useStyles()
  return (
    <div className={classes.PageTitle}>
        <div>
          <h1 className={classes.title}>{title}</h1>
          {intro && <p className={classes.intro}>{intro}</p>}
        </div>
      {children && <div className={classes.actions}>{children}</div>}
    </div>
  )
}

export default PageTitle
