import React, { Fragment } from 'react'
import Button from 'components/Button/Button'
import IconPrint from 'components/Icons/IconPrint/IconPrint'
import { orderStatuses } from '../../../helpers'
import { useHistory } from 'react-router-dom'
import useStyles from './OrderDetailsHeader.styles'
import OrderStatusLozenge from 'components/Orders/OrderStatusLozenge/OrderStatusLozenge'

interface Props {
  onHoldOrder: () => void
  onSaveOrder: () => void
  onProcessOrder: () => void
  onCancelOrder: () => void
  onPrintPicklist: () => void
  canSave: boolean
  canProcess: boolean
  orderNumber: string
  status:
    | 'OPEN'
    | 'COMPLETED'
    | 'PARTIALLY_COMPLETED'
    | 'CANCELLED'
    | 'IN_PICKING'
    | 'PARTIALLY_PICKED'
    | 'ON_HOLD'
    | 'ASSIGNED'
    | 'READY_FOR_PACKING'
}

const OrderDetailsHeader = (props: Props) => {
  const {
    onHoldOrder,
    onSaveOrder,
    onProcessOrder,
    onCancelOrder,
    onPrintPicklist,
    orderNumber,
    status,
    canSave,
  } = props
  const savedStatus = orderStatuses[status]
  const completedStatus =
    savedStatus === orderStatuses.COMPLETED || savedStatus === orderStatuses.PARTIALLY_COMPLETED
  const canHold =
    savedStatus !== orderStatuses.COMPLETED &&
    savedStatus !== orderStatuses.PARTIALLY_COMPLETED &&
    savedStatus !== orderStatuses.ON_HOLD
  const history = useHistory()
  const classes = useStyles()
  const savedStatusClass = savedStatus.replace(/\s+/g, '-').toLowerCase()

  const headerButtons = () => {
    const CancelAndPickButtons = (props: { children?: any }) => (
      <Fragment>
        <Button
          type="textOnly"
          icon={<IconPrint />}
          title="Print this pick list"
          onClick={onPrintPicklist}
          className={classes.headerBtn}
        />
        {props.children}
        {canHold ? <Button title="Hold order" type="primary" key={'hold-order'} onClick={onHoldOrder} className={classes.headerBtn} /> : ''}
        <Button title="Cancel order" type="secondary" onClick={onCancelOrder} className={classes.headerBtn} />
        {savedStatus === orderStatuses.READY_FOR_PACKING && <Button title="Return to orders" type="secondary" onClick={() => history.push('/orders')} className={classes.headerBtn} />}
      </Fragment>
    )

    if (savedStatus === orderStatuses.CANCELLED) return null
    if (completedStatus) {
      return (
        <Button title="Return to orders" type="secondary" onClick={() => history.push('/orders')} className={classes.headerBtn} />
      )
    }
    if (
      savedStatus === orderStatuses.IN_PICKING ||
      savedStatus === orderStatuses.PARTIALLY_PICKED ||
      savedStatus === orderStatuses.ON_HOLD ||
      savedStatus === orderStatuses.READY_FOR_PACKING
    ) {
      return (
        <Fragment>
          <CancelAndPickButtons>
                  { savedStatus !== orderStatuses.PARTIALLY_PICKED && savedStatus !== orderStatuses.READY_FOR_PACKING && (
                  <Button
                    title="Save order"
                    key={'save-order'}
                    onClick={onSaveOrder}
                    disabled={!canSave}
                    className={classes.headerBtn}
                  />
                  )}
                  { savedStatus === orderStatuses.READY_FOR_PACKING && (
                    <Button
                      title="Process order"
                      key={'process-order'}
                      onClick={onProcessOrder}
                      className={classes.headerBtn}
                    />
                  )}
          </CancelAndPickButtons>
        </Fragment>
      )
    }
    if (savedStatus === orderStatuses.OPEN || savedStatus === orderStatuses.ASSIGNED || savedStatus === orderStatuses.ON_HOLD) {
      return <CancelAndPickButtons />
    }
  }

  return (
    <div className={classes.header}>
        <div className={classes.details}>
        <h1>
          Order# <strong>{orderNumber}</strong>       
        </h1>
        <span>Status:  
          <div className={classes.savedStatus} data-record={savedStatusClass}>
            <OrderStatusLozenge status={savedStatus} />
          </div>
        </span>
        </div>
        <div className={classes.buttonContainer}>{headerButtons()}</div>
    </div>
  )
}

export default OrderDetailsHeader
