import React from 'react'
import useStyles from './ProductImage.styles'
import { Img } from 'react-image'
import clsx from 'clsx'

const ProductImage = (props: { imageUrl: string; isSmall?: boolean }) => {
  const { imageUrl, isSmall } = props
  const classes = useStyles()
  return (
    <div className={clsx(classes.productImage, isSmall && classes.small)}>
      <Img src={[imageUrl]} unloader={<div className={classes.noImage}><span>PRODUCT IMAGE</span></div>} />
    </div>
  )
}

export default ProductImage
