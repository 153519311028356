import React from 'react'

const IconCheckbox = (props) => {
  const fillColor = props.fillColor || '#f3770a'
  const width = props.width ? props.width + 'px' : '30px'
  const height = props.height ? props.height + 'px' : '30px'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g data-name="Group 32609" transform="translate(-14)">
        <rect width={width} height={height} rx={4} transform="translate(14)" fill={fillColor} />
        <path
          d="M25.1 13.737a.453.453 0 00-.578 0L23.146 14.9a.425.425 0 00-.036.034.4.4 0 00.036.584l2.254 1.905 1.749 1.477a.453.453 0 00.578 0l1.749-1.477 5.378-4.538a.425.425 0 00.036-.034.4.4 0 00-.036-.584L33.475 11.1a.453.453 0 00-.575 0l-5.173 4.363a.453.453 0 01-.578 0z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default IconCheckbox
