import React from 'react'

const IconArrowRight = (props) => {
  const fillColor = props.fillColor || '#3D4958'
  const width = (props.width || 22) + 'px'
  const height = (props.height || 22) + 'px'
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={fillColor}
          d="M21.72,10.42A.76.76,0,0,1,22,11a.73.73,0,0,1-.29.58.49.49,0,0,1-.15.27l-11,9.9a.72.72,0,0,1-1-.19.71.71,0,0,1-.08-1l9.77-8.8H.83a.78.78,0,1,1,0-1.55H19.29L9.51,1.42a.71.71,0,0,1,.08-1,.72.72,0,0,1,1-.19l11,9.9A.53.53,0,0,1,21.72,10.42Z"
        />
      </g>
    </svg>
  )
}

export default IconArrowRight
