import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { buttonPrintContainer } from '../../assets/jss/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPrintContainer,
  })
)

export default useStyles
