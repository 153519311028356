import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'assets/jss/theme'
import { fontSizes } from 'assets/jss/variables'

const useStyles = makeStyles({
  productTitle: {
    fontSize: fontSizes.subtitle,
    margin: '-5px 0 0 20px',
    [theme.breakpoints.up('md')]: {
      fontSize: fontSizes.hybrid,
    },
  },
  promotion: {
    display: 'block',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  productInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  small: {
    '& $productImage': {
      width: '50px',
      height: '50px',
    },
  },
})

export default useStyles
