import { makeStyles } from '@material-ui/core/styles'
import { colors, fontSizes } from '../../assets/jss/variables'

export const useStyles = makeStyles((theme) => ({
  primary: {},
  secondary: {},
  tertiary: {},
  white: {},
  grey: {},
  orange: {},
  outlined: {},
  textOnly: {},
  warning: {},
  disabled: {},
  fullWidth: {},
  small: {},
  medium: {},
  large: {},
  icon: {},
  text: {},
  textSpacer: {},
  loader: {},
  hasContents: {
    '& > div': {
      marginTop: '-5px',
      marginBottom: '-5px',
    },
  },
  btn: {
    //minHeight: 50,
    fontFamily: 'Avenir Roman',
    padding: '10px 30px',
    fontSize: 14,
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    '&$primary': {
      color: '#fff',
      background: 'radial-gradient(circle at -10px -10px, #00cef9 0%, #00d0d4 100%)',
      '&:hover': {
        background: 'radial-gradient(circle at -10px -10px, #00a4c7 0%, #00a6a9 100%)',
      },
      '&$disabled': {
        background: 'radial-gradient(circle at -10px -10px, #b2f0fe 0%, #b2f1f3 100%)',
        cursor: 'not-allowed',
      },
      '&[disabled]': {
        background: 'radial-gradient(circle at -10px -10px, #b2f0fe 0%, #b2f1f3 100%)',
        cursor: 'not-allowed',
      },
    },
    '&[disabled]': {
      cursor: 'not-allowed',
    },
    '&$secondary': {
      background: 'transparent',
      border: '1px solid #B8BFC2',
      color: '#738699',
      '&:hover': {
        background: 'rgba(#3d4958, 0.1)',
        color: '#636c78',
        borderColor: '#636c78',
      },
      '&$disabled': {
        background: 'transparent',
        borderColor: '#3d4958',
        opacity: 0.3,
      },
      '&[disabled]': {
        background: 'transparent',
        borderColor: '#3d4958',
        opacity: 0.3,
      },
    },
    '&$tertiary': {
      backgroundColor: '#8DCA00',
      color: '#FFF',
    },
    '&$white': {
      textTransform: 'none',
      backgroundColor: '#FFF',
    },
    '&$grey': {
      backgroundColor: colors.grey.grey1,
    },
    '&$orange': {
      backgroundColor: colors.orange,
      color: '#FFF',
    },
    '&$outlined': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.blue}`,
      color: colors.blue,
      textTransform: 'none',
    },
    '&$textOnly': {
      overflow: 'visible',
      padding: 0,
      border: 0,
      textTransform: 'none',
      color: colors.blue,
      fontSize: fontSizes.hybrid,
      background: 'transparent',
      lineHeight: 'normal',
      textDecoration: 'underline',
      cursor: 'pointer',
      '-moz-user-select': 'text',
      '&::-moz-focus-inner': {
        padding: 0,
        border: 0,
      },
      '& $icon': {
        marginRight: '10px',
      },
    },
    '&$warning': {
      border: '1px solid #da1939',
      background: 'transparent',
      color: '#da1939',
      '&:hover': {
        background: 'rgba(#da1939, 0.1)',
      },
      '&$disabled': {
        opacity: '0.3',
        '&:hover': {
          background: 'transparent',
        },
      },
      '&[disabled]': {
        opacity: '0.3',
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    '&$small': {
      padding: '8px 25px',
      minHeight: 32,
    },
    '&$medium': {
      padding: '16px 25px',
      minHeight: 32,
    },
    '&$large': {
      padding: '18px 25px',
      fontSize: 14,
    },
    '&$fullWidth': {
      width: '100%',
    },
    '& $icon': {
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '-5px 10px -5px 0',
    },
    '& $text': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& $textSpacer': {
      paddingLeft: 10,
    },
    '& $loader': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: 20,
    },
  },
}))
