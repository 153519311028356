import React, { useEffect } from 'react'
import useStyles from './OrderResultsTable.styles'
import Button from 'components/Button/Button'
import OrderStatusLozenge from 'components/Orders/OrderStatusLozenge/OrderStatusLozenge'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Data, OrderResultsTableProps } from './OrderResultsTable.props'
import Loader from 'components/Loader/Loader'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { formatDate } from '../../../../helpers'
import Checkbox from 'components/Checkbox/Checkbox'

export type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  { id: 'orderNumber', numeric: false, disablePadding: false, label: 'Order' },
  { id: 'placedAt', numeric: true, disablePadding: false, label: 'Order Timestamp' },
  { id: 'customerName', numeric: true, disablePadding: false, label: 'Customer Name' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Order Status' },
  { id: 'deliveryMethod', numeric: true, disablePadding: false, label: 'Delivery Method' },
  { id: 'pickingDate', numeric: true, disablePadding: false, label: 'Picking Date' },
  { id: 'grandTotal', numeric: true, disablePadding: false, label: 'Order Total' },
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  onShowAssignedOrders: () => void
  onSeeAllOrders: () => void
  order: Order
  orderBy: string
  rowCount: {
    all: number
    assigned: number
  }
  showingAssignedOrders?: boolean
  allRowsSelected: boolean
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    showingAssignedOrders,
    onShowAssignedOrders,
    onSeeAllOrders,
    onRequestSort,
    allRowsSelected,
  } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell colSpan={8} className={classes.tabHeaderCell}>
            <div className={classes.pickerButtons}>
              <button
                className={clsx(classes.tabButton, !showingAssignedOrders && classes.selected)}
                onClick={onSeeAllOrders}
              >
                See all ({rowCount.all})
              </button>
              <button
                className={clsx(classes.tabButton, showingAssignedOrders && classes.selected)}
                onClick={onShowAssignedOrders}
              >
                Assigned to me
              </button>
              <span className={classes.assignedCount}>{rowCount.assigned}</span>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount.all}
              checked={rowCount.all > 0 && numSelected === rowCount.all && allRowsSelected}
              color="primary"
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all orders' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={(headCell.id === 'placedAt' || headCell.id === 'grandTotal') ? classes.mediumTabletUp : undefined}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  )
}

const OrderResultsTable = ({
  rows,
  loading,
  hasMoreItems,
  loadMore,
  onSelectionChange,
  onSort,
  onShowAssignedOrders,
  onSeeAllOrders,
  selectedOrderNumbers,
  initialSortBy,
  initialSortOrder,
  selectAll,
  onSetSelectAll,
  showingAssignedOrders,
  rowCount,
}: OrderResultsTableProps) => {
  const classes = useStyles({ hasData: rows.length > 0 })
  const [order, setOrder] = React.useState<Order>(initialSortOrder)
  const [orderBy, setOrderBy] = React.useState<keyof Data>(initialSortBy)
  const history = useHistory()
  const allRowsSelected = !!rows.filter((row) => selectedOrderNumbers.includes(row.orderNumber)).length

  useEffect(() => {
    setOrder(initialSortOrder)
    setOrderBy(initialSortBy)
  }, [initialSortOrder, initialSortBy])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    const sortOrder = isAsc ? 'desc' : 'asc'
    setOrder(sortOrder)
    setOrderBy(property)
    onSort(property, sortOrder.toUpperCase())
  }

  const handleSelectAllClick = () => {
    if (!selectAll) {
      const newSelecteds = rows.map((n: any) => n.orderNumber)
      onSelectionChange(newSelecteds)
      onSetSelectAll(true)
      return
    }
    onSelectionChange([])
  }

  const NoResults = () => {
    return (
      <div className={classes.noResultsFound}>
        {showingAssignedOrders ? (
          <>
            <p>No orders have been assigned to you</p>
            <span>Please either refine your search or look in 'See all'</span>
          </>
        ) : (
          <>
            <p>No search results found</p>
            <span>Please refine your search in order to return a list of orders</span>
          </>
        )}
      </div>
    )
  }

  const handleClick = (event: React.MouseEvent<unknown>, orderNumber: string, orderId: string) => {
    const selectedIndex = selectedOrderNumbers.indexOf(orderNumber)
    let newSelected: string[] = []
    if (event.target.toString().indexOf('HTMLInputElement') === -1) {
      history.push(`/orders/order-details/${orderId}`)
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedOrderNumbers, orderNumber)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedOrderNumbers.slice(1))
      } else if (selectedIndex === selectedOrderNumbers.length - 1) {
        newSelected = newSelected.concat(selectedOrderNumbers.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedOrderNumbers.slice(0, selectedIndex),
          selectedOrderNumbers.slice(selectedIndex + 1)
        )
      }
      onSelectionChange(newSelected)
    }
  }
  const isSelected = (name: string) => selectedOrderNumbers.indexOf(name) !== -1
  return (
    <div className={classes.orderResultsContainer}>
      <div className={classes.orderResultsTable}>
        {loading && (
          <div className={classes.loader}>
            <Loader diameter={30} />
          </div>
        )}
        {rows.length < 1 && !loading && <NoResults />}
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedOrderNumbers.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              onShowAssignedOrders={onShowAssignedOrders}
              onSeeAllOrders={onSeeAllOrders}
              rowCount={rowCount}
              showingAssignedOrders={showingAssignedOrders}
              allRowsSelected={allRowsSelected}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.orderNumber)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    className={clsx(classes.tableRow, isItemSelected && classes.tableRowSelected)}
                    onClick={(event) => handleClick(event, row.orderNumber, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderNumber}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId, id: `order-${row.orderNumber}` }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.orderCellInner}>
                        <span>{row.orderNumber}</span>
                        <span className={classes.smallTabletOnly}>{formatDate(row.placedAt)}</span>
                        <span className={classes.smallTabletOnly}>{new Intl.NumberFormat('en-GB', {
                          style: 'currency',
                          currency: row.grandTotal.currency,
                        }).format(parseFloat(row.grandTotal.amount))}</span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.mediumTabletUp}>{formatDate(row.placedAt)}</TableCell>
                    <TableCell align="left">{row.customer.name}</TableCell>
                    <TableCell align="left">
                      <OrderStatusLozenge status={row.status} />
                    </TableCell>
                    <TableCell align="left">{row.deliveryMethod}</TableCell>
                    <TableCell align="left">{formatDate(row.pickingDate)}</TableCell>
                    <TableCell align="left" className={classes.mediumTabletUp}>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: row.grandTotal.currency,
                      }).format(parseFloat(row.grandTotal.amount))}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {hasMoreItems && <Button title="LOAD MORE" type="secondary" onClick={loadMore} />}
    </div>
  )
}

export default OrderResultsTable
