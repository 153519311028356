import { createTheme } from '@material-ui/core/styles'
import { breakpoints, colors, fontSizes, fontWeights } from './variables'

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          fontSize: '16px',
          color: colors.grey.grey2,
          lineHeight: '20px',
          margin: 0,
          padding: 0,
          fontFamily: "'Avenir Book', sans-serif",
          fontWeight: fontWeights.regular,
        },
        thead: {
          '@media print': {
            display: 'table-header-group',
          },
        },
        button: {
          color: colors.grey.grey2,
          border: 'none',
          outline: 'none',
          backgroundColor: colors.white,
          '&:active': {
            border: 'none',
            outline: 'none',
          },
          '&:hover': {
            border: 'none',
            outline: 'none',
          },
        },
        h1: {
          fontSize: fontSizes.large,
          fontFamily: "'Avenir Roman', sans-serif",
          fontWeight: fontWeights.medium,
          margin: '0 0 15px 0',
        },
        h2: {
          fontSize: fontSizes.medium,
          margin: '0 0 15px 0',
        },
        p: {
          marginTop: '0',
          marginBottom: '15px',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        li: {
          listStyle: 'none',
        },
        em: {
          fontStyle: 'normal',
        },
        a: {
          textDecoration: 'none',
          color: colors.grey.grey2,
          '&:visited': {
            color: colors.grey.grey2,
          },
          '&:hover': {
            color: colors.grey.grey2,
          },
          '&:active': {
            color: colors.grey.grey2,
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        color: colors.grey.grey2,
      },
      inputMultiline: {
        '@media only screen and (max-width: 1024px)': {
          fontSize: fontSizes.hybrid,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 'inherit',
        color: colors.grey.grey2,
        '@media only screen and (max-width: 1024px)': {
          padding: '10px',
        },
        '@media print': {
          padding: '5px',
        },
      },
      head: {
        fontWeight: 'bold',
      },
      footer: {
        fontSize: fontSizes.hybrid,
        color: '#3d4958',
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom: '1px solid #b8bfc2',
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: fontSizes.hybrid,
      },
    },
    MuiInputLabel: {
      animated: {
        '@media only screen and (max-width: 1024px)': {
          fontSize: fontSizes.subtitle,
        },
      },
    },
    MuiTypography: {
      displayBlock: {
        textTransform: 'capitalize',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '& .MuiSvgIcon-root': {
          width: '30px',
          height: '30px',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '22px',
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: colors.grey.grey2,
    },
    text: {
      primary: colors.grey.grey2,
    },
  },
  typography: {
    fontFamily: ['Avenir Book', 'sans-serif', 'Roboto', '"Helvetica Neue"'].join(','),
  },
})
