import React, { Fragment } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Spinner from 'components/Spinner/Spinner'
import Layout from 'components/Layout/Layout'
import Splash from 'components/Splash/Splash'

const App: React.FunctionComponent = (props) => {
  const { isAuthenticated, isLoading } = useAuth0()
  if (isLoading) {
    return <Spinner message="Checking authentication response..." />
  } else if (!isAuthenticated) {
    return (
      <Layout
        showHeader={false}
        showFooter={true}
        mainContent={<Splash />}
      />
    )
  }

  return <Fragment>{props.children}</Fragment>
}

export default App
