import React from 'react'

const IconPlus = (props) => {
  const fillColor = props.fillColor || '#3D4958'
  const width = props.width ? props.width + 'px' : '22px'
  const height = props.height ? props.height + 'px' : '22px'

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={fillColor}
          d="M10.285,10.285 L10.285,0.715 C10.285,0.320116404 10.6051164,3.2046962e-14 11,3.19744231e-14 C11.3948836,3.19018842e-14 11.715,0.320116404 11.715,0.715 L11.715,10.285 L21.285,10.285 C21.6798836,10.285 22,10.6051164 22,11 C22,11.3948836 21.6798836,11.715 21.285,11.715 L11.715,11.715 L11.715,21.285 C11.715,21.6798836 11.3948836,22 11,22 C10.6051164,22 10.285,21.6798836 10.285,21.285 L10.285,11.715 L0.715,11.715 C0.320116404,11.715 4.83592932e-17,11.3948836 0,11 C-4.83592932e-17,10.6051164 0.320116404,10.285 0.715,10.285 L10.285,10.285 Z"
          id="path-1"
        ></path>
      </g>
    </svg>
  )
}

export default IconPlus
