import React from 'react'
import { formatDate, formatPrice } from '../../../helpers'
import { AddressProps, OrderProps, OrderUserHistory } from '../../../types'
import useStyles from './OrderDetailsOverview.styles'
import OrderDetailsNotes from '../OrderDetailsNotes/OrderDetailsNotes'
import OrderStatusLozenge from 'components/Orders/OrderStatusLozenge/OrderStatusLozenge'
import { orderStatuses } from '../../../helpers'
import moment from 'moment-timezone'
import clsx from 'clsx'

interface Props {
  data: OrderProps
  orderUserHistory: OrderUserHistory[]
  onUpdateOrderNotes: (notes: string) => void
}

const OrderDetailsOverview = (props: Props) => {
  const { data, onUpdateOrderNotes, orderUserHistory } = props
  const classes = useStyles()

  type fieldKeys = keyof AddressProps
  const formatAddress = (address: AddressProps) => {
    if (!address) return null
    const addressFields: fieldKeys[] = [
      'name',
      'address1',
      'address2',
      'city',
      'region',
      'postcode',
      'county',
      'phone',
    ]
    return (
      <ul className={classes.address}>
        {addressFields.map((field) => (
          <li key={field}>{address[field]}</li>
        ))}
      </ul>
    )
  }

  return (
    <div className={classes.orderDetailsOverview}>
      <div className={classes.columns}>
        <div className={classes.section}>
          <ul className={classes.column}>
            <li>
              <span>Customer Name</span>
              {data.deliveryAddress.name}
            </li>
            <li>
              <span>Order Status</span>
              <div className={classes.orderStatus}><OrderStatusLozenge status={data.status} isSmall={true} /></div>
            </li>
            <li>
              <span>Due Delivery Date</span>
              {formatDate(data.dueDeliveryDate)}
            </li>
          </ul>
          <ul className={classes.column}>
            <li>
              <div><span>{data.revisedGrandTotal ? 'Original ' : ''}Order Total</span><OrderStatusLozenge status={data.paymentStatus} isSmall={true} /></div>
              {formatPrice(data.grandTotal.amount, data.grandTotal.currency)}
            </li>
            {data.revisedGrandTotal && (
              <li>
                <span>Revised Order Total</span>
                {formatPrice(data.revisedGrandTotal.amount, data.revisedGrandTotal.currency)}
              </li>
            )}
            <li>
              <span>Date Placed</span>
              {formatDate(data.placedAt)}
            </li>
            <li>
              <span>Delivery Method</span>
              {data.deliveryMethod}
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          <ul className={classes.column}>
            <li>
              <span>Email Address</span>
              {data.customer.email}
            </li>
            <li>
              <span>Delivery Address</span>
              {formatAddress(data.deliveryAddress)}
            </li>
          </ul>
          <ul className={classes.column}>
            <li>
              <span>Telephone Number</span>
              {data.customer.phone}
            </li>
            <li>
              <span>Invoice Address</span>
              {formatAddress(data.billingAddress)}
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.historyNotesContainer}>
        <ul className={classes.pickerHistory}>
          <span>Picker History</span>
          {orderUserHistory.map((record:any) => {
            const recordStatus = orderStatuses[record.status].replace(/\s+/g, '-').toLowerCase()
            return (
              <li>
                <span>
                  <span>{moment(record.lastModifiedAt).format('DD/MM/YYYY')}</span>
                  <span className={clsx(classes.smallTabletOnly, classes.recordUserName)}>{record.user.name}</span>
                </span>
                <div className={classes.recordStatus} data-record={recordStatus}>
                  <OrderStatusLozenge status={orderStatuses[record.status]} isSmall={true} />
                </div>
                <span className={classes.mediumTabletUp}>{record.user.name}</span>
              </li>
            )
        })}
        </ul>
        <div className={classes.orderNotes}>
          <OrderDetailsNotes notes={data.notes} onUpdateOrderNotes={onUpdateOrderNotes} />
        </div>
      </div>
    </div>
  )
}

export default OrderDetailsOverview
