export const breakpoints = {
  xs: 480,
  sm: 720,
  md: 1024,
  lg: 1280,
  xl: 1672,
}

export const fontSizes = {
  label: '0.625rem',
  subtitle: '0.75rem',
  hybrid: '0.875rem',
  small: '1rem',
  medium: '1.25rem',
  large: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  xxxl: '3rem',
  super: '3.75rem',
  xxxxl: '4rem',
}

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 750,
}

export const spacing = {
  xxs: '5px',
  xs: '8px',
  small: '16px',
  medium: '20px',
  large: '32px',
  xl: '48px',
  xxl: '64px',
  xxxl: '88px',
  super: '120px',
}

export const colors = {
  grey: {
    grey1: '#EAEAEA',
    grey2: '#35526D',
    grey3: '#62747A',
  },
  green: '#8DCA00D9',
  black: '#000000',
  white: '#FFFFFF',
  orange: '#F3770A',
  blue: '#42C1E0',
  red: '#F80000',
}

export const statusColors = {
  open: '#E6E6E6',
  inPicking: '#FF7600',
  readyForPacking: '#FFA200',
  partiallyPicked: '#FFA200',
  completed: '#8CC702',
  assigned: '#8CC702',
  partiallyCompleted: '#8CC702',
  cancelled: '#C73E02',
  onHold: '#AA2222',
}

export const typography = {
  body_1: {
    fontSize: fontSizes.small,
    fontWeight: fontWeights.regular,
    letterSpacing: '0px',
    lineHeight: '1rem',
  },
  large: {
    fontSize: fontSizes.large,
    fontWeight: fontWeights.regular,
    letterSpacing: '0.5px',
    lineHeight: '1rem',
  },
}

export const smallDrawerWidth = '90px'
export const largeDrawerWidth = '240px'

export const colorHighlight = '#ff7600'
