import React from 'react'
import Button from 'components/Button/Button'
import useStyles from '../../assets/jss/dialog.style'

interface Props {
  onConfirm: () => void
  onCancel: () => void
  message: string
}

const ConfirmationDialog = (props: Props) => {
  const { onConfirm, onCancel, message } = props
  const classes = useStyles()
  return (
      <div className={classes.content}>
        <p>{message}</p>
        <Button onClick={onConfirm} title="Confirm" fullWidth={true}  size="medium" />
        <Button onClick={onCancel} title="Cancel" type="secondary" fullWidth={true}  size="medium" />
      </div>
  )
}

export default ConfirmationDialog
