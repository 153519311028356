import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardWidgets: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    dashboardWidget: {
      width: '624px',
      height: 'auto',
      marginBottom: '20px',
      marginRight: '24px',
    },
    dashboardWidgetFullWidth: {
      width: '100%',
      marginRight: 0,
    },
  })
)

export default useStyles
