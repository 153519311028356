import React from 'react'
import { useStyles } from './OrderStatusLozenge.styles'
// import { ODCOrderStatus } from '../../../helpers'
import clsx from 'clsx'

interface OrderStatusLozengeProps {
  status: string
  isSmall?: boolean 
}

const OrderStatusLozenge = ({ status, isSmall }: OrderStatusLozengeProps) => {
  const classes = useStyles({ status })
  return (
    <div className={clsx(classes.orderStatusLozenge, isSmall && classes.small)}>
      {/* { status === ODCOrderStatus.ASSIGNED.status && (
        <span className={classes.assignedIndicator}></span>
      )} */}
      <span>{status.replace(/_/g, ' ')}</span>
    </div>
  )
}

export default OrderStatusLozenge
