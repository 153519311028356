import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'assets/jss/theme'
import { colors, fontSizes } from '../../../assets/jss/variables'

const useStyles = makeStyles({
  orderDetailsSummaryAction: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100px',
    alignItems: 'center',
    position: 'fixed',
    fontSize: fontSizes.small,
    bottom: 0,
    left: 0,
    zIndex: 10,
    width: '100%',
    '& > *': {
      zIndex: 3,
    },
    '&:after': {
      content: "''",
      opacity: 0.9,
      position: 'absolute',
      bottom: 0,
      height: '100%',
      width: '100%',
    },
    '& button': {
      margin: '0 10px 0 10px',
    },
    [theme.breakpoints.up('md')]: {
      height: '70px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
    padding: '0 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 32px',
    },
    width: '100%',
  },
  priceDetailsContainer: {
    color: colors.white,
    alignItems: 'center',
    '& span': {
      display: 'block',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      fontSize: fontSizes.subtitle,
      padding: '8px 15px',
      '&:first-child': {
        marginBottom: '10px',
        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
        },
      },
      '&:only-child': {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& > button': {
        fontSize: fontSizes.hybrid,
        padding: '8px 25px',
      },
    },
  },
  userDetailsContainer: {
    color: colors.white,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      display: 'block',
    },
  },
  statusOpen: {
    '&:after': {
      backgroundColor: colors.grey.grey2,
    },
  },
  statusPicking: {
    '&:after': {
      backgroundColor: colors.green,
    },
  },
  userPhoto: {
    position: 'relative',
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  userDetails: {
    margin: '0 12px 0 0',
    textAlign: 'right',
  },
})

export default useStyles
