import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fontWeights } from '../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'table-cell',
      verticalAlign: 'bottom',
      textAlign: 'left',
      fontSize: '10px',
      fontWeight: fontWeights.bold,
      lineHeight: '14px',
      color: '#35526D',
    },
    textHeavy: {
      fontSize: '14px',
    },
    value: {
      width: '302px',
      textAlign: 'left',
      fontSize: '48px',
      fontWeight: fontWeights.bold,
      lineHeight: '48px',
      color: '#35526D',
    },
  })
)

export default useStyles
