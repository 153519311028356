import React from 'react'
import Loader from 'components/Loader/Loader'
import useStyles from './Spinner.styles'

interface Props {
  message?: string
  diameter?: number
  borderWidth?: number
  color?: string
}

const Spinner = ({ message, ...rest }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.spinner}>
      <div className={classes.loader}>
        <Loader {...rest} />
      </div>
      <p className={classes.message}>{message}</p>
    </div>
  )
}

export default Spinner
