import React, { useState, useEffect } from 'react'
import PickListOrder from './PickListOrder/PickListOrder'
import { stateSelectedOrderIds } from '../../cache'
import { useReactiveVar } from '@apollo/client'
import useStyles from './PickList.styles'
import { useLazyQuery, useMutation } from '@apollo/client'
import Button from 'components/Button/Button'
import { GET_PRINTABLE_PICK_LIST } from '../../graphQL/queries'
import { START_PICKING_ORDERS } from '../../graphQL/mutations'
import { has } from 'lodash'
import { OrderProps } from '../../types'
import { PickListOrderProps } from './PickListOrder/PickListOrder.props'
import Spinner from 'components/Spinner/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { orderStatuses } from '../../helpers'
import { useAuth0 } from '@auth0/auth0-react'

interface ErrorResponse {
  responseType: 'success' | 'error'
  responseText: string
  showNotification: boolean
}

const PickList = () => {
  const { user } = useAuth0()
  const [getPickList, { loading, data }] = useLazyQuery(GET_PRINTABLE_PICK_LIST)
  const [startPickingOrders, { loading: pickingLoading }] = useMutation(START_PICKING_ORDERS)
  const [formattedData, setData] = useState<PickListOrderProps[]>([])
  const [openOrders, setOpenOrders] = useState<string[]>([])
  const [pickingResponse, setPickingResponse] = React.useState<ErrorResponse>({
    responseType: 'error',
    responseText: '',
    showNotification: false,
  })
  const { orderId } = useParams<{ orderId?: string }>()
  const orderIds = useReactiveVar(stateSelectedOrderIds)
  const classes = useStyles()
  const history = useHistory()
  const orderLimit = 500

  useEffect(() => {
    if (!orderIds.length) {
      history.push('/orders')
    }

    getPickList({
      variables: {
        first: orderLimit,
        sort: { field: 'status', direction: 'DESC' },
        filter: { orderIds },
      },
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (has(data, 'orders.edges') && data.orders.edges.length > 0) {
      const { edges } = data.orders
      const mappedData = edges.map((edge: { cursor: string; node: OrderProps[] }) => {
        return { ...edge.node }
      })
      setData(mappedData)
      setOpenOrders(
        mappedData
          .filter(
            (order: OrderProps) =>
              orderStatuses[order.status] === orderStatuses.OPEN ||
              orderStatuses[order.status] === orderStatuses.ASSIGNED ||
              orderStatuses[order.status] === orderStatuses.ON_HOLD
          )
          .map((order: OrderProps) => order.id)
      )
    } else {
      setData([])
    }
    // eslint-disable-next-line
  }, [data])

  const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />

  const handleCloseNotification = () => {
    setPickingResponse({ ...pickingResponse, showNotification: false })
  }

  const handlePickOrders = async () => {
    const userId = user ? user.sub : undefined
    try {
      await startPickingOrders({ variables: { orderIds: openOrders, userId: userId } })
      setPickingResponse({
        responseType: 'success',
        responseText: 'The orders have been set to in-picking',
        showNotification: true,
      })
    } catch (e: any) {
      setPickingResponse({ responseType: 'error', responseText: e.message, showNotification: true })
    }
  }

  const { showNotification, responseType, responseText } = pickingResponse

  return (
    <div className={classes.pickListContainer}>
      <Snackbar
        open={showNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={responseType}>
          {responseText}
        </Alert>
      </Snackbar>
      {loading || pickingLoading ? (
        <Spinner message="Loading orders" />
      ) : (
        <div className={classes.buttonPrintContainer}>
          {orderId && (
            <Button
              onClick={() => history.push(`/orders/order-details/${orderId}`)}
              title="Order details"
            />
          )}
          <Button onClick={() => history.push('/orders')} title="Order listing" />
          <Button onClick={() => window.print()} title="Print" />
          {responseType !== 'success' && !!openOrders.length && (
            <Button onClick={() => handlePickOrders()} title="Set Open orders to in-picking" />
          )}
        </div>
      )}
      {formattedData.map((order: any) => (
        <PickListOrder {...order} key={order.orderNumber} />
      ))}
    </div>
  )
}

export default PickList
