import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../assets/jss/variables'

const styles = makeStyles(() => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loader: {
    marginBottom: spacing.large,
  },
  message: {
    textAlign: 'center',
  },
}))

export default styles
