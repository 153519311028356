import React, { Fragment } from 'react'
import useStyles from './OrderDetailsTable.styles'
import { OrderProps } from '../../../types'
import {
  formatPrice,
  getRemovedItemsForStatus,
  getFilteredConsignmentsForStatus,
} from '../../../helpers'
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@material-ui/core'
import Button from 'components/Button/Button'
import IconPrint from 'components/Icons/IconPrint/IconPrint'
import ProductInfo from 'components/ProductInfo/ProductInfo'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { orderStatuses } from '../../../helpers'

interface LineItems {
  id: string
  quantity: number | undefined
}

interface ActionButtons {
  id: string
  quantityUpdated: number
  quantityOrdered: number
  quantityNotPicked: number
  consignmentIndex: number
  isRemoved: boolean
}

interface DataProps {
  data: OrderProps
  inPicking: boolean
  lineItemsPicked: LineItems[]
  onEditLineItem: (lineItemId: string, consignmentIndex: number) => void
  onPrintDispatchNote: (consignmentId: number) => void
  onGetShippingLabel: (consignmentId: string) => void
}

const OrderDetailsTable = (props: DataProps) => {
  const classes = useStyles()
  const {
    data,
    lineItemsPicked,
    inPicking,
    onEditLineItem,
    onPrintDispatchNote,
  } = props

  const savedStatus = orderStatuses[data.status]
  const completedStatus =
    savedStatus === orderStatuses.COMPLETED || savedStatus === orderStatuses.PARTIALLY_COMPLETED

  const actionButtons = ({ id, quantityOrdered, quantityNotPicked, quantityUpdated, consignmentIndex, isRemoved } : ActionButtons) => {
    const selectedItem = lineItemsPicked.find((lineItem) => lineItem.id === id)   
    const selectedQuantity = (completedStatus ? quantityUpdated : selectedItem ? selectedItem.quantity : 0) || 0
      return (
        <Fragment>
            <TableCell align="center">
              <div className={classes.buttonContainer}>
                <Button disabled={!inPicking} type={selectedQuantity === quantityOrdered ? 'tertiary' : selectedQuantity > 0 || ((selectedItem && selectedQuantity === 0) || quantityNotPicked === quantityOrdered)  ? 'orange' : 'grey'} title={`${selectedQuantity}/${quantityOrdered}`} onClick={() => onEditLineItem(id, consignmentIndex)} />
                { (selectedQuantity > 0 && !isRemoved) && (<span className={classes.pickedStatus}>{ selectedQuantity === quantityOrdered ? 'COMPLETED' : 'PARTIALLY PICKED' }</span>) }
              </div>
            </TableCell>
        </Fragment>
      )
    }

  const renderItems = (items: any, type: string) => {
    let qtyToUse = 'quantityOrdered'
    if (completedStatus) {
      qtyToUse = type === 'consignment' ? 'quantityPicked' : 'quantityRefunded'
    }
    if (isEmpty(items[0].lineItems)) return null
    return (
      <Fragment>
        {items.map((item: any, consignmentIndex: number) => {
          const consignmentNumber = consignmentIndex + 1
          return (
            <Fragment key={`${item.id}-${consignmentIndex}`}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6} className={clsx(completedStatus && classes.spaciousHeader)}>
                    <div className={classes.consignmentHeader}>
                      {type === 'consignment' ? (
                        <Fragment>
                         { (items.length > 1 || completedStatus) && (
                          <span className={classes.consignmentTitle}>
                            Consignment #{consignmentNumber}
                          </span>
                         )}
                          {completedStatus && (
                            <Button
                              type="textOnly"
                              icon={<IconPrint />}
                              title={`Print Dispatch Note for Consignment #${consignmentNumber}`}
                              onClick={() => onPrintDispatchNote(item.id)}
                            />
                          )}
                        </Fragment>
                      ) : (
                        <span className={classes.consignmentTitle}>Removed Items</span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.headerProductCell}>Product</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="left">BIN No.</TableCell>
                  <TableCell align="left">
                    Line Code
                  </TableCell>
                  <TableCell align="left" colSpan={2}>
                    Picked Qty
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.lineItems.map((row: any, lineitemIndex: number) => (
                  <TableRow key={`${data.orderNumber}-${lineitemIndex}`}>
                    <TableCell align="left">
                      <ProductInfo
                        imageUrl={row.imageUrl}
                        title={row.title}
                        promotion={row.promotion}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {formatPrice(row.priceUnit.amount, row.priceUnit.currency)}
                    </TableCell>
                    <TableCell align="left">{row.binNo}</TableCell>
                    <TableCell align="left">{row.lineCode}</TableCell>
                    {actionButtons({ id: row.id, consignmentIndex, quantityOrdered: row.quantityOrdered, quantityNotPicked: row.quantityNotPicked,  quantityUpdated: row[qtyToUse], isRemoved: qtyToUse === 'quantityRefunded' })}
                  </TableRow>
                ))}
              </TableBody>
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  let totalItems = 0
  data.consignments.forEach((consignment: any) => {
      consignment.lineItems.forEach((lineItem: any) => {
        totalItems += lineItem.quantityOrdered
      })
    }
  )

  const totalItemsPicked = lineItemsPicked.reduce((acc:any , currentItem: any) => {
    return acc + currentItem.quantity
  }, 0)

  return (
    <Fragment>
      <div className={classes.tableContainer}>
        {!completedStatus && (
        <div className={classes.totalContainer}>
          <div>
            <div className={clsx(classes.totalItemsLeft, (totalItemsPicked > 0 && totalItemsPicked < totalItems) && classes.statusSomePicked, (totalItemsPicked === totalItems) && classes.statusAllPicked)}>{totalItems-totalItemsPicked}</div> <span>Items to pick</span>
          </div>
          <div>
            <span className={classes.total}>{totalItemsPicked}</span><span>Items picked</span>
          </div>
          <div>
            <span className={classes.total}>{totalItems}</span><span>Total items</span>
          </div>
        </div>
        )}
        <Table aria-label="Pick list">
          {renderItems(
            getFilteredConsignmentsForStatus(data.status, data.consignments),
            'consignment'
          )}
          {renderItems(
            [{ lineItems: getRemovedItemsForStatus(data.status, data.consignments) }],
            'removed'
          )}
          <TableFooter>
            <TableRow className={clsx(classes.footerRow, classes.footerFirst)}>
              <TableCell align="right">Sub Total:</TableCell>
              <TableCell colSpan={5} align="left">
                {formatPrice(data.subTotal.amount, data.subTotal.currency)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.footerRow}>
              <TableCell align="right">Delivery ({data.deliveryMethod}):</TableCell>
              <TableCell colSpan={5} align="left">
                {formatPrice(data.deliveryCost.amount, data.deliveryCost.currency)}
              </TableCell>
            </TableRow>
            <TableRow className={clsx(classes.footerRow, classes.footerLast)}>
              <TableCell align="right">
                <strong>Grand Total:</strong>
              </TableCell>
              <TableCell colSpan={5} align="left">
                <strong>{formatPrice(data.grandTotal.amount, data.grandTotal.currency)}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Fragment>
  )
}

export default OrderDetailsTable
