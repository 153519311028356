import React from 'react'
import { useStyles } from './HeaderUser.styles'
import { stateUser } from '../../../cache'
import { useReactiveVar } from '@apollo/client'

const HeaderUser = () => {
  const classes = useStyles()
  const userDetails = useReactiveVar(stateUser)
  return (
    <div className={classes.headerUser}>
      <p className={classes.userDetails}>
        <em className={classes.userName}>{userDetails?.name}</em>
        <span className={classes.userRole}>{userDetails?.roles[0] || 'User'}</span>
      </p>
      <div className={classes.userPhoto}>
        <img src={userDetails?.image} alt={userDetails?.name} />
      </div>
    </div>
  )
}

export default HeaderUser
