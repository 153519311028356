import React from 'react'
import Countdown from 'react-countdown'
import PercentageIndicator from 'components/PercentageIndicator/PercentageIndicator'

import useStyles from './DashboardSummaryHeader.styles'

interface Props {
  ordersSummary: {
    Count: number
    onHoldOrderCount: number
    completedOrderCount: number
    inpickingOrderCount: number
    openOrderCount: number
    assignedOrderCount: number
    readyForPackingOrderCount: number
    partiallyPickedOrderCount: number
    nextDayOrderCount: number
    nextDayPre12OrderCount: number
    nominatedOrderCount: number
    nominatedPre12OrderCount: number
    standardOrderCount: number
    pre12OrderCount: number
    totalOrderCount: number
  },
  subtext: string,
  diameter: number,
  showCounter?: boolean,
  hours?: number,
  minutes?: number,
  seconds?: number,
  completed?: boolean,
}

const DashboardSummaryHeader = (props: Props) => {
  const classes = useStyles()
  let moment = require('moment')
  const processedPercentage = props.ordersSummary.totalOrderCount
    ? Math.ceil((props.ordersSummary.completedOrderCount * 100) / props.ordersSummary.totalOrderCount)
    : 0
  const countDownRenderer = ({ hours, minutes, seconds, completed }: any) => {
    if (!completed) {
      return (
        <span>
          {hours}h: {minutes}m left
        </span>
      )
    }
  }
  return (
    <div className={classes.header}>
      <PercentageIndicator
        mainText={`${processedPercentage}%`}
        subText={props.subtext}
        diameter={props.diameter}
        outerColor="#8CC702"
        percentage={processedPercentage}
      />
      {props.showCounter ? (
        <div className={classes.counter}>
          <Countdown date={moment().endOf('day').valueOf()} renderer={countDownRenderer} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default DashboardSummaryHeader
