import { Theme as ThemeType } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles<ThemeType>(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 0 40px',
  },
  headerCore: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  menuToggle: {
    position: 'relative',
    left: '-10px',
  },
}))
