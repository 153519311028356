import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject, ApolloProvider } from '@apollo/client'
import config from './config'
import { cache } from './cache'
import { useAuth0 } from '@auth0/auth0-react'

const { scope } = config.auth0
const { uri } = config.graphQL

const SecureApolloProvider = (props: any) => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>('')

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        let newToken = await getAccessTokenSilently({
          audience: uri,
          scope: scope,
        })
        setAccessToken(newToken)
      } catch (e: any) {
        console.log(`Error getting access token: ${e.message}`)
      }
    }
    getAccessToken()
    // eslint-disable-next-line
  }, [])

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    uri,
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
    cache,
  })

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default SecureApolloProvider
