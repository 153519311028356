import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useStyles } from './BuildInfo.styles'

const BuildInfo = () => {
  const { getAccessTokenSilently } = useAuth0()
  const classes = useStyles()

  const copyAccessCode = async () => {
    let textArea = document.createElement('textarea')
    textArea.value = await getAccessTokenSilently()
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')
      console.log('Access code copied to clipboard')
    } catch (err) {
      console.error('Failed to copy access code to clipboard')
    }
    
    document.body.removeChild(textArea)
  }

  return (
    <p className={classes.buildInfo}>
      Build Info:{' '}
      <em>
        {process.env.REACT_APP_BUILD_INFO} - {process.env.REACT_APP_COMMIT_SHA1}
      </em>{' '}
      | Build Date:
      <em>{process.env.REACT_APP_BUILD_DATE}</em> |
      <span className={classes.accessCode} onClick={copyAccessCode}>
        {' '}
        (copy auth token)
      </span>
    </p>
  )
}

export default BuildInfo
