import React, { CSSProperties, ReactNode } from 'react'
import useStyles from './RoundButton.styles'
import clsx from 'clsx'
import Fab from '@material-ui/core/Fab'

export type Props = {
  icon: string
  label?: string
  ariaLabel: string
  disabled?: boolean
  onClick: () => any
  labelAlign?: 'side' | 'bottom'
  className?: string
  classNames?: { [key in 'container' | 'label']?: string }
  small?: boolean
  iconWidth?: number
  style?: CSSProperties
  children: ReactNode
}

const RoundButton = ({
  ariaLabel,
  disabled,
  onClick,
  className,
  classNames,
  small,
  children,
  style,
}: Props) => {
  const classes = useStyles()

  return (
    <div
      style={style}
      className={clsx(classes.buttonContainer, className, classNames?.container, small && classes.small)}
    >
      <Fab
        className={clsx(classes.roundButton, small && classes.small)}
        onClick={onClick}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        <>{children}</>
      </Fab>
    </div>
  )
}

export default RoundButton
