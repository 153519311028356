import React from 'react'
import useStyles from '../../../assets/jss/dialog.style'
import IconPrint from 'components/Icons/IconPrint/IconPrint'
import Button from 'components/Button/Button'

type ButtonConfig = {
  label: string
  onClick: () => void
  disabled?: boolean
}

interface Props {
  onContinuePicking: () => void
  buttonPrintConfig: ButtonConfig
  buttonStatusChangeConfig: ButtonConfig
  message: string
}

const OrderDetailsActionChoice = (props: Props) => {
  const { message, onContinuePicking, buttonPrintConfig, buttonStatusChangeConfig } = props
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <p>{message}</p>
      <Button
          type="outlined"
          fullWidth={true}
          size="medium"
          icon={<IconPrint />}
          title={buttonPrintConfig.label}
          onClick={buttonPrintConfig.onClick}
          disabled={buttonPrintConfig.disabled}
      />
      <Button
          type="primary"
          fullWidth={true}
          size="medium"
          title={buttonStatusChangeConfig.label}
          onClick={buttonStatusChangeConfig.onClick}
          disabled={buttonStatusChangeConfig.disabled}
      />
      <Button
          type="tertiary"
          fullWidth={true}
          size="medium"
          title="CONTINUE PICKING"
          onClick={onContinuePicking}
      />
    </div>
  )
}

export default OrderDetailsActionChoice
