import React from 'react'
import { DateRangePicker, DateRange } from 'materialui-daterange-picker'
import { TextField } from '@material-ui/core'
import IconCalendar from '../Icons/IconCalendar/IconCalendar'
import useStyles from './InputDateRangePicker.styles'

interface Props {
  onChange: (name: string, value: DateRange) => any
  id: string
  label: string
  name: string
  value: DateRange
  maxDate?: Date
}

const InputDateRangePicker = ({ onChange, id, label, name, value, maxDate }: Props) => {
  const [open, setOpen] = React.useState(false)
  const toggle = () => {
    setOpen(!open)
  }

  const formatDate = (date: any) => {
    return date ? new Date(date).toLocaleDateString('en-gb') : ''
  }

  const classes = useStyles()
  const { startDate, endDate } = value
  const todaysDate = new Date()
  const currentStartDate = formatDate(startDate)
  const currentEndDate = formatDate(endDate)

  const onDateRangeChange = (name: string, range: DateRange) => {
    onChange(name, range)
    setOpen(false)
  }

  const fieldValue =
    currentStartDate && currentEndDate ? `${currentStartDate} - ${currentEndDate}` : ''

  return (
    <React.Fragment>
      <div className={classes.dateRangePicker}>
        <DateRangePicker
          open={open}
          toggle={toggle}
          onChange={(range) => onDateRangeChange(name, range)}
          maxDate={maxDate}
          initialDateRange={{ startDate: todaysDate, endDate: todaysDate }}
        />
      </div>
      <TextField
        id={id}
        label={label}
        name={name}
        value={fieldValue}
        onKeyDown={() => false}
        onClick={() => setOpen(true)}
        InputProps={{
          endAdornment: (<IconCalendar />),
        }}
      />
    </React.Fragment>
  )
}

export default InputDateRangePicker
