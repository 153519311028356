import { Theme as ThemeType } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors, fontSizes } from 'assets/jss/variables'

interface CssProps {
  show: boolean
}

export const useStyles = makeStyles<ThemeType, CssProps>(() => ({
  ordersSelectedContainer: {
    backgroundColor: colors.grey.grey3,
    fontSize: fontSizes.subtitle,
    color: colors.white,
    position: 'fixed',
    padding: '0 30px',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: 250,
    overflow: 'hidden',
    display: 'flex',
    height: ({ show }) => {
      return show ? '70px' : '0'
    },
    alignItems: 'center',
    '& > button': {
      height: 'fit-content',
      marginRight: '30px',
    },
    transition: 'all .2s ease-out',
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      margin: '8px 0px 8px 0px',
      width: '200px',
    },
    '& .MuiSelect-icon': {
      color: colors.white,
    },
    '& .MuiFormLabel-root': {
      color: colors.white,
    },
    '& .MuiInputBase-input': {
      color: colors.white,
    },
    '& .MuiSelect-select': {
      backgroundColor: 'transparent!important',
      display: 'flex',
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: `1px solid ${colors.white}!important`,
      },
      '&:after': {
        borderBottom: `1px solid ${colors.white}!important`,
      },
    },
  },
  ordersSelected: {
    fontSize: '18px',
    marginRight: '20px',
  },
  printButton: {
    color: `${colors.white}!important`,
  },
  userImage: {
    width: '16px',
    height: '16px',
    marginRight: '10px',
    borderRadius: '50%',
  },
  imageFallback: {
    backgroundColor: colors.green,
  },
  selectMenu: {
    '& label': {
      fontSize: fontSizes.hybrid,
      transform: 'translate(0, 10px) scale(1)',
      '&.MuiFormLabel-filled': {
        transform: 'translate(0, -10px) scale(0.85)',
      },
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
}))
