import { makeStyles } from '@material-ui/core/styles'
import { largeDrawerWidth, smallDrawerWidth } from '../../assets/jss/variables'

export const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  layoutContainer: {
    display: 'flex',
    position: 'relative',
    minHeight: '100%',
    zIndex: 1,
    transition: 'filter 300ms ease',
  },
  layoutMain: {
    flexGrow: 1,
    marginLeft: `-${smallDrawerWidth}`,
    padding: '0 15px',
    transition: 'opacity 1000ms ease',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: `-${largeDrawerWidth}`,
      padding: '0 30px',
    },
  },
  layoutContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 28,
    overflowY: 'scroll',
    height: 'calc(100vh - 118px)',
  },
  layoutContentMain: {
    flex: '1 1',
  },
  layoutContentFooter: {
    padding: '30px 0 0',
  },
  layoutBackground: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.3,
    background: '#fff',
  },
  layoutBackgroundImage: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: 'blur(125px)',
  },
  layoutSidebarOpen: {
    '& $layoutMain': {
      marginLeft: 0,
    },
  },
}))
