import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'assets/jss/variables'

const useStyles = makeStyles({
  logo: {
    margin: '0px 0 30px 0',
    display: 'block',
  },
  layoutTableWrapper: {
    marginTop: '40px',
    width: '100%',
    '& th': {
      fontWeight: 'normal',
    },
    '& h1': {
      marginBottom: '30px',
    },
    pageBreakAfter: 'always',
  },
  orderDetails: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: '20px',
    fontSize: '13px',
    '& > li': {
      margin: '0 50px 10px 0',
      lineHeight: '24px',
      '@media print': {
        marginRight: '25px',
      },
    },
    '& > li:last-child': {
      marginRight: '0',
    },
    '& span': {
      display: 'block',
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    fontSize: '13px',
  },
  productName: {
    maxWidth: '275px',
  },
  box: {
    padding: '10px',
    minHeight: '50px',
    width: '50px',
    border: `1px solid ${colors.grey.grey3}`,
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
