import React, { useReducer } from 'react'
import PageTitle from 'components/PageTitle/PageTitle'
import DashboardSummary from 'components/Dashboard/DashboardSummary/DashboardSummary'
import DashboardWidget from 'components/Dashboard/DashboardWidget/DashboardWidget'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { GET_ORDERS_SUMMARY } from '../../graphQL/queries'
import Spinner from 'components/Spinner/Spinner'
import { initialFilterState, orderListReducer } from '../Orders/OrderList/OrderList'
import useStyles from './Dashboard.styles'

const Dashboard = () => {
  const classes = useStyles()
  const { user } = useAuth0()
  const history = useHistory()
  let moment = require('moment')
  const { loading, data } = useQuery(GET_ORDERS_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: { forDate: moment().format('YYYY-MM-DD') },
  })

  const [state, dispatch] = useReducer(orderListReducer, initialFilterState)
  const viewAllOrders = () => {
    localStorage.setItem(
      'listSearch',
      JSON.stringify({
        ...state,
        filters: { ...initialFilterState.filters },
        sort: { field: 'placedAt', direction: 'DESC' },
      })
    )
    dispatch({ type: 'reset' })
    history.push('/orders')
  }

  return (
    <div className="dashboard">
      <PageTitle title={`Welcome ${user?.nickname}`} intro="" />

      <div className={classes.dashboardWidgets}>
        <div className={clsx(classes.dashboardWidget, classes.dashboardWidgetFullWidth)}>
          <DashboardWidget
            width={'full'}
            header={{ title: "Today's Orders" }}
            footer={{ title: 'View all orders', onClick: () => viewAllOrders() }}
          >
            {loading ? (
              <Spinner diameter={40} message="Loading Dashboard Summary..." />
            ) : (
              <DashboardSummary ordersSummary={data.ordersSummary} />
            )}
          </DashboardWidget>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
