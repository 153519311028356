import React from 'react'
import useStyles from './ProductInfo.styles'
import ProductImage from '../ProductImage/ProductImage'
import clsx from 'clsx'

const ProductInfo = (props: { imageUrl: string; title: string; promotion: string; isSmall?: boolean, className?: string }) => {
  const { className, imageUrl, title, promotion, isSmall } = props
  const classes = useStyles()
  return (
    <div className={clsx(classes.productInfo, isSmall && classes.small, className && className)}>
      <ProductImage imageUrl={imageUrl} isSmall={isSmall} />
      <div className={classes.productTitle}>
        {title}
        {promotion && <span className={classes.promotion}>{promotion}</span>}
      </div>
    </div>
  )
}

export default ProductInfo
