import { makeStyles } from '@material-ui/core/styles'
import { fontSizes, fontWeights, spacing } from '../../assets/jss/variables'

const styles = makeStyles((theme) => ({
  splash: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 80,
    position: 'relative',
    minHeight: 'calc(100vh - 90px)',
  },
  logo: {
    width: '100%',
    maxWidth: 200,
    marginBottom: 40,
  },
  title: {
    marginBottom: spacing.large,
    fontWeight: fontWeights.bold,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: fontSizes.xl,
    },
  },
  buttonContainer: {
    width: 240,
  },
}))

export default styles
