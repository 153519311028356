import React from 'react'

const IconArrowLeft = (props) => {
  const fillColor = props.fillColor || '#3D4958'
  const width = (props.width || 22) + 'px'
  const height = (props.height || 22) + 'px'
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill={fillColor}
          d="M0.283796574,11.5783456 C0.109886846,11.4362541 0,11.2275339 0,10.995 C0,10.761381 0.110914834,10.5517984 0.286235452,10.4096695 C0.308563699,10.3055472 0.358441262,10.2128236 0.436760722,10.1423045 L11.4296955,0.244221522 C11.677373,0.0212116531 12.116164,0.104296585 12.4091396,0.429678958 C12.7021152,0.755061332 12.7388789,1.2001333 12.4912013,1.42314316 L2.72130202,10.22 C6.96840558,10.22 13.1179087,10.22 21.1698113,10.22 C21.6280755,10.22 22,10.5672 22,10.995 C22,11.4228 21.6280755,11.77 21.1698113,11.77 L2.7101959,11.77 L12.4912013,20.5768568 C12.7388789,20.7998667 12.7021152,21.2449387 12.4091396,21.570321 C12.116164,21.8957034 11.677373,21.9787883 11.4296955,21.7557785 L0.436760722,11.8576955 C0.355448665,11.7844818 0.304793569,11.6873344 0.283796574,11.5783456 Z"
        />
      </g>
    </svg>
  )
}

export default IconArrowLeft
