import 'date-fns'
import React from 'react'
import {
  TextField,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  Input,
  MenuProps,
} from '@material-ui/core'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import InputDateRangePicker from 'components/InputDateRangePicker/InputDateRangePicker'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import useStyles from './OrderSearchForm.styles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DateRange } from 'materialui-daterange-picker'
import DateFnsUtils from '@date-io/date-fns'
import { OrderListParams } from '../OrderList.types'
import {ODCOrderStatus} from "../../../../helpers";

export interface OrderSearchFormProps {
  params: OrderListParams
  onInputChange: (e: any) => void
  onPickerChange: (name: string, value: MaterialUiPickersDate | DateRange) => void
  onSearch: () => void
  onClear: () => void
}

const OrderSearchForm = (props: OrderSearchFormProps) => {
  const classes = useStyles()
  const {
    orderNumber,
    surname,
    postcode,
    email,
    dateOrderedTo,
    dueDeliveryDateTo,
    deliveryMethod,
    pickingDate,
    orderStatus,
    orderStatuses,
    deliveryMethods,
  } = props.params
  const { onInputChange, onPickerChange, onSearch, onClear } = props

  const handleSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    onSearch()
  }

  const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }
  // sorting menu options on drop down
  if(orderStatuses){
    let orderStatusMenuPosition: String[] = []
    for (const orderStatus of Object.values(ODCOrderStatus)) {
      orderStatusMenuPosition.push(orderStatus.status)
    }
    orderStatuses.sort((a, b) => orderStatusMenuPosition.indexOf(a) - orderStatusMenuPosition.indexOf(b))
  }

  return (
    <form className={classes.searchForm} onSubmit={handleSubmit}>
      <div className={classes.searchContainer}>
        <div className={classes.fieldContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <TextField
                id="search-ordernumber"
                label="Order #"
                name="orderNumber"
                value={orderNumber}
                onChange={onInputChange}
              />
              <TextField
                id="search-surname"
                label="Surname"
                name="surname"
                value={surname}
                onChange={onInputChange}
              />
              <TextField
                id="search-postcode"
                name="postcode"
                label="Postcode"
                value={postcode}
                onChange={onInputChange}
              />
            </Grid>
            <Grid container>
              <TextField
                id="email"
                name="email"
                label="Email"
                value={email}
                type="email"
                onChange={onInputChange}
              />
              <InputDateRangePicker
                name="dateOrderedTo"
                id="date-ordered"
                label="Date Ordered (From/To)"
                value={dateOrderedTo}
                onChange={onPickerChange}
                maxDate={new Date()}
              />
              <InputDateRangePicker
                name="dueDeliveryDateTo"
                id="date-delivery-due"
                label="Due Delivery Date (From/To)"
                value={dueDeliveryDateTo}
                onChange={onPickerChange}
              />
            </Grid>
            <Grid container>
              <FormControl>
                <InputLabel id="order-status-label">Order Status</InputLabel>
                <Select
                  labelId="order-status-label"
                  id="order-status"
                  multiple
                  name="orderStatus"
                  value={orderStatus}
                  onChange={onInputChange}
                  input={<Input />}
                  className={classes.listDisplay}
                  renderValue={(selected) =>
                    (selected as string[])
                      .map((item) => item.replace(/_/g, ' ').toLowerCase())
                      .join(', ')
                  }
                  MenuProps={selectMenuProps}
                >
                  {orderStatuses.map((option: string) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox color="primary" checked={orderStatus.indexOf(option) > -1} />
                      <ListItemText
                        className={classes.listDisplay}
                        primary={option.replace(/_/g, ' ').toLowerCase()}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="delivery-method-label">Delivery Method</InputLabel>
                <Select
                  labelId="delivery-method-label"
                  id="delivery-method"
                  multiple
                  name="deliveryMethod"
                  value={deliveryMethod}
                  onChange={onInputChange}
                  input={<Input />}
                  renderValue={(selected) =>
                    (selected as string[])
                      .map((item) => deliveryMethods.find((method) => method.name === item)!.label)
                      .join(', ')
                  }
                  MenuProps={selectMenuProps}
                >
                  {deliveryMethods.map((option: { name: string; label: string }) => (
                    <MenuItem key={option.name} value={option.name}>
                      <Checkbox
                        color="primary"
                        checked={deliveryMethod.indexOf(option.name) > -1}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <KeyboardDatePicker
                margin="normal"
                className={classes.datePicker}
                id="picking-date"
                label="Picking Date"
                format="dd/MM/yyyy"
                initialFocusedDate={new Date()}
                value={pickingDate}
                onChange={(date) => onPickerChange('pickingDate', date)}
                KeyboardButtonProps={{
                  'aria-label': 'picking date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.buttonContainer}>
          <Button title="Clear" type="textOnly" size="small" onClick={onClear} />
          <Button title="SEARCH ORDERS" type="secondary" size="small" isSubmit={true} />
        </div>
      </div>
    </form>
  )
}

export default OrderSearchForm
