import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { card } from '../../../assets/jss/common'
import { colors, fontSizes, fontWeights } from '../../../assets/jss/variables'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusNonePicked: {},
    statusSomePicked: {},
    statusAllPicked: {},
    spaciousHeader: {},
    totalContainer: {
      display: 'flex',
      margin: '15px 0 0 10px',
      marginTop: '15px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '30px',
      },
      '& > div': {
        display: 'flex',
        paddingRight: '15px',
        paddingLeft: '15px',
        borderLeft: `1px solid ${colors.grey.grey1}`,
        alignItems: 'center',
        '&:first-child': {
          paddingLeft: '0',
          borderLeft: 'none',
        },
        '&:last-child': {
          fontSize: fontSizes.subtitle,
          [theme.breakpoints.up('md')]: {
            fontSize: fontSizes.hybrid,
          },
        },
      },
    },
    totalItemsLeft: {
      padding: '5px 15px',
      borderRadius: '4px',
      marginRight: '10px',
      backgroundColor: colors.grey.grey1,
      color: colors.black,
      '&$statusNonePicked': {
        backgroundColor: colors.grey.grey1,
        color: colors.black,
      },
      '&$statusSomePicked': {
        backgroundColor: colors.orange,
        color: colors.white,
      },
      '&$statusAllPicked': {
        backgroundColor: colors.green,
        color: colors.white,
      },
    },
    total: {
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.small,
      marginRight: '10px',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        fontSize: fontSizes.small,
        marginBottom: '10px',
        padding: '4px',
        '&:last-child': {
          marginRight: '0',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.medium,
          padding: '10px 30px',
        },
      },
    },
    pickedStatus: {
      fontSize: fontSizes.subtitle,
    },
    subHeading: {
      marginBottom: '20px',
      '& h2': {
        fontSize: '20px',
      },
    },
    consignmentHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    consignmentTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    print: {
      color: '#05ADD6',
      fontSize: '14px',
    },
    tableContainer: {
      ...card,
      fontSize: fontSizes.hybrid,
      '& .MuiTableCell-body': {
        fontSize: fontSizes.subtitle,
        verticalAlign: 'top',
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.hybrid,
        },
        '&:first-child': {
          paddingTop: '20px',
        },
        '&:last-child': {
          paddingTop: '10px',
        },
      },
      '& .MuiTableCell-head.MuiTableCell-root': {
        fontSize: fontSizes.label,
        padding: '5px 10px',
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.hybrid,
          padding: '5px 30px',
        },
      },
      '& .MuiTableCell-root': {
        padding: '15px 10px',
        [theme.breakpoints.up('md')]: {
          padding: '15px 30px',
        },
      },
      '& $spaciousHeader': {
        paddingTop: '10px!important',
        paddingBottom: '10px!important',
      },
    },
    headerProductCell: {
      width: '40%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    itemSelected: {
      width: '112px',
      cursor: 'pointer',
    },
    footerRow: {
      '& > .MuiTableCell-root': {
        border: 'none',
        fontSize: fontSizes.subtitle,
        padding: '8px 30px',
        [theme.breakpoints.up('md')]: {
          fontSize: fontSizes.hybrid,
        },
      },
    },
    footerFirst: {
      '& > .MuiTableCell-root': {
        paddingTop: '30px',
      },
    },
    footerLast: {
      '& > .MuiTableCell-root': {
        paddingBottom: '30px',
      },
    },
  })
)

export default useStyles
